import React, { useEffect, useRef, useState } from "react";
import { getJavascriptIntegrations, createJavasciptIntegration, deleteJavascriptIntegration } from "../../../api/javascriptIntegrations";
import { Button } from "../../../components/Form/Button";
import { FormikTextInput } from "../../../components/Form/Formik/FormikTextInput";
import { FormikSubmitButton } from "../../../components/Form/Formik/FormikSubmit";
import * as yup from "yup";
import { Form, Formik } from "formik";
import MenuContextList from "../../../components/MenuContextList";
import Modal from "../../../components/Modal";
import { useToastAction } from "../../../hooks/useToastAction";
import { useModalHelper } from "../../../hooks/useModalHelper";
import { stringToLocaleDateTimeString } from "../../../utils/date";
import { config as publicConfig } from "../../../api/public/publicConfig";
import { config } from "../../../utils/config"
import { ToggleButton } from "../../../components/Form/ToggleButton";
import { EyeIcon, CodeIcon } from "@heroicons/react/solid";
import { QRCodeCanvas } from 'qrcode.react';
import { getEditableElementsProperty } from "../../../api/dialogDefinition";
import { FormikSelectInput } from "../../../components/Form/Formik/FormikSelect";
import { useDebounceCallback } from "@react-hook/debounce";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/outline";

function downloadBlob(blob, filename) {
  const objectUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}

export const JavascriptIntegrations = ({ dialogKey }) => {
  const [javascriptIntegration, setJavascriptIntegration] = useState();
  const loadAction = useToastAction();
  const createAction = useToastAction()
  const deleteAction = useToastAction()

  const createModalHelper = useModalHelper()
  const viewCodeModalHelper = useModalHelper()
  const qrCodeRef = useRef();

  const [javascriptIntegrations, setJavascriptIntegrations] = useState([]);

  useEffect(() => {
    load();
  }, [])

  const load = () => {
    loadAction.execute(async () => {
      const javascriptIntegrations = await getJavascriptIntegrations(dialogKey)

      setJavascriptIntegrations(javascriptIntegrations);
    }, "Failed to load embedded")
  }

  const onCreate = async (name) => {
    createAction.execute(async () => {
      const newJavascriptIntegration = await createJavasciptIntegration(dialogKey, name);
      setJavascriptIntegrations([...javascriptIntegrations, newJavascriptIntegration])
    }, "Failed to add new embedded", "New embedded added")
  }

  const onDelete = async (id) => {
    deleteAction.execute(async () => {
      await deleteJavascriptIntegration(id);

      const remainingJavascriptIntegrations = javascriptIntegrations.filter(d => d.id !== id)

      setJavascriptIntegrations([...remainingJavascriptIntegrations])
    }, "Failed to delete embedded", "Embedded deleted")
  }

  const onView = (jsIntegration) => {
    if (javascriptIntegration?.id !== jsIntegration?.id) {
      setJavascriptIntegration(jsIntegration);
    }
    viewCodeModalHelper.open();
  }

  return (
    <section aria-labelledby="integrations-heading">
      <div className="mt-10 bg-white pt-6 shadow sm:rounded-md">
        <div className="flex justify-between items-center px-4 sm:px-6">
          <h2
            id="integrations-heading"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Embedded
          </h2>
          <Button
            text="New"
            onClick={createModalHelper.open}
          />
        </div>
        <div className="mt-6 flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="border-t border-gray-200">
                <table className="min-w-full mb-1 divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Created
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {javascriptIntegrations?.map(element => (
                      <tr key={element.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                          {element.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                          {stringToLocaleDateTimeString(element.createdDate)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          {
                            <MenuContextList
                              element={
                                element
                              }
                              deleteType={
                                "Javascript integration"
                              }
                              actions={[
                                {
                                  name: "View",
                                  onClick: () => onView(element)
                                },
                                {
                                  name: "Delete",
                                  onClick: () => onDelete(element.id)
                                },
                              ]}
                            />
                          }
                        </td>
                      </tr>
                    )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewCodeModal
        modalHelper={viewCodeModalHelper}
        javascriptIntegration={javascriptIntegration}
        dialogKey={dialogKey}
      />
      <CreateModal
        modalHelper={createModalHelper}
        onCreate={onCreate}
      />
    </section>
  )
}


const CodeStyle = (elements) => {
  const codeTag = elements?.map((item) => {
    return <pre key={item} className={`whitespace-pre-wrap font-mono break-words leading-5 text-${item.style}-600`}><code>{item.text}</code></pre>
  })

  return codeTag;
}

const JavascriptSection = (javascriptIntegration) => {
  const elements = [
    {
      text: `<!-- Add this button. Feel free to style it, but make sure to have the correct ID -->`,
      style: 'gray',
    },
    {
      text: `<input id="${javascriptIntegration?.id}" type="button" value="Create form"/>
      `,
      style: 'green',
    },
    {
      text: `<!-- This will call Smartforms with a new button copy this at the bottom of the page -->`,
      style: 'gray',
    },
    {
      text: ` <script>
      window.onload = (event) => {
        var element = document.getElementById("${javascriptIntegration?.id}")
        element.addEventListener('click', function(e) {
          fetch('${config.apiBaseUrl}/${publicConfig.basePath}/javascriptIntegrations/v1/dialogValues', 
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'         
            },
            body: JSON.stringify({ 
              token: '${javascriptIntegration?.token}' 
            })
        })
      .then(response => response.json())
      .then(data => window.open(data.url, "_blank"));
      });
      };
  </script>`,
      style: 'green',
    },
  ]
  return (
    <div className="mt-4 bg-gray-100 w-full p-10" style={{ minHeight: "470px" }}>
      {CodeStyle(elements)}
    </div>
  )
}



const LinkSection = ({ javascriptIntegration, dialogKey, hasToRefresh, setHasToRefresh, parameters, setParameters, url, setUrl }) => {
  const inDevelopment = process.env.NODE_ENV === "development";
  const baseUrl = window.location.host;
  const [editableElements, setEditableElements] = useState([]);
  const fullBaseUrl = `${baseUrl}/embedded/${javascriptIntegration?.token}`

  const refs = useRef(url.map(() => React.createRef()));
  const loadAction = useToastAction();

  useEffect(() => {
    refs.current[0].current.focus();
  }, []);


  useEffect(() => {
    load();
  }, [])

  const load = () => {
    loadAction.execute(async () => {
      const editableElementsProperty = await getEditableElementsProperty(dialogKey);
      editableElementsProperty.unshift("Select a option");
      setEditableElements(editableElementsProperty.map((element, index) => { return { name: element, value: index === 0 ? '' : element } }));
    }, "Failed to load properties")
    buildUrl(baseUrl, parameters)
  }

  const imageToBlob = (imageURL) => {
    const img = new Image;
    const c = document.createElement("canvas");
    const ctx = c.getContext("2d");
    img.crossOrigin = "";
    img.src = imageURL;
    return new Promise(resolve => {
      img.onload = function () {
        c.width = this.naturalWidth;
        c.height = this.naturalHeight;
        ctx.drawImage(this, 0, 0);
        c.toBlob((blob) => {
          // here the image is a blob
          resolve(blob)
        }, "image/png", 0.75);
      };
    })
  }

  const onCopyQRCodeClick = async (qrRef) => {
    var canvas = qrRef.current.querySelector("canvas").toDataURL('image/png');

    const blob = await imageToBlob(canvas)
    const item = new window.ClipboardItem({ "image/png": blob });
    navigator.clipboard.write([item]);
  }

  const onDownloadQRCodeClick = (qrRef) => {
    var canvas = qrRef.current.querySelector("canvas").toDataURL('image/png');

    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      let a = document.createElement('a');
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = 'image_name.png';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.remove();
    };

    xhr.open('GET', canvas);
    xhr.send();
  }

  const onRefresh = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const parametersWithoutEmptyField = parameters.filter(item => item?.key?.trim() !== '' && item?.value?.trim() !== '')
    const newUrl = buildUrl(fullBaseUrl, parametersWithoutEmptyField)
    setUrl([{ text: newUrl }])
    setHasToRefresh(false)
  }

  const debounced = useDebounceCallback(
    (value) => {
      document.getElementById('save').click();
    },
    1000
  );

  const buildUrl = (baseUrl, parameters) => {
    parameters.forEach((parameter, index) => {
      baseUrl += `${index === 0 ? '?' : ''}${parameter.key}=${encodeURIComponent(parameter.value)}`
      if (index < parameters.length - 1) {
        baseUrl += '&'
      }
    });
    return baseUrl
  }

  return (
    <div>
      {url.map((element, index) => {
        const qeSvg = <QRCodeCanvas value={element.text} />
        return (
          <div>
            <div>
              <label htmlFor="link-to-copy" className="block text-sm font-medium text-gray-700">
                Location
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                  {inDevelopment ? 'http://' : 'https://'}
                </span>
                <input
                  type="text"
                  name="link-to-copy"
                  id="link-to-copy"
                  disabled={true}
                  className={`flex-1 min-w-0 block w-full px-3 py-2 rounded-none sm:text-sm border-gray-300 ${hasToRefresh ? "bg-gray-50 text-gray-500" : ""}`}
                  value={hasToRefresh ? "<Please refresh>" : element.text}
                />
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => { navigator.clipboard.writeText(`${inDevelopment ? 'http://' : 'https://'}${element.text}`) }}
                  disabled={hasToRefresh}
                >
                  <span>Copy</span>
                </button>
              </div>
            </div>
            <div className="mt-4 flex flex-wrap">
              <div className="flex flex-col flex-1">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    QR code
                  </label>
                </div>
                <div ref={refs.current[index]}>
                  {
                    hasToRefresh ?
                      <div className="flex justify-center items-center w-32 h-32 border-2 bg-gray-50 text-gray-900 sm:text-sm ">
                        {"<Please refresh>"}
                      </div>
                      :
                      qeSvg
                  }
                </div>
                <span className="relative mt-3 z-0 inline-flex rounded-md">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    onClick={() => onCopyQRCodeClick(refs.current[index])}
                    disabled={hasToRefresh}
                  >
                    Copy
                  </button>
                  <button
                    type="button"
                    className="-ml-px relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    onClick={() => onDownloadQRCodeClick(refs.current[index])}
                    disabled={hasToRefresh}
                  >
                    Download
                  </button>
                  <button
                    type="button"
                    className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    onClick={onRefresh}
                  >
                    Refresh
                  </button>
                </span>
              </div>
              <div className="flex flex-col flex-2">
                <label>Parameters (prefill of fields when creating)</label>
                <Formik
                  initialValues={{
                    parameters
                  }}
                  validationSchema={() => {
                    return yup.object({
                      parameter: yup.array().of(yup.object().shape({
                        key: yup.string().required("Property it's required"),
                        value: yup.string().required("Value it's required")
                      }))
                    })
                  }}
                  onSubmit={async (values) => {
                    setHasToRefresh(true)
                    setParameters(values.parameters)
                  }}
                >
                  {
                    props => (
                      <Form onChange={debounced}>
                        {
                          props.values.parameters.map((parameter, index) => {
                            return (
                              <div
                                key={`parameters-${index}`}
                                className="flex mt-2"
                              >
                                <div>
                                  <FormikSelectInput
                                    label="Property"
                                    onChange={e => { }}
                                    name={`parameters[${index}].key`}
                                    formikProps={props}
                                    options={editableElements}
                                    required
                                  />
                                </div>
                                <div className="ml-5">
                                  <FormikTextInput
                                    className="ml-4"
                                    label="Value"
                                    name={`parameters[${index}].value`}
                                    required={true}
                                    formikProps={props}
                                  />
                                </div>
                                <div className="flex justify-center items-center ml-2 mt-5">
                                  {
                                    index === props.values.parameters.length - 1 &&
                                    parameter.key &&
                                    parameter.value && (
                                      <PlusCircleIcon
                                        className="w-5 cursor-pointer"
                                        onClick={() => {
                                          props.setFieldValue(`parameters[${index + 1}]`, { key: '', value: '' });
                                          setParameters([...parameters, { key: '', value: '' }])
                                        }}
                                      />
                                    )}
                                  {
                                    props.values.parameters.length > 1 && (
                                      <TrashIcon
                                        className="w-5 cursor-pointer"
                                        onClick={() => {
                                          const newParameters = props.values.parameters.filter((param, i) => i !== index);
                                          props.setFieldValue('parameters', newParameters)
                                          setHasToRefresh(true)
                                          setParameters(newParameters)
                                        }}
                                        disabled={props.values.parameters.length === 1}
                                      />
                                    )}
                                </div>
                              </div>)
                          })
                        }
                        <div className="pt-2 flex justify-end hidden">
                          <FormikSubmitButton
                            id="save"
                            text="Save"
                            className="i"
                            formikProps={props}
                          />
                        </div>
                      </Form>
                    )
                  }
                </Formik>
              </div>
            </div>
          </div>

        )
      })}
      {/* <section className="mt-4 bg-gray-100 w-full p-10" style={{minHeight: "470px"}}>
      {CodeStyle(elements)}
    </section> */}
    </div>
  )
}

const ViewCodeModal = ({ modalHelper, javascriptIntegration, dialogKey }) => {
  const baseUrl = window.location.host;

  const [hasToRefresh, setHasToRefresh] = useState(false)

  const [parameters, setParameters] = useState([{ key: '', value: '' }])
  const [url, setUrl] = useState([
    {
      text: `${baseUrl}/embedded/${javascriptIntegration?.token}`,
    }
  ])

  const toggleElements = [
    {
      name: "Javascript",
      icon: EyeIcon,
    },
    {
      name: "Link",
      icon: CodeIcon,
    },
  ]


  const [toggleEnabled, setToggleEnabled] = useState("Javascript");

  let actualCode;
  if (toggleEnabled === "Javascript") {
    actualCode = JavascriptSection(javascriptIntegration)
  } else if (toggleEnabled === "Link") {
    actualCode = <LinkSection javascriptIntegration={javascriptIntegration} dialogKey={dialogKey}
      hasToRefresh={hasToRefresh} setHasToRefresh={setHasToRefresh}
      parameters={parameters} setParameters={setParameters}
      url={url} setUrl={setUrl}
    />
  }

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="large"
    >
      <div div className="pb-4">
        <div className="mt-2 flex items-center justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Embedded
          </h3>
          <div>
            <ToggleButton setToggle={setToggleEnabled} toggle={toggleEnabled} elements={toggleElements} disabled={hasToRefresh} />
          </div>
        </div>
      </div>
      {actualCode}
    </Modal>
  )
}

const CreateModal = ({ modalHelper, onCreate }) => {

  const cancel = () => {
    modalHelper.close();
  }

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="medium"
      title="Create integration"
    >
      <Formik
        initialValues={{
          name: ''
        }}
        validationSchema={() => {
          return yup.object({
            name: yup.string().required('Required'),
          })
        }}
        onSubmit={async (values, actions) => {
          await onCreate(values.name)
          actions.setSubmitting(false)
          modalHelper.close();
        }}
      >
        {
          props => (
            <Form>
              <FormikTextInput
                label="Name"
                name="name"
                required={true}
                formikProps={props}
              />
              <div className="mt-10 flex justify-between">
                <FormikSubmitButton
                  formikProps={props}
                  text="Create"
                />
                <Button
                  onClick={cancel}
                  text="Close"
                  theme="white"
                />
              </div>
            </Form>
          )
        }
      </Formik>
    </Modal>
  );
}