import { v4 as uuidv4 } from "uuid";

export const HeadlineAndContent = () => {
    return {
        id: uuidv4(),
        dialogObjectId: null,
        lockToDialogObject: false,
        space: null,
        backgroundColor: "",
        margin: "20px 0",
        padding: null,
        borderWidth: null,
        borderColor: null,
        roundedCorners: false,
        static: false,
        name: "Section-1",
        newPage: false,
        backgroundImage: null,
        backgroundSize: 0,
        backgroundRepeat: false,
        avoidBreakInside: false,
        rows: [
            {
                id: uuidv4(),
                columns: [
                    {
                        id: uuidv4(),
                        borderWidth: "0",
                        elements: [
                            {
                                id: uuidv4(),
                                type: 3,
                                property: "headerLine",
                                value: "Headline (H1)",
                                layout: { order: 2 },
                                text: {
                                    level: "1",
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 32,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: "#000"
                                }
                            },
                            {
                                id: uuidv4(),
                                type: 8,
                                borderWidth: "1px",
                                borderColor: "#8d8d8d",
                                width: 0,
                                property: "divider"
                            },
                            {
                                id: uuidv4(),
                                type: 0,
                                property: "contentText",
                                value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dignissim, nunc in volutpat pellentesque, eros purus sollicitudin urna, nec dignissim nunc nunc sed nulla. Mauris vehicula arcu vitae risus elementum, vel dapibus risus consectetur. Ut ac massa sed justo tempor egestas. Duis eget orci et quam vehicula volutpat ut nec libero. Nam ut gravida metus. Integer ac semper erat. Proin pharetra, erat sed luctus sagittis, magna ligula porttitor nisi, vel tempor arcu turpis sed nunc. Curabitur volutpat augue sit amet ante dictum fermentum. Suspendisse potenti. Cras et leo vitae lectus eleifend varius in et lectus. Sed et lectus eu elit gravida condimentum.\n\n",
                                layout: { order: 2 },
                                text: {
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 16,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000"
                                }
                            }
                        ],
                        contentAlign: null,
                        verticalAlign: null,
                        backgroundColor: null,
                        space: 3,
                        span: null,
                        margin: "0",
                        padding: null,
                        borderColor: null,
                        roundedCorners: false
                    }
                ],
                space: 5,
                gap: null,
                backgroundColor: null,
                margin: "5px 0",
                padding: "10px",
                borderWidth: "0",
                borderColor: null,
                roundedCorners: true
            }
        ]
    };
};
