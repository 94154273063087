
import { FolderAddIcon, TrashIcon, CheckIcon, XIcon, FolderIcon, PlusIcon, ArrowsExpandIcon } from '@heroicons/react/outline'
import { useClassNames, useModalHelper } from '@metaforcelabs/metaforce-core';
import { useContext, useEffect, useState } from 'react';
import { createDialogDefinitionFolder, deleteDialogDefinitionFolder, updateDialogDefinitionFolder } from '../../../api/dialogDefinition';
import Modal from '../../../components/Modal';
import { useToastAction } from '../../../hooks/useToastAction';
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import './styles.scss';
import { systemFolderIds } from '../../../utils/folders';
import { FolderContext } from '../../../contexts';
import MenuContextList from '../../../components/MenuContextList';
import OnRenameFolderModal from './onRenameFolderModal';

export default function FoldersSidebar({ folders, selectedFolder, setSelectedFolder, onFolderCreated, onFolderDeleted, onFolderRenamed }) {
  const { setCurrentFolder } = useContext(FolderContext);
  const createAction = useToastAction();
  const deleteAction = useToastAction();
  const renameAction = useToastAction();
  const { classNames } = useClassNames();
  const errorHandler = useErrorHandler();

  const initialInCreationFolderState = {
    privateFolder: false,
    sharedFolder: false
  }
  const [inCreationFolder, setInCreationFolder] = useState(initialInCreationFolderState)

  const handleAddFolderClick = (type) => {
    setInCreationFolder((latest) => setInCreationFolder({ ...latest, [type]: !latest[type] }));
  }

  const initialFolders = {
    privateFolders: [{ id: "privateFolder", name: 'Private Folders', onClick: () => handleAddFolderClick("privateFolder"), icon: PlusIcon, isAddItem: true, isStatic: true },
    { id: systemFolderIds.home, name: 'Home', icon: FolderIcon, isStatic: true }],
    sharedFolders: [{ id: "sharedFolder", name: 'Shared Folders', onClick: () => handleAddFolderClick("sharedFolder"), icon: PlusIcon, isAddItem: true, isStatic: true }],
    deletedForms: [{ id: systemFolderIds.trash, inTrash: true, name: 'Trash', icon: TrashIcon, isStatic: true }]
  }

  const [allFolders, setAllFolders] = useState(initialFolders)

  const handleSelectFolderClick = (e, item, index) => {
    setSelectedFolder({ folderId: item.id, index });
    setCurrentFolder({ folderId: item.id, index })
  }

  const handleDeleteFolder = async (id) => {
    deleteAction.execute(async () => {
      await deleteDialogDefinitionFolder(id);
      onFolderDeleted()
    }, "Failed to delete folder");
  }

  const handleRenameFolder = async (item) => {
    renameAction.execute(async () => {
      await updateDialogDefinitionFolder(item);
      onFolderRenamed()
    }, "Failed to rename folder");
  }

  useEffect(() => {
    if (folders) {
      const newFolders = { ...initialFolders }

      folders.forEach(folder => {
        const buildFolderItem = { ...folder, icon: FolderIcon, isAddItem: false, isStatic: false }
        folder.isPrivate ? newFolders.privateFolders.push(buildFolderItem) : newFolders.sharedFolders.push(buildFolderItem)
      });

      setAllFolders(newFolders);
    }
  }, [folders])

  const SidebarItem = ({ item, index }) => {
    const current = selectedFolder.folderId === item.id;

    const renameModalHelper = useModalHelper();

    const handleClick = (e) => {
      current
        ? e.preventDefault()
        : item.onClick
          ? item.onClick(e, item, index)
          : handleSelectFolderClick(e, item, index)
    }

    return (
      <>
        <div
          tabIndex={0}
          key={item.name}
          className={`flex px-3 py-2 text-sm group font-medium cursor-pointer items-center rounded-md 
                    ${item?.isAddItem ? "bg-white sticky top-0 justify-between flex-row-reverse py-4" : current ? "bg-indigo-100 text-gray-900" : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"}`}
          aria-current={current ? 'page' : undefined}
          onClick={handleClick}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              document.activeElement.click();
            }
          }}
        >
          <item.icon
            className={
              `flex-shrink-0 -ml-1 mr-3 ${item?.isAddItem ? "h-4 w-4" : current ? "text-gray-500 h-6 w-6" : "text-gray-400 group-hover:text-gray-500 h-6 w-6"}`
            }
            aria-hidden="true"
          />
          <span className={`truncate whitespace-nowrap overflow-hidden overflow-ellipsis select-none ${item?.isAddItem ? "text-base" : ""}`}>
            {item.name}
          </span>
          {
            current && !item.isStatic &&
            <div className="absolute right-5 mt-2">
              <MenuContextList
                element={item}
                deleteType={'folder'}
                width='w-44'
                actions={[
                  {
                    name: "Rename",
                    disabled: renameAction.isExecuting,
                    onClick: () => { renameModalHelper.open() },
                    icon: ArrowsExpandIcon,
                    hidden: false,
                  },
                  {
                    name: "Delete",
                    onClick: () => { handleDeleteFolder(item.id) },
                    icon: TrashIcon,
                    hidden: false,
                  },
                ]}
              />
            </div>
          }
        </div>
        <OnRenameFolderModal
          modalHelper={renameModalHelper}
          selectedFolder={item}
          allFolders={allFolders}
          onRename={handleRenameFolder}
        />
      </>
    )
  }

  const Divider = () => {
    return (
      <div className="relative py-0.5">
        <div className="absolute inset-0 flex items-center " aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
      </div>
    )
  }

  const AddFolderField = ({ field, isPrivate }) => {
    const show = inCreationFolder[field];
    const [newFolder, setNewFolder] = useState({ name: "", isPrivate: false })
    const [isInvalid, setIsInvalid] = useState(false);

    const handleAddFolderFieldChange = (e, isPrivate) => {
      const { value } = e.target;
      setNewFolder({ ...newFolder, name: value, isPrivate });
    }

    const handleCancelFolderCreation = () => {
      setInCreationFolder(initialInCreationFolderState)
      setNewFolder({ name: "", isPrivate: false })
    }

    const handleCreateFolder = async () => {
      if (allFolders.privateFolders.some(m => m.name.toLowerCase() === newFolder.name.toLowerCase()) || allFolders.sharedFolders.some(m => m.name.toLowerCase() === newFolder.name.toLowerCase())) {
        errorHandler.handleApiError('Folder name is same', 'Folder name is same', false, 'create-folder');
        setIsInvalid(true);
      }
      else {
        setIsInvalid(false);
        createAction.execute(async () => {
          await createDialogDefinitionFolder(newFolder);
          onFolderCreated()
        }, "Failed to create folder")
      }
    }

    return show && (
      <>
        <div
          className={`flex  px-3 py-2 text-sm group font-medium items-center rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900 justify-between`}
        >
          <FolderAddIcon className={`flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500`} />
          <input className={`focus:outline-none p-1 border rounded-md ${isInvalid ? "border-red-500 focus:border-red-500" : "border-gray-300 focus:ring-gray-400 focus:border-gray-400"}`}
            key={field}
            name={field}
            value={newFolder.name}
            onChange={(e) => handleAddFolderFieldChange(e, isPrivate)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleCreateFolder();
              }
            }}
          />
          <button
            type="button"
            className="inline-flex justify-center"
            onClick={() => handleCreateFolder()}
          >
            <CheckIcon className='z-30  cursor-pointer h-5 w-5' />
          </button>
          <button
            type="button"
            className="inline-flex justify-center"
            onClick={() => handleCancelFolderCreation()}
          >
            <XIcon className=' h-5 w-5 cursor-pointer' />
          </button>
        </div>
      </>
    )
  }

  return (
    <>
      <nav className="relative space-y-1 z-10 w-1/5 h-fit" aria-label="FoldersSidebar">
        <div className='top-0 shadow-sm border border-gray-200 sm:rounded-lg bg-white '>
          <div className='private-shared-folders-container overflow-y-auto'>
            {
              allFolders.privateFolders.map((privateItem, index) =>
                <SidebarItem key={privateItem.id} item={privateItem} index={index} />
              )
            }
            <AddFolderField field={"privateFolder"} isPrivate />
            <Divider />
            {
              allFolders.sharedFolders.map((sharedItem, index) =>
                <SidebarItem key={sharedItem.id} item={sharedItem} index={index} />
              )
            }
          </div>
          <AddFolderField field="sharedFolder" />
          <Divider />
          {
            allFolders.deletedForms.map((deletedItem, index) =>
              <SidebarItem key={deletedItem.id} item={deletedItem} index={index} />
            )
          }
        </div>
      </nav>
    </>
  )
}