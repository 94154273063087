import React, { useEffect, useRef, useState } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import { getDialogObject } from '../../api/dialogObjects';
import _ from "lodash";
import { useNewIdGenerator } from '../../screens/Admin/DialogDesign/useNewIdGenerator';
import { backgroundSizeOptions, dialogType, elementTypes, resourceCategories } from '../../utils/constants';
import { ContainerContext } from '../../contexts';
import * as Icon from "@heroicons/react/outline"
import { getResources } from '../../api/resources';
import { useDefinitionManager } from '../../screens/Admin/DialogDesign/useDefinitionManager';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState(_.cloneDeep(initData));
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const [resourceOptions, setResourceOptions] = useState([]);
  const idGenerator = useNewIdGenerator()
  const containerContext = React.useContext(ContainerContext)
  const forcePageCheckbox = useRef();
  const avoidPageCheckbox = useRef();

  useEffect(() => setData(_.cloneDeep(initData)), [initData])
  useEffect(() => {
    load()
  }, [])
  
  const load = async () => {
    const resources = await getResources(resourceCategories.section);
    setResourceOptions(prev => ([{ name: 'None', value: null }, ...resources.map(x => ({ name: x.name, value: x.assetUrl, id: x.id }))]));
  }

  const handleOnChange = async (e, type) => {
    if (Array.isArray(e)) {
      e.forEach(ev => {
        const { name, value } = ev.target;

        setProperty(data, name, value, type)
      })
      actions.handleChanges(dialogDefinition, e, data.id, type)
    } else {
      const { name, value } = e.target;
      if (name === 'lockToDialogObject') {
        const templateObject = await getDialogObject(initData.dialogObjectId)
        const templateContainer = templateObject.container;
        let cloned = _.cloneDeep(templateContainer);
        idGenerator.newIdsOnContainer(cloned);

        const containerIndex = dialogDefinition.containers.findIndex(c => c.id === initData.id)
        const event = {
          target: {
            name: `containers[${containerIndex}]`,
            value: {
              ...cloned,
              id: initData.id,
              lockToDialogObject: true,
              dialogObjectId: templateObject.id
            }
          }
        }
        actions.handleChange(dialogDefinition, event, data.id, null, true)
        setProperty(data, name, value, type)
      }
      else {
        actions.handleChange(dialogDefinition, e, data.id, type)
        setProperty(data, name, value, type)
      }
    }
    setData(data => ({
      ...data
    }));
  }

  if (data.dialogObjectId && data.readOnly === true) {
    return (
      <Property.Group
        title='Template'
      >
        <div className='px-4 sm:px-6 pt-3 flex items-center justify-between'>
          Template is read-only
        </div>
      </Property.Group>
    )
  }

  return (
    <>
      {
        data.dialogObjectId &&
        <Property.Group
          title='Template'
        >
          <Property.Checkbox
            onChange={handleOnChange}
            label={'Lock to template'}
            name={'lockToDialogObject'}
            value={data.lockToDialogObject}
          />
        </Property.Group>
      }

      <Property.Group
        title='Information'
      >
        <Property.Label
          label="Type"
          value="Section"
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Name'}
          name={'name'}
          value={data?.name}
        />

      </Property.Group>

      {
        (containerContext.isObjectTemplate === true || containerContext.isDialogTemplate === true) &&
        <Property.Group
          title='Template'
        >
          <Property.Checkbox
            onChange={handleOnChange}
            label={'Read-only'}
            name={'readOnly'}
            value={data?.readOnly}
          />
        </Property.Group>
      }

      <Property.Group
        title='Style'>

        <Property.ResourceSelect
          onChange={handleOnChange}
          label={'Background Resource'}
          name={'backgroundImage'}
          value={data?.backgroundImage}
          optionalPropSet={{ name: 'resourceId', optionsTarget: 'id' }}
          resourceCategories={[resourceCategories.section]}
          options={resourceOptions}
          onResourcesChanged={load}
        />

        <Property.Base64Upload
          onChange={handleOnChange}
          label={'Background Image'}
          name={'backgroundImage'}
          value={data?.backgroundImage}
          acceptedExtensions={"image/*"}
        />

        <div className={`${data?.backgroundImage ? '' : 'hidden'}`}>
          <Property.Select
            onChange={(e) => handleOnChange(e, 'number')}
            label={'Fit image'}
            name={'backgroundSize'}
            value={data?.backgroundSize}
            options={backgroundSizeOptions}
          />

          <Property.Checkbox
            onChange={handleOnChange}
            label={'Repeat image'}
            name={'backgroundRepeat'}
            value={data.backgroundRepeat}
          />
        </div>

        <Property.Color
          onChange={handleOnChange}
          label={'Background color'}
          name={'backgroundColor'}
          initColor={data.backgroundColor}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data.borderWidth}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Rounded corners'}
          name={'roundedCorners'}
          value={data.roundedCorners}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Force page-break on PDF'}
          name={'newPage'}
          value={data?.newPage}
          ref={forcePageCheckbox}
          dependency={avoidPageCheckbox}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Avoid page-break inside on PDF'}
          name={'avoidBreakInside'}
          value={data?.avoidBreakInside}
          ref={avoidPageCheckbox}
          dependency={forcePageCheckbox}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Place section on top for step-by-step'}
          name={'static'}
          value={data?.static}
          disabled={dialogDefinition.type !== dialogType.stepByStep}
        />

      </Property.Group>

      <Property.Group
        title='Show and hide'
      // subtitle='Add condition for when shal be visible or not.'
      // icon={<Icon.ShareIcon className='rounded-lg mx-4 p-2 w-11 shadow-md' style={{backgroundColor: "#FEEAEA", color: '#FA7578'}} />}
      >
        <Property.LogicControl dialogDefinition={dialogDefinition} currentContainer={data} name="showLogicControl" values={data.showLogicControl} onChange={handleOnChange} />
      </Property.Group>

      <Property.Group
        title='Placement'>

        <Property.MarginPadding
          onChange={handleOnChange}
          label={'Margin'}
          name={'margin'}
          value={data.margin}
          type={'margin'}
        />

        <Property.MarginPadding
          onChange={handleOnChange}
          label={'Padding'}
          name={'padding'}
          value={data.padding}
          type={'padding'}
        />

        <Property.Select
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Gap'}
          name={'space'} // property name on backend is called "space"
          value={data.space}
          options={[
            { name: "None", value: 0 },
            { name: "Small", value: 2 },
            { name: "Medium", value: 3 },
            { name: "Large", value: 5 },
            { name: "Extra Large", value: 8 },
          ]}
        />

      </Property.Group>

    </>
  )
}
