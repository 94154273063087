import { NavLink } from "react-router-dom";
import MenuContextList from "../../../components/MenuContextList/index";
import { stringToLocaleDateTimeString } from "../../../utils/date";
import { editStates } from "../../../utils/constants";
import { isActive } from "../../../utils/dialogDefinitions";
import { Badge } from "../../../components/Badge";
import { DuplicateIcon, CheckIcon, SaveIcon, PencilAltIcon, TrashIcon, BanIcon, ArrowsExpandIcon } from "@heroicons/react/solid";
import * as Constants from "../../../utils/constants";
import { useModalHelper } from "@metaforcelabs/metaforce-core";
import { useToastAction } from "../../../hooks/useToastAction"
import Modal from "../../../components/Modal";
import { FolderIcon } from "@heroicons/react/outline";
import Select from 'react-select';
import { useEffect, useState } from "react";
import { moveDialogDefinitionToFolder } from "../../../api/dialogDefinition";
import OnRenameModal from "./onRenameModal";
import { systemFolderIds } from "../../../utils/folders";

export default function TableBody({ data, feature, definitionAction, onClick, onMoveToFolderSuccess, folders, selectedFolder }) {
  const { duplicate, activate, deactivate, rename } = definitionAction;
  const { onDuplicate, onDeactivate, onActivate, onHandleExport, onDelete, onRenameSmartForm } = onClick;
  const [selectedDialogDefinition, setSelectedDialogDefinition] = useState(null);

  const folderModalHelper = useModalHelper();
  const renameModalHelper = useModalHelper();

  const openMoveToFolder = (dialogDefinitionId) => {
    setSelectedDialogDefinition(dialogDefinitionId);
    folderModalHelper.open();
  }

  const openRenameModal = (dialogDefinition) => {
    setSelectedDialogDefinition(dialogDefinition)
    renameModalHelper.open();
  }

  const isTrashFolder = selectedFolder?.folderId === systemFolderIds.trash;

  return (
    <>
      <tbody className="bg-white divide-y divide-gray-200">
        {data?.map((element, index) => (
          <tr key={index}>
            <td className="px-6 py-7 whitespace-nowrap text-sm font-medium text-gray-900 overflow-ellipsis overflow-hidden"
              style={{ maxWidth: "300px" }}
            >
              {
                <span className={`${!element?.id ? "w-3/5 skeleton-loader" : ""}`}>
                  {feature.hasFeature(
                    Constants.designFeatureCode
                  ) && !isTrashFolder ? (
                    <NavLink to={`/admin/dialog/${element.id}/design`}
                      className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {element?.name}
                    </NavLink>
                  ) : (
                    element?.name
                  )}
                </span>
              }
            </td>
            <td className="px-6 py-7 whitespace-nowrap text-sm font-medium">
              <span className={`${!element?.id ? "w-3/5 skeleton-loader" : ""}`}>
                {element?.id && !element?.isDeleted &&
                  <>
                    {isActive(element) ? (
                      <span className="text-gray-900">Active</span>
                    ) : (
                      <span className="text-gray-400">Inactive</span>
                    )}
                  </>
                }

                {element?.id && element?.isDeleted &&
                  <>
                    <span className="text-gray-400">Deleted</span>
                  </>
                }
              </span>
            </td>
            <td className="px-6 py-7 whitespace-nowrap text-sm text-gray-500">
              <span className={`${!element?.id ? "w-3/5 skeleton-loader" : ""}`}>
                {element?.id && !element?.isDeleted &&
                  <>
                    {element.editState === editStates.draft ? (
                      <Badge type="default" text="Draft" />
                    ) : (
                      <Badge type="success" text="Published" />
                    )}
                  </>
                }

                {element?.id && element?.isDeleted &&
                  <>
                    <Badge type="error" text="Deleted" />
                  </>
                }

              </span>
            </td>
            <td className="px-6 py-7 whitespace-nowrap text-sm text-gray-500">
              <span className={`${!element?.id ? "w-3/5 skeleton-loader" : ""}`}>
                {stringToLocaleDateTimeString(element?.createdDate)}
              </span>
            </td>
            <td className="px-6 py-7 whitespace-nowrap text-sm text-gray-500">
              <span className={`${!element?.id ? "w-3/5 skeleton-loader" : ""}`}>
                {stringToLocaleDateTimeString(element?.updatedDate)}
              </span>
            </td>
            <td className="px-6 py-7 whitespace-nowrap text-sm text-gray-500">
              <span className={`${!element?.id ? "w-3/5 skeleton-loader" : ""}`}>
                {element?.category || '-'}
              </span>
            </td>
            {feature.hasFeature(Constants.designFeatureCode) && (
              <td className="py-8 whitespace-nowrap text-right text-sm font-medium absolute">
                {
                  element?.id && !element?.isDeleted &&
                  <MenuContextList
                    element={element}
                    deleteType={"smartform"}
                    actions={[
                      {
                        name: "Duplicate",
                        disabled: duplicate.isExecuting,
                        onClick: () => onDuplicate(element.id),
                        icon: DuplicateIcon,
                        hidden: element.isDeleted
                      },
                      isActive(element)
                        ? {
                          name: "Deactivate",
                          disabled: deactivate.isExecuting,
                          onClick: () =>
                            onDeactivate(element.id),
                          icon: BanIcon,
                          lineAfter: true,
                          hidden: element.isDeleted
                        }
                        : {
                          name: "Activate",
                          disabled: activate.isExecuting,
                          onClick: () =>
                            onActivate(element.id),
                          icon: CheckIcon,
                          lineAfter: true,
                          hidden: element.isDeleted
                        },
                      {
                        name: "Export",
                        onClick: () =>
                          onHandleExport(element.id),
                        icon: SaveIcon,
                        lineAfter: true,
                        hidden: element.isDeleted
                      },
                      {
                        name: "Rename",
                        disabled: rename.isExecuting,
                        onClick: () => openRenameModal({ id: element.id, name: element.name }),
                        icon: ArrowsExpandIcon,
                        hidden: element.isDeleted
                      },
                      {
                        name: "Edit",
                        href: `/admin/dialog/${element.id}/design`,
                        icon: PencilAltIcon,
                        hidden: element.isDeleted
                      },
                      {
                        name: "Move to Folder",
                        onClick: () => openMoveToFolder(element.id),
                        icon: FolderIcon,
                        hidden: element.isDeleted
                      },
                      {
                        name: "Delete",
                        onClick: () => onDelete(element.id),
                        icon: TrashIcon,
                        hidden: element.isDeleted
                      },
                    ]}
                  />
                }
              </td>
            )}
          </tr>
        ))}
      </tbody>
      <OnRenameModal modalHelper={renameModalHelper} selectedDialogDefinition={selectedDialogDefinition} onRenameSmartForm={onRenameSmartForm} />
      <MoveToFolderModal
        onMoveToFolderSuccess={onMoveToFolderSuccess}
        modalHelper={folderModalHelper}
        selectedDialogDefinition={selectedDialogDefinition}
        folders={folders}
      />
    </>
  );
}

const MoveToFolderModal = ({ modalHelper, selectedDialogDefinition, onMoveToFolderSuccess, folders }) => {
  const loadAction = useToastAction();
  const moveAction = useToastAction();
  const [folderOptions, setFolderOptions] = useState([]);
  const [selectedFolderOption, setSelectedFolderOption] = useState(null);

  useEffect(() => {
    if (modalHelper.isOpen) {
      load();
    }
  }, [modalHelper.isOpen]);

  function load() {
    loadAction.execute(() => {
      const options = []

      options.push({
        value: systemFolderIds.home,
        label: "Home"
      })

      folders?.forEach(folder => {
        options.push({
          value: folder.id,
          label: folder.name
        })
      });

      setFolderOptions(options);
    }, "Failed to load folders")
  }

  const onCancel = () => {
    modalHelper.close();
  }

  const onMove = () => {
    moveAction.execute(async () => {
      await moveDialogDefinitionToFolder(selectedDialogDefinition, selectedFolderOption)
      onMoveToFolderSuccess();
      modalHelper.close();
    }, "Failed to move Smartform", "Moved Smartform")
  }

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="medium"
      title="Move to Folder"
    >
      <Select
        className="relative"
        name="language"
        options={folderOptions}
        onChange={(event) => {
          setSelectedFolderOption(event.value);
        }}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      />
      <div className="mt-10 flex gap-2 justify-end">

        <button
          onClick={onMove}
          type="button"
          className="mt-3 ml-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
          disabled={moveAction.isExecuting}
        >
          Move form
        </button>
        <button onClick={onCancel} type="button" className="mt-3 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm">
          Cancel
        </button>

      </div>
    </Modal>
  )
}
