import { useEffect, useState } from "react";
import { setProperty } from "../../utils/property";
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { alignmentOptions } from "../../utils/constants";

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type);

    setProperty(data, name, value, type);
    setData((data) => ({
      ...data,
    }));
  };

  return (
    <div className="popover-container-items">
      <Property.Group title="Information">
        <Property.Label
          label="Type"
          value="Text"
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Property"}
          name={"property"}
          value={data?.property}
        />

        <Property.TextArea
          onChange={handleOnChange}
          label={"Value"}
          name={"value"}
          value={data?.value}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

      </Property.Group>

      <Property.Group title="Element Style">
        <Property.Checkbox
          onChange={handleOnChange}
          label={"RoundedCorners"}
          name={"roundedCorners"}
          value={data?.roundedCorners}
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data?.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data?.borderWidth}
        />

      </Property.Group>

      <Property.Group title="Text Style">
        <Property.Color
          onChange={handleOnChange}
          label={"Color"}
          name={"text.color"}
          initColor={data?.text?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Size"}
          name={"text.size"}
          type={'number'}
          value={data?.text?.size || null}
        />

        <Property.FontStyleAndWeight
          onChange={handleOnChange}
          name="text"
          property={data?.text}
        />

        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={"Alignment"}
          name={"text.alignment"}
          value={data?.text?.alignment}
          options={alignmentOptions}
        />

        <Property.Select
          onChange={(e) => handleOnChange(e, "number")}
          label={"Icon"}
          name={"leadingIcon"}
          value={data?.leadingIcon}
          options={[
            { name: "None", value: -1 },
            { name: "Clock", value: 0 },
            { name: "Duplicate", value: 1 },
            { name: "Exclamation", value: 2 },
            { name: "QuestionMark", value: 3 },
            { name: "CheckMark", value: 4 }
          ]}
        />
      </Property.Group>

    </div>
  );
}
