import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { Form, Formik } from "formik";
import { FormikTextInput } from '../../../components/Form/Formik/FormikTextInput';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { Button } from "../../../components/Form/Button";
import * as yup from "yup";
import { useErrorHandler } from "../../../hooks/useErrorHandler";

export default function OnRenameFolderModal({ modalHelper, selectedFolder, allFolders, onRename }) {
  const [initialValues, setInitialValues] = useState();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    // When modal opens, initialize input text with the current folder's name
    if (modalHelper.isOpen) {
      setInitialValues({ name: selectedFolder.name })
    }
  }, [modalHelper.isOpen])

  const handleRename = (values) => {
    if (allFolders.privateFolders.some(m => m.name.toLowerCase() === values.name.toLowerCase() && m.id !== selectedFolder.id) ||
      allFolders.sharedFolders.some(m => m.name.toLowerCase() === values.name.toLowerCase() && m.id !== selectedFolder.id)) {
      errorHandler.handleApiError('Folder name already exists', 'Folder name already exists', false, 'rename-folder');
      return;
    }

    onRename({
      ...selectedFolder,
      name: values.name,
    });
    modalHelper.close();
  }

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="medium"
      title={`Rename ${selectedFolder?.name} Folder`}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={() => {
          return yup.object({
            name: yup.string().required('Required'),
          })
        }}
        onSubmit={async (values, actions) => {
          handleRename(values);
        }}
      >
        {
          props => (
            <Form>
              <FormikTextInput
                name="name"
                formikProps={props}
                required={true}
              />
              <div className='mt-5 sm:mt-6'>
                <FormikSubmitButton
                  formikProps={props}
                  text={"Save"}
                />
                <Button
                  theme='white'
                  text={'Cancel'}
                  className='ml-2'
                  onClick={modalHelper.close}
                />
              </div>
            </Form>
          )
        }
      </Formik>
    </Modal>
  );
}