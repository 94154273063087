export const config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  workflowApiUrl:process.env.REACT_APP_WORKFLOW_BASE_URL,
  tokenStorageKey: '@MetaForce:userToken',
  identityBaseUrl: process.env.REACT_APP_IDENTITY_BASE_URL,
  centerpointBaseUrl: process.env.REACT_APP_CENTERPOINT_BASE_URL,
  webEditorBaseUrl: process.env.REACT_APP_WEBEDITOR_BASE_URL,
  centerpointUiBaseUrl: process.env.REACT_APP_CENTERPOINT_UI_BASE_URL,
  productLicense: '611b8ac465064876fb35e1cf',
  appTitle: process.env.REACT_APP_APP_TITLE || 'Metaforce - Studio',
  favicon: process.env.REACT_APP_APP_FAVICON || '/favicon.ico',
  archiveBaseUrl: process.env.REACT_APP_ARCHIVE_BASE_URL,
  interactAdminBaseUrl: process.env.REACT_APP_INTERACT_ADMIN_BASE_URL,
  docsUrl: process.env.REACT_APP_DOCUMENTATION_URL || 'https://documentation.metaforce.net/userDocumentation/studio',
  workflow_ui_url: process.env.REACT_APP_WORKFLOW_UI_URL,
}

export const oidcConfig = {
    client_id: 'dialog_web',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: 'code',
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    scope: 'openid profile email api.internal offline_access',
    authority: process.env.REACT_APP_AUTHORITY,
    silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
    automaticSilentRenew: true,
    loadUserInfo: true,
  };

  export const PhoneInputConfig = {
    defaulPhoneInputCountry: process.env.REACT_APP_DEFAULT_PHONE_INPUT_COUNTRY || "NO"
  }