import { useToastAction } from "@metaforcelabs/metaforce-core";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import Select from "react-select";

export const OnTransferRecordModal = ({
    modalHelper,
    selectedRecord,
    onTransferRecord,
    allUsers,
}) => {
    const loadAction = useToastAction();
    const transferAction = useToastAction();
    const [allUsersOptions, setAllUsersOptions] = useState([]);
    const [selectedUserOption, setSelectedUserOption] = useState(null);

    useEffect(() => {
        if (modalHelper.isOpen) {
            load();
        }
    }, [modalHelper.isOpen]);

    function load() {
        loadAction.execute(() => {
            const options = [];
            allUsers?.forEach((user) => {
                options.push({
                    value: user.id,
                    label: user.name,
                    email: user.email,
                });
            });
            setAllUsersOptions(options);
        }, "Failed to load folders");
    }

    const onCancel = () => {
        modalHelper.close();
        setSelectedUserOption(null);
    };

    return (
        <Modal
            isOpen={modalHelper.isOpen}
            onClose={modalHelper.close}
            size="medium"
            title="Transfer Smartform to another user"
        >
            <Select
                className="relative"
                name="transferUser"
                options={allUsersOptions}
                onChange={(e) =>
                    setSelectedUserOption({
                        userId: e.value,
                        userEmail: e.email
                    })
                }
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            <div className="mt-10 flex gap-2 justify-end">
                <button
                    onClick={() =>{
                        onTransferRecord(selectedRecord, selectedUserOption)
                        onCancel();
                    }
                    }
                    type="button"
                    className="mt-3 ml-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                    disabled={transferAction.isExecuting || selectedUserOption === null}
                >
                    Transfer Smartform
                </button>
                <button
                    onClick={onCancel}
                    type="button"
                    className="mt-3 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
};
