import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import { useToastAction } from '../../../hooks/useToastAction';
import React, { useEffect, useState, useRef } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { getAllAccessGroups } from '../../../api/accessGroups';
import { getDialogSecurity, updateDialogSecurity } from '../../../api/dialogSecuirty';
import { useClassNames } from '../../../hooks/useClassNames';
import useClickOutside from '../../../hooks/useClickOutside';
import Loader from '../../../components/Loader';

export default function DialogSecurity() {
  let { dialogKey } = useParams();
  const { classNames } = useClassNames();
  const [allAccessGroups, setAllAccessGroups] = useState([]);
  const [selectedAccessGroups, setSelectedAccessGroups] = useState([]);
  const [dialogSecurity, setDialogSecurity] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const [isNoChanges, setIsNoChanges] = useState(true);
  const [activeSourceItem, setActiveSourceItem] = useState(null);
  const [activeDragItem, setActiveDragItem] = useState(null);
  const [activeSelectedItem, setActiveSelectedItem] = useState(null);
  const [activeDragDestination, setActiveDragDestination] = useState(null);
  const [destinationDropDisabled, setDestinationDropDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const loadAction = useToastAction();
  const saveAction = useToastAction();
  const allAccessGroupsListRef = useRef(null);
  const selectedAccessGroupsListRef = useRef(null);

  useClickOutside(allAccessGroupsListRef, () => {
    setActiveSourceItem(null)
  });

  useClickOutside(selectedAccessGroupsListRef, () => {
    setActiveSelectedItem(null);
  });

  const loadData = () => {
    loadAction.execute(async () => {
      const data = await getAllAccessGroups();
      const ds = await getDialogSecurity(dialogKey);
      setDialogSecurity(ds);
      setAllAccessGroups(data);
      const selected = mapSelectedAccessGroups(ds, data);
      setSelectedAccessGroups([...selected]);
      setIsLoading(false);
    }, "Failed to laod");
  }


  const handleAddSourceItem = () => {
    if (activeSourceItem) {
      // const addObj = createNewObjectInstance(activeSourceItem.objectId);
      // addObj.name = { ...activeSourceItem }.name;
      // const selected = [...selectedObjects, addObj];
      // setSelectedObjects(prev => selected);
      setSelectedAccessGroups(prev => {
        return [...prev, activeSourceItem];
      });

    }
  }

  const selectedItemDragged = (onActiveCssClass) => {
    if (!activeDragItem) {
      return "";
    }

    return activeDragItem?.source?.droppableId === "selected" ? onActiveCssClass : "";
  }

  const selectedOverDelete = () => {
    return activeDragItem?.source?.droppableId === "selected" && activeDragDestination?.droppableId === "delete";
  }

  useEffect(() => {
    setIsNoChanges(_.isEqual(selectedAccessGroups.map(x => x.id), dialogSecurity.accessGroups))
  }, [selectedAccessGroups, dialogSecurity.accessGroups])

  const handleRemoveSelected = () => {
    if (activeSelectedItem) {
      let clones = _.cloneDeep(selectedAccessGroups);
      const activeIdx = clones.findIndex(x => x.id === activeSelectedItem?.id);
      let items = [...clones.filter(x => x.id !== activeSelectedItem?.id)];

      if (items.length === 0) {
        setActiveSelectedItem(null)
      } else {
        const newSelectedIdx = activeIdx === items.length ? items.length - 1 : activeIdx;
        setActiveSelectedItem(prev => items[newSelectedIdx])
      }

      setSelectedAccessGroups(prev => items);
    }
  }

  const handleDragStart = (source) => {
    const sourceIndex = source?.source?.index;
    setDestinationDropDisabled(selectedAccessGroups.findIndex(x => x.id === allAccessGroups[sourceIndex]?.id) !== -1)
    setActiveDragItem(source);
    const isFromSource = source?.draggableId.includes('source');
    const idWithoutPrefix = source?.draggableId.split("-")[0];
    if(isFromSource){
      setActiveSourceItem(allAccessGroups.find(ag => ag.id === idWithoutPrefix));
    } else {
      setActiveSelectedItem(selectedAccessGroups.find(ag => ag.id === idWithoutPrefix))
    }
  }

  const handleDragUpdate = (draggable) => {
    const { destination } = draggable;
    setActiveDragDestination(destination)
  }

  const handleDragEnd = ({ destination, source, ...result }) => {
    setActiveDragItem(null);
    setActiveDragDestination(null);
    let items = [...selectedAccessGroups];
    // selected dropped on delete
    if (source?.droppableId === "selected" && destination?.droppableId === "delete") {
      items.splice(source.index, 1)
      items = [...items]
      setSelectedAccessGroups(
        items
      );
      return;
    }

    // const item = allObjects.find(x => x.id === result.draggableId);
    //source dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === "source" && destination.droppableId === "selected") {
      const item = allAccessGroups[source.index];
      const existingIdx = selectedAccessGroups.findIndex(x => x.id === item.id);
      if (existingIdx === -1) {
        items = [...items, item];
      }
    }

    updateSelectedObjects(items);

    const isFromSource = source?.droppableId.includes('source');
    if(isFromSource){
      setActiveSourceItem(null);
    } else {
      setActiveSelectedItem(null)
    }
  }

  const updateSelectedObjects = (updatedList) => {
    setSelectedAccessGroups(
      updatedList
    );

    // if (updatedList.length > 0) {
    //     setErrorMessage("")
    // } else {
    //     setErrorMessage("At lease one object required")
    // }
  }

  const mapSelectedAccessGroups = (ds, accessGroups) => {
    return ds?.accessGroups.map(x => accessGroups.find(a => a.id === x) || { name: "(Unavailable)", id: x });

  }

  const handleResetClick = () => {
    const selected = mapSelectedAccessGroups(dialogSecurity, allAccessGroups);
    setSelectedAccessGroups([...selected]);
  }

  const handleSave = () => {
    saveAction.execute(async () => {
      await updateDialogSecurity(dialogKey, selectedAccessGroups);

      setDialogSecurity(prev => {
        return { ...prev, accessGroups: selectedAccessGroups.map(x => x.id) };
      })
    }, "Save failed", "Access Groups Saved");
  }

  const selectedAccessGroupsExists = (accessGroupId) => {
    return allAccessGroups.findIndex(x => x.id === accessGroupId) !== -1;
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="pb-4">
      <div className="pb-5 border-b border-gray-200 mt-10 mb-6">
        <h1 className="text-2xl leading-6 font-medium  text-gray-900">Smartform Security</h1>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
          All users will by default have access to a Smartform. You can restrict access by attaching access groups to a Smartform
        </p>
      </div>
      <div className="bg-white shadow rounded-lg mt-4 p-8">
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragUpdate={handleDragUpdate}>
          <div>
            <div className="mb-4 flex justify-between border-b pb-4 mb-2">
              <div>
                <h1 className="mb-2 text-xl leading-6 font-medium  text-gray-900">Access Groups</h1>
                <p className="mb-2 max-w-4xl text-sm text-gray-500">
                  Drag and drop or select Access Groups
                </p>
              </div>
              <div className='hidden sm:inline-block space-x-4'>
                <button
                  type="button"
                  id={"reset-btn"}
                  onClick={e => {
                    handleResetClick();
                  }}
                  disabled={isLoading}
                  className={`w-1/2 sm:w-auto inline-flex items-center w-auto justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm`}
                >
                  <span>Reset</span>
                </button>
                <button
                  type="button"
                  id={"save-btn"}
                  disabled={isNoChanges || isLoading}
                  onClick={e => {
                    handleSave();
                  }}
                  className={`w-1/2 sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded text-white text-sm bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400`}
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
            <div
              className={classNames('grid grid-cols-12 pb-4 space-x-1 sm:space-x-4 rounded-lg')}>
              <div className="col-span-5">
                <Droppable droppableId="source" isDropDisabled={true}>
                  {(provided, snapshot) => (<div className='space-y-2'>
                    <div className=''>
                      <label className='font-semibold'>All Access Groups</label>
                    </div>
                    <div className="relative" ref={allAccessGroupsListRef}>
                      <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="border border-gray-200 rounded-md h-80 overflow-y-auto shadow"
                      >
                        { isLoading && 
                           <div className='w-full h-full flex flex-col justify-center items-center'>
                            <Loader/>
                            <p className='leading-6 text-sm text-gray-500'>Loading Access Groups</p>
                          </div>
                        }
                        { !isLoading && allAccessGroups.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id + "-source"} index={index}>
                              {(provided, { isDragging }) => (
                                <div>
                                  <li
                                    ref={provided.innerRef}
                                    onClick={e => {
                                      setActiveSourceItem(item)
                                    }}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div
                                      className={classNames("px-2 sm:px-4 py-1.5 text-gray-600 space-x-2 sm:space-x-4 text-sm sm:text-md",
                                        isDragging ? "rounded-md border border-indigo-500 shadow-sm" : "border-b",
                                        activeSourceItem?.id === item.id ? "rounded-md border border-indigo-500" : "")}
                                    >
                                      <span>{index + 1}.</span> <span className={classNames('font-semibold')}>{item.name}</span>
                                    </div>
                                  </li>
                                  {
                                    isDragging && (
                                      <div
                                        className={classNames("px-2 sm:px-4 py-1.5 text-gray-600 space-x-2 sm:space-x-4 text-sm sm:text-md border-b",
                                          activeSourceItem?.id === item.id ? "rounded-md border border-indigo-500" : "")}
                                      >
                                        <span>{index + 1}.</span> <span className='font-semibold'>{item.name}</span>
                                      </div>
                                    )
                                  }
                                </div>

                              )}
                            </Draggable>
                          ))
                        }
                          {!provided.placeholder}
                        </ul>
                      {
                        activeSourceItem && !activeDragItem &&
                        (
                          <SourceListActionMenu onItemAdd={handleAddSourceItem} />
                        )
                      }
                    </div>
                  </div>
                  )}
                </Droppable>
              </div>
              <div className="col-span-2 flex">
                <Droppable droppableId="delete">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={classNames("flex-1 flex mt-14 mb-6 border-2 border-transparent border-dotted rounded items-center justify-center", selectedItemDragged("border-red-200"), snapshot.isDraggingOver && activeDragItem?.source?.droppableId === "selected" ? "border-red-400" : "")}>
                      <TrashIcon className={classNames("h-10 w-10 text-transparent", selectedItemDragged("text-red-200"))}></TrashIcon>
                      <span className="hidden">{provided.placeholder}</span>
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="col-span-5">
                <Droppable droppableId="selected" isDropDisabled={destinationDropDisabled}>
                  {(provided, snapshot) => (
                    <div className='space-y-2'>
                      <div className=''>
                        <label className='font-semibold'>Selected Objects</label>
                      </div>
                      <div className="relative" ref={selectedAccessGroupsListRef}>
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="border border-gray-200 rounded-md h-80 overflow-y-auto overflow-x-hidden shadow w-full"
                        >
                          { isLoading && 
                            <div className='w-full h-full flex flex-col justify-center items-center'>
                              <Loader/>
                              <p className='leading-6 text-sm text-gray-500'>Loading Selected Objects</p>
                            </div>
                          }
                          { !isLoading && selectedAccessGroups.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id + "-selected"} index={index}>
                              {(provided, { isDragging }) => (
                                <li
                                  ref={provided.innerRef}
                                  onClick={e => setActiveSelectedItem(item)}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    className={classNames("px-2 sm:px-4 py-1.5 space-x-2 sm:space-x-4 text-sm sm:text-md",
                                      isDragging ? classNames("rounded-md border shadow-sm border-indigo-500", selectedOverDelete() ? "inline-block px-2 py-.5 space-x-2 truncate w-16 sm:w-20" : "") : "border-b",
                                      selectedAccessGroupsExists(item.id) ? "text-gray-600" : "text-gray-400",
                                      activeSelectedItem?.id === item.id ? "rounded-md border border-indigo-500" : "")}
                                  >
                                    <span>{index + 1}.</span> <span className={classNames('font-semibold')}>{item.name}</span>
                                  </div>
                                </li>
                              )}
                            </Draggable>
                          ))
                        }
                          {
                            !snapshot?.isDraggingOver && !isLoading && selectedAccessGroups?.length === 0 && (
                              <div
                                className={classNames("px-2 sm:px-4 py-1.5 text-gray-600 space-x-2 sm:space-x-4 text-sm sm:text-md border-b")}
                              >
                                <span></span> <span className='font-semibold'> {'<All>'}</span>
                              </div>
                            )
                          }
                          {provided.placeholder}
                        </ul>
                        {
                          activeSelectedItem && !activeDragItem &&
                          (
                            <SelectedListActionMenu onRemove={() => handleRemoveSelected()}
                            // onMoveUp={() => handleMoveUp()} onMoveDown={() => handleMoveDown()} 
                            />
                          )
                        }
                      </div>

                    </div>
                  )}
                </Droppable>
              </div>

            </div>
            <div className='py-1 text-red-500'>
              {errorMessage}
            </div>
            <div className='flex space-x-4 sm:hidden'>
              <button
                type="button"
                id={"reset-btn"}
                onClick={e => {
                  handleResetClick();
                }}
                className={`w-1/2 sm:w-auto flex items-center w-auto justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm`}
              >
                <span>Reset</span>
              </button>
              <button
                type="button"
                id={"save-btn"}
                onClick={e => {
                  handleSave();
                }}
                disabled={isNoChanges || isLoading}
                className={`w-1/2 sm:w-auto flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded text-white text-sm bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400`}
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        </DragDropContext>
      </div>
    </div>
  )
}

const SelectedListActionMenu = ({ onRemove, onMoveUp, onMoveDown }) => {
  return (
    <div className="absolute top-24 -left-12 grid grid-cols-2 space-y-2">
      <div className="row-span-2 flex items-center justify-center mt-2">
        <div className="border rounded-full p-2 text-white bg-indigo-600 hover:bg-indigo-500 shadow-sm cursor-pointer" onClick={e => onRemove()} title="Add Object">
          <TrashIcon className="h-5 w-5 text-white" />
        </div>
      </div>
    </div>
  )
}

const SourceListActionMenu = ({ onItemAdd }) => {
  return (
    <div className={`absolute top-24 -right-12 grid grid-cols-1 grid-rows-2`}>
      <div className="row-span-2 flex items-center justify-center mt-2">
        <div className="border rounded-full p-2 text-white bg-indigo-600 hover:bg-indigo-500 shadow-sm cursor-pointer" onClick={e => onItemAdd()} title="Add Object">
          <PlusIcon className="h-5 w-5 text-white" />
        </div>
      </div>
    </div>
  )
}
