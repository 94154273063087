import { Form, Formik } from "formik";
import React, {  } from "react";
import * as yup from "yup";
import { FormikTextInput } from "../../../components/Form/Formik/FormikTextInput";
import { FormikSubmitButton } from "../../../components/Form/Formik/FormikSubmit";
import { FormikCheckbox } from "../../../components/Form/Formik/FormikCheckbox";
import FormikRadioButton from "../../../components/Form/Formik/FormikRadioButton";
import { useDebouncedSave } from "../../../hooks/useDebouncedSave";
import { FormikFormObserver } from "../../../components/Form/Formik/FormikFormObserver";

export const documentSigningModes = {
  multiple: 'multiple',
  single: 'single'
}

export default function NormalProcessDigitalSigning({ digitalSigningProviderName, dialogDefinition, onSaveDigitalSigning }) {

  const { hasDigitalSigning, digitalSigningMessage, digitalSigningSignNowMessage, digitalSigningSignNowDescription,
    digitalSigningSignNowBtnText, digitalSigningAskOtherBtnText, digitalSigningSubmit, digitalSigningAdd,
    digitalSigningCancel, digitalSigningCancelBtnText, digitalSigningError, digitalSigningDescription, digitalSigningFirstColumn, digitalSigningSecondColumn,
    digitalSigningDocumentSigningMode, digitalSigningAllowDirectSigning
  } = dialogDefinition;

  const debouncedSaved = useDebouncedSave((values) => {
    onSaveDigitalSigning(values)
  })

  return (
    <Formik
      initialValues={{
        hasDigitalSigning,
        digitalSigningDocumentSigningMode: digitalSigningDocumentSigningMode || documentSigningModes.multiple,
        digitalSigningMessage: digitalSigningMessage || '',
        digitalSigningSignNowMessage: digitalSigningSignNowMessage || '',
        digitalSigningSignNowDescription: digitalSigningSignNowDescription || 'I confirm i have have authority to sign.',
        digitalSigningSignNowBtnText: digitalSigningSignNowBtnText || 'Sign Now',
        digitalSigningAskOtherBtnText: digitalSigningAskOtherBtnText || 'Ask Somebody else',
        digitalSigningSubmit: digitalSigningSubmit || 'Submit',
        digitalSigningAdd: digitalSigningAdd || 'Add',
        digitalSigningCancel: digitalSigningCancel || 'Cancel',
        digitalSigningCancelBtnText: digitalSigningCancelBtnText || 'Cancel',
        digitalSigningError: digitalSigningError || 'Error',
        digitalSigningDescription: digitalSigningDescription || 'No parties',
        digitalSigningFirstColumn: digitalSigningFirstColumn || 'Name',
        digitalSigningSecondColumn: digitalSigningSecondColumn || 'Email',
        digitalSigningAllowDirectSigning: digitalSigningAllowDirectSigning
      }}
      validationSchema={() => {
        return yup.object().shape({
          digitalSigningSignNowMessage: yup
            .string()
            .when('hasDigitalSigning', {
              is: (value) => value,
              then: yup.string('Text only').required('Required'),
              otherwise: yup.string()
            }),
          digitalSigningSignNowBtnText: yup
            .string()
            .when('hasDigitalSigning', {
              is: (value) => value,
              then: yup.string('Text only').max(30, 'Max 30 characters').required('Required'),
              otherwise: yup.string()
            }),
          digitalSigningCancelBtnText: yup
            .string()
            .when('hasDigitalSigning', {
              is: (value) => value,
              then: yup.string('Text only').max(30, 'Max 30 characters').required('Required'),
              otherwise: yup.string()
            }),
          digitalSigningAskOtherBtnText: yup
            .string()
            .when('hasDigitalSigning', {
              is: (value) => value,
              then: yup.string('Text only').max(30, 'Max 30 characters').required('Required'),
              otherwise: yup.string()
            }),

        });
      }}

      onSubmit={async (values, actions) => {
        onSaveDigitalSigning(values);
        actions.setSubmitting(false);
      }}
    >
      {
        props => (
          <>
            <FormikFormObserver onFormChange={values => {
              // This logic is replicated on the backend
              if (values?.digitalSigningDocumentSigningMode !== documentSigningModes.single) {
                props.setFieldValue("digitalSigningAllowDirectSigning", false, false);
              }
              debouncedSaved.save(values)
            }}>
              <Form>
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 ">
                  <div className="shadow sm:rounded-md sm:overflow-hidden mb-6">
                    <div className="bg-white py-6 px-4 sm:p-6">
                      <div className="my-2">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">General</h3>
                      </div>

                      <div className="my-6 grid grid-cols-4 gap-6">

                        <div className="col-span-4 sm:col-span-4">
                          <FormikCheckbox
                            label={<>Require digital signing.<span className="ml-2 font-bold">(Provider: {digitalSigningProviderName})</span></>}
                            secondaryLabel={`Smartform require digital signing`}
                            name="hasDigitalSigning"
                            formikProps={props}
                            styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                          />
                        </div>
                      </div>

                      {
                        props.values.hasDigitalSigning && (
                          <>
                            <div className="w-full border-t border-gray-200 my-5" />
                            <div className="my-6">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Configuration</h3>
                            </div>

                            <div className="my-6 grid grid-cols-4 gap-6">
                              <div className="col-span-4">
                                <FormikRadioButton
                                  value={documentSigningModes.multiple}
                                  label="Allow last party in process to define e-mail adresses that shall sign the document"
                                  name="digitalSigningDocumentSigningMode"
                                  formikProps={props}
                                  defaultChecked={props.values.digitalSigningDocumentSigningMode === documentSigningModes.multiple}
                                  styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600"
                                />
                              </div>
                              <div className="col-span-4">
                                <FormikRadioButton
                                  value={documentSigningModes.single}
                                  label="Only allowed to enter one e-mail adress to sign the Smartform"
                                  name="digitalSigningDocumentSigningMode"
                                  formikProps={props}
                                  defaultChecked={props.values.digitalSigningDocumentSigningMode === documentSigningModes.single}
                                  styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600"
                                />
                              </div>
                              <div className="ml-6 -mt-2 col-span-4">
                                <FormikCheckbox
                                  label="Allow direct signing of Smartform"
                                  secondaryLabel="User can sign directly in Smartforms without receiving a link on e-mail"
                                  name="digitalSigningAllowDirectSigning"
                                  formikProps={props}
                                  disabled={props.values.digitalSigningDocumentSigningMode !== documentSigningModes.single}
                                  styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                                />
                              </div>
                            </div>
                            <div className="w-full border-t border-gray-200 my-5" />
                            <div className="my-6">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Sign Now dialog</h3>
                            </div>
                            <div className="mt-6 grid grid-cols-4 gap-6">
                              <div className="col-span-4 sm:col-span-4">
                                <FormikTextInput
                                  label="Sign now message"
                                  name="digitalSigningSignNowMessage"
                                  formikProps={props}
                                  rows={4}
                                />
                              </div>
                              <div className="col-span-4 sm:col-span-4">
                                <FormikTextInput
                                  label="Sign now description"
                                  name="digitalSigningSignNowDescription"
                                  formikProps={props}
                                  rows={4}
                                />
                              </div>
                              <div className="col-span-4 sm:col-span-2">
                                <FormikTextInput
                                  label="Sign now button"
                                  name="digitalSigningSignNowBtnText"
                                  formikProps={props}
                                />
                              </div>
                              <div className="col-span-4 sm:col-span-2">
                                <FormikTextInput
                                  label="Ask somebody else button"
                                  name="digitalSigningAskOtherBtnText"
                                  formikProps={props}
                                />
                              </div>
                              <div className="col-span-4 sm:col-span-2">
                                <FormikTextInput
                                  label="Cancel button"
                                  name="digitalSigningCancelBtnText"
                                  formikProps={props}
                                />
                              </div>
                            </div>
                            <div className="w-full border-t border-gray-200 my-5" />
                            <div className="my-6">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Define Signatories dialog</h3>
                            </div>
                            <div className="mt-6 grid grid-cols-4 gap-6">
                              <div className="col-span-4 sm:col-span-4">
                                <FormikTextInput
                                  label="Digital Signing message"
                                  name="digitalSigningMessage"
                                  formikProps={props}
                                  rows={4}
                                />
                              </div>
                              <div className="col-span-4 sm:col-span-2">
                                <FormikTextInput
                                  label="First Column"
                                  name="digitalSigningFirstColumn"
                                  formikProps={props}
                                />
                              </div>
                              <div className="col-span-4 sm:col-span-2">
                                <FormikTextInput
                                  label="Second Column"
                                  name="digitalSigningSecondColumn"
                                  formikProps={props}
                                />
                              </div>
                              <div className="col-span-4 sm:col-span-2">
                                <FormikTextInput
                                  label="Description"
                                  name="digitalSigningDescription"
                                  formikProps={props}
                                />
                              </div>
                              <div className="col-span-4 sm:col-span-2">
                                <FormikTextInput
                                  label="Error"
                                  name="digitalSigningError"
                                  formikProps={props}
                                />
                              </div>
                              <div className="col-span-4 sm:col-span-2">
                                <FormikTextInput
                                  label="Add button"
                                  name="digitalSigningAdd"
                                  formikProps={props}
                                />
                              </div>
                              <div className="col-span-4 sm:col-span-2">
                                <FormikTextInput
                                  label="Cancel button"
                                  name="digitalSigningCancel"
                                  formikProps={props}
                                />
                              </div>
                              <div className="col-span-4 sm:col-span-2">
                                <FormikTextInput
                                  label="Submit button"
                                  name="digitalSigningSubmit"
                                  formikProps={props}
                                />
                              </div>
                            </div>
                          </>
                        )
                      }


                    </div>
                  </div>
                </div>

                <div className="pt-2 flex justify-end hidden">
                  <FormikSubmitButton
                    id="save"
                    text="Save"
                    className="i"
                    formikProps={props}
                  />
                </div>
              </Form>
            </FormikFormObserver>
          </>
        )
      }
    </Formik >

  )
}