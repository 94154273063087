import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux"
import { Link, NavLink, useParams } from "react-router-dom";
import { getDefinitionLanguages } from "../../api/definitionLanguage";
import { ContainerContext, DesignActionbarContext, TopbarContext } from "../../contexts";
import { dialogObjectStatuses, processTypes } from "../../utils/constants"
import { isContainerLocked } from "../../utils/dialogDefinitions";
import { Locked } from "../../structures/Container/locked";
import Properties from "../../structures/Container/properties";

export default function DialogDefinitionHelp() {
    const { dialogKey } = useParams();
    
    const { setActiveTab, onActivate } = useContext(DesignActionbarContext);
    const { currentContainer, currentContainerId, showPropertiesPanel, actions } = useContext(ContainerContext)
    const { setOpenHelperSidebar } = useContext(TopbarContext);
    const currentDialogDefinition = useSelector(state => state.dialogDefinitions.current)        

    const [definitionLanguages, setDefinitionLanguages] = useState([]);

    const handleSetActiveTab = (tab) => {
        setActiveTab(tab);
        setOpenHelperSidebar(false);
    }

    const handleOpenPropertiesTab = () => {
        if(!currentContainer || !currentContainerId) return;

        const isLocked = isContainerLocked(currentContainer)
        if (isLocked) {
            showPropertiesPanel(currentContainerId, currentContainer, isLocked, (<Locked containerId={currentContainerId} initData={currentContainer} actions={actions} />))
        } else {
            showPropertiesPanel(currentContainerId, currentContainer, isLocked, (<Properties initData={currentContainer} actions={actions} />))
        }
        setOpenHelperSidebar(false);
    }

    useEffect(() => {
        load();
      }, [])
    
    const load = async () => {
        let definitionLanguages = await getDefinitionLanguages(dialogKey);
        setDefinitionLanguages(definitionLanguages);
    }

    return (
        <div className="grid gap-2">
            {
                currentDialogDefinition?.status === dialogObjectStatuses.inactive &&
                <div>Do you want to <button className="text-indigo-600 hover:text-indigo-500" onClick={onActivate}>activate</button> your Smartform?</div>
            }
            
            <div>Would you like to see a <Link target="_blank" to={`/dialog/${dialogKey}/preview`} className="text-indigo-600 hover:text-indigo-500">preview</Link> of your Smartform?</div>
            
            <div>You can change <button className="text-indigo-600 hover:text-indigo-500" onClick={() => handleSetActiveTab('theme')}>layout</button> on your form</div>
            
            <div><Link to="#companyPresentation" className="text-indigo-600 hover:text-indigo-500" onClick={() => handleSetActiveTab('add')}>Objects</Link> can easily be added</div>
            
            <div>Add <NavLink to={`/admin/dialog/${dialogKey}/security`} className="text-indigo-600 hover:text-indigo-500">access control</NavLink> to the form</div>
            {
                definitionLanguages.length < 1 &&
                <div>Add <NavLink to={`/admin/dialog/${dialogKey}/configurations#languages-heading`} className="text-indigo-600 hover:text-indigo-500">multi language</NavLink> support to the form</div>
            }
            
            {
                definitionLanguages.length > 0 &&
                <div>Warning - You got items to <NavLink to={`/admin/dialog/${dialogKey}/language`} className="text-indigo-600 hover:text-indigo-500">multi language</NavLink></div>
            }

            {
                currentDialogDefinition.containers?.length > 1 && currentDialogDefinition.process === processTypes.normal &&
                <div>Since your Smartform contains more than one section with input fields you can add a <NavLink to={`/admin/dialog/${dialogKey}/process`} className="text-indigo-600 hover:text-indigo-500">process</NavLink> to the form if more than one person shall fill it out.</div>
            }
            {
                currentDialogDefinition.containers?.length > 0 && currentContainer && currentContainerId &&
                <div>Change <button className="text-indigo-600 hover:text-indigo-500" onClick={() => handleOpenPropertiesTab()}>background</button> of the section you are editing</div>
            }
        </div>
    )
  }
  