import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button } from '../../../components/Properties'
import { VerticalStepper } from '../../../components/VerticalStepper'
import { ProcessSettings } from './settings'
import { getDialogDefinitionEditMode, updateDialogDefinitionProcess, updateDialogDefinitionProperties } from '../../../api/dialogDefinition'
import { useToastAction } from "../../../hooks/useToastAction";
import { processTab, processTypeOptions, processTypes } from "../../../utils/constants";
import { moveItem } from "../../../utils/array";
import { Select } from "../../../components/Form/Select";
import { createParticipatingParty, getParticipatingParties } from "../../../api/participatingParties";
import * as _ from 'lodash'
import {
  createDefinitionProcessStep,
  deleteDefinitionProcessSteps,
  getDefinitionProcess,
  getDefinitionProcessSteps,
  patchDefinitionProcessStep
} from "../../../api/definitionProcesses";
import { useDebounceCallback } from "@react-hook/debounce";
import Enumerable from "linq";
import { ParticipatingParties } from "./participatingParties";
import { PhoneNumbers } from "./phoneNumbers";
import { useClassNames } from "@metaforcelabs/metaforce-core";
import ProcessTabs from "./processTabs";
import DigitalSigning from "./digitalSigning";
import { getCustomerIntegrations } from "../../../api/customerIntegrations";
import { getAllActiveContactLists } from "../../../api/contactLists";
import NormalProcess from "./normalProcess";
import { countryList } from "../../../utils/countryList";
import { AdminlayoutContext } from "../../../contexts";
import { useSelectInputHelpers } from "../../../hooks/useSelectInputHelpers";
import { useTemplateLanguageActions } from "../../../hooks/useTemplateLanguageActions";

export const DialogProcess = () => {
  const { dialogKey } = useParams();
  const loadDefinitionAction = useToastAction();
  const submitAction = useToastAction();
  const addStepAction = useToastAction(true);
  const deleteStepAction = useToastAction();
  const patchStepAction = useToastAction(true);

  const [dialogDefinition, setDialogDefinition] = useState();
  const [containers, setContainers] = useState([]);
  const [steps, setSteps] = useState([]);
  const [currentStepId, setCurrentStepId] = useState(null)
  const [participatingParties, setParticipatingParties] = useState([]);
  const [contactLists, setContactLists] = useState([]);
  const [definitionProcess, setDefinitionProcess] = useState();
  const [customerIntegrations, setCustomerIntegrations] = useState([]);
  const adminLayoutContext = useContext(AdminlayoutContext)
  const templateLanguageActions = useTemplateLanguageActions(adminLayoutContext.templateLanguages)

  useEffect(() => {
    load();
  }, [])

  const load = async () => {
    loadDefinitionAction.execute(async () => {

      const [dialogDefinitionResult, participatingParties, definitionProcess, customerIntegrations, contactListResult] = await Promise.all([
        getDialogDefinitionEditMode(dialogKey),
        getParticipatingParties(dialogKey),
        getDefinitionProcess(dialogKey),
        getCustomerIntegrations(),
        getAllActiveContactLists()
      ])

      const steps = await getDefinitionProcessSteps(definitionProcess.id);
      
      setDialogDefinition(dialogDefinitionResult);
      setContainers(dialogDefinitionResult.containers);
      setParticipatingParties(participatingParties);
      setDefinitionProcess(definitionProcess);
      setContactLists(contactListResult);
      setCustomerIntegrations(customerIntegrations);

      setSteps(steps);

      if (steps.length > 0) {
        setCurrentStepId(steps[0].id)
      }

    }, "Failed to load")
  }

  const onProcessChange = (process) => {
    submitAction.execute(async () => {
      const dialogDefinitionResult = await updateDialogDefinitionProcess(dialogKey, process);
      setDialogDefinition(dialogDefinitionResult);

      if (process === processTypes.multiStep && !steps.length) onAddStepClick();

    }, "Failed to save", "Saved")
  }

  const onContactListChange = async (contactListId) => {
    await handleDialogDefinitionPropertyChange("contactListId", contactListId);
  }

  const onDefaultLanguageChange = async (languageCode) => {
    await handleDialogDefinitionPropertyChange("defaultLanguage", languageCode);
  }

  const handleDialogDefinitionPropertyChange = async (propName, value) => {
    submitAction.execute(async () => {
      const dialogDefinitionResult = await updateDialogDefinitionProperties(dialogKey, { ...dialogDefinition, [propName]: value });
      setDialogDefinition(dialogDefinitionResult);
    }, "Failed to save", "Saved");
  }

  const handleSaveDigitalSigning = (digitalSigningValues) => {
    submitAction.execute(async () => {
      const updatedDialogDefinition = await updateDialogDefinitionProperties(dialogKey, {
        ...dialogDefinition,
        ...digitalSigningValues
      });
      setDialogDefinition(updatedDialogDefinition)
    }, "Failed to save", "Saved")
  }

  const onStepClick = (stepId) => {
    const mainContainer = document.getElementsByClassName("main-container")
    if (mainContainer[0]) {
      mainContainer[0].scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    setCurrentStepId(stepId)
  }

  const onAddStepClick = () => {
    addStepAction.execute(async () => {
      if (steps?.length >= participatingParties.length) {
        const result = await createParticipatingParty(dialogKey, { name: `Participating Party Nº${participatingParties.length + 1}`, emailOnCompletion: false, digitalSigning: false })
        setParticipatingParties([...participatingParties, result])
      }

      const step = await createDefinitionProcessStep(definitionProcess.id, participatingParties[0].id);
      setSteps([...steps, step]);
      onStepClick(step.id);
    }, "Failed to add step")
  }

  const onDeleteStepClick = () => {
    if (currentStepId) {
      deleteStepAction.execute(async () => {
        const remaining = steps.filter(s => s.id !== currentStepId)
        if (remaining.length > 0) {
          await deleteDefinitionProcessSteps(definitionProcess.id, currentStepId)
          const lastStep = remaining[remaining.length - 1];
          setCurrentStepId(lastStep.id)
          await syncStepsOrder(remaining)
        } else {
          onProcessChange(processTypes.normal)
        }
      }, "Failed to delete")
    }
  }

  const currentStep = () => {
    if (!currentStepId) {
      return null
    }

    return steps.find(s => s.id === currentStepId)
  }

  const debouncedStepChanged = useDebounceCallback((name, value) => {
    patchStepAction.execute(async () => {
      const payload = {}
      payload[name] = value;
      await patchDefinitionProcessStep(definitionProcess.id, currentStepId, payload)
    }, "Failed to update")
  }, 250);

  const onStepPropertyChange = async (value, name) => {
    if (name === "order") {
      moveItem(steps, currentStep().order, value)
      await syncStepsOrder(steps)
    } else {
      const stepIndex = steps.findIndex(s => s.id === currentStepId)
      _.set(steps, `[${stepIndex}].${name}`, value);
      setSteps([...steps])
      debouncedStepChanged(name, value)
    }
  }

  const onUpdateListProperty = async (propertyName, addToList, value) => {
    await patchStepAction.execute(async () => {
      let list = currentStep()[propertyName] || []
      if (addToList) {
        list.push(value)
      } else {
        list = list.filter(v => v !== value);
      }

      const payload = {}
      payload[propertyName] = list

      await patchDefinitionProcessStep(definitionProcess.id, currentStepId, payload)
      currentStep()[propertyName] = list
      setSteps([...steps])
    }, "Failed to update");
  }

  const syncStepsOrder = async (stepsToSync) => {
    stepsToSync.forEach(async (s, i) => {
      s.order = i
      await patchDefinitionProcessStep(definitionProcess.id, s.id, { order: s.order })
    })
    setSteps([...stepsToSync])
  }

  const contactListOptions = () => {
    const options = [{ value: '', name: 'Standard' }]
    contactLists?.map(c => options.push({ value: c.id, name: `Contactlist: ${c.name}` }))

    return options
  }

  return (
    <div className="max-w-screen-2xl mx-auto px-4 lg:px-6 hidden lg:block">
      {
        dialogDefinition && containers &&
        <>
          <div className='max-w-7xl grid grid-cols-12 gap-4 mt-5'>
            <div className='flex flex-col col-span-12 md:col-span-4 lg:col-span-3'>
              <Select
                label="Select process style"
                name="process"
                selectedValue={dialogDefinition.process || processTypes.normal}
                onChange={onProcessChange}
                options={processTypeOptions}
              />
            </div>
          </div>
          {
            dialogDefinition.process === processTypes.multiStep &&

            <div className="">
              <div className="border-t border-gray-200 my-5" />

              <ProcessTabs tabs={[
                { key: processTab.processSteps, name: 'Process steps', },
                { key: processTab.parties, name: 'Parties', },
                { key: processTab.digitalSigning, name: 'Digital Signing', }
              ]}
                defaultTab={processTab.processSteps}
              >
                {
                  ({ currentTab }) => (
                    <div>

                      {
                        currentTab === processTab.processSteps && currentStep() &&
                        <ProcessSettings
                          containers={containers}
                          step={currentStep()}
                          participatingParties={participatingParties}
                          onPropertyChange={onStepPropertyChange}
                          onUpdateListProperty={onUpdateListProperty}
                          numberOfSteps={steps?.length}
                          contactLists={contactLists}
                          steps={steps}
                          onStepClick={onStepClick}
                          onDeleteStepClick={onDeleteStepClick}
                          onAddStepClick={onAddStepClick}
                          currentStepId={currentStepId}
                          customerIntegrations={customerIntegrations}
                          alreadySelectedContainerIds={Array.prototype.concat(...steps.filter(s => s.id !== currentStepId).map(s => s.containerDefinitionIds))}
                        />
                      }
                      {
                        currentTab === processTab.parties && (
                          <ParticipatingParties dialogKey={dialogKey} onChange={(parties) => setParticipatingParties(parties)} setParticipatingParties={setParticipatingParties} participatingParties={participatingParties} />
                        )
                      }

                      {
                        currentTab === processTab.digitalSigning && (
                          <DigitalSigning dialogDefinition={dialogDefinition} onSaveDigitalSigning={handleSaveDigitalSigning} digitalSigningProviderName={customerIntegrations.digitalSigningProviderName} />
                        )
                      }

                    </div>
                  )
                }
              </ProcessTabs>
            </div>
          }

          {
            definitionProcess && dialogDefinition.process === processTypes.normal &&
            <div className='max-w-7xl grid grid-cols-12 gap-4 mt-5'>
              <div className='flex flex-col col-span-12 md:col-span-4 lg:col-span-3'>
                <Select
                  label="Contact list"
                  id={"contactListId"}
                  horizontal={false}
                  selectedValue={dialogDefinition.contactListId || ''}
                  options={contactListOptions()}
                  onChange={onContactListChange}
                />
              </div>
              <div className='flex flex-col col-span-12 md:col-span-4 lg:col-span-3'>
                <Select
                  label="Preferred language"
                  id={"defaultLanguage"}
                  horizontal={false}
                  selectedValue={dialogDefinition.defaultLanguage || ''}
                  options={templateLanguageActions.getAsSelectOptions()}
                  onChange={onDefaultLanguageChange}
                />
              </div>
            </div>
          }

          {
            dialogDefinition.process === processTypes.normal && (
              <NormalProcess dialogDefinition={dialogDefinition} digitalSigningProviderName={customerIntegrations.digitalSigningProviderName}
                onSaveDigitalSigning={handleSaveDigitalSigning}
              />
            )
          }
          {
            definitionProcess && dialogDefinition.process === processTypes.phoneNumber &&
            <div className="w-full border-t border-gray-200 my-5" >
              <div className='flex'>
                <div className="flex-1 px-5 my-5 w-80">
                  <PhoneNumbers
                    definitionProcessId={definitionProcess.id}
                  />
                </div>
              </div>
            </div>
          }
        </>
      }
    </div>
  )
}