import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, ClipboardCopyIcon } from "@heroicons/react/outline";
import CodeMirror from '@uiw/react-codemirror';
import { githubLight } from '@uiw/codemirror-theme-github';
import { json } from '@codemirror/lang-json';
import React from "react";
import { useToastAction } from "../../hooks/useToastAction";
// import { useToastAction } from "@metaforcelabs/metaforce-core";

export default function JsonCodeExample() {

    const code = [
        { value: "option1", display: "Option 1" },
        { value: "option2", display: "Option 2" },
        { value: "option3", display: "Option 3" }
    ];

    return (
        <Disclosure defaultOpen={true}>
            {({ open }) => (
                <>
                    <Disclosure.Button className="mt-4 py-1 w-full cursor-pointer block rounded-lg text-left text-sm font-sm">
                        <div className="flex w-full">
                        <ChevronUpIcon
                                className={`${open ? 'rotate-180 transform mr-2' : ''
                                    } h-5 w-5 mr-2`}
                            />
                            <span>Example data</span>
                            
                        </div>

                    </Disclosure.Button>
                    <Disclosure.Panel className="text-sm text-gray-500">
                        <JsonCodeViewer code={code} />
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}

export const JsonCodeViewer = ({ code }) => {
    const copyaction = useToastAction();

    const handleCopyToClipboard = () => {

        copyaction.executeAsync(async () => {
            navigator.clipboard.writeText(JSON.stringify(code, null, 2))
        }, "Failed to copy to clipboard", "Example copied to clipboard")
    }

    return (
        <div className="relative">
            <CodeMirror
                theme={githubLight} value={JSON.stringify(code, null, 2)}
                extensions={[json({})]}
                readOnly={true}
                editable={false}

            />
            <div className="absolute top-0 right-0 p-1 bg-white text-gray-500 hover:text-brand-blue cursor-pointer border hover:border-brand-blue-light border-gray-400 shadow rounded"
                onClick={() => handleCopyToClipboard()}>
                <ClipboardCopyIcon className="h-5 w-5" />
            </div>
        </div>
    )
}