import { useState, useEffect } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';
import { alignmentOptions } from '../../utils/constants';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type);

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }
  const radiogroupName = dialogDefinition.containers.reduce((acc, container) => {
    const thisRow = container.rows.find(row => row.columns.find(col => col.elements.find(elem => elem.property == data?.property))); 
    if (thisRow) acc = thisRow.radioGroupName || thisRow.id;
    return acc;
  }, null);

  return (
    <div className="popover-container-items">
      <Property.Group title="Information">
        <Property.Label
          label="Type"
          value="Radio Button"
        />
        <Property.Text
          onChange={propertyToLabel.onChange}
          label={"Property"}
          name={"property"}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={"Label"}
          name={"label"}
          value={data?.label}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Value"}
          name={"value"}
          value={data?.value}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.Text
          label={"Radio button group"}
          disabled={true}
          name={"RadioButtonGroup"}
          value={radiogroupName}
        />

      </Property.Group>

      <Property.Group
        title='Connected elements'>

        <Property.ConnectedElements data={data} handleOnChange={handleOnChange} />

      </Property.Group>

      <Property.Group
        title='Text Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Color'}
          name={'text.color'}
          initColor={data?.text?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Size'}
          name={'text.size'}
          type={'number'}
          value={data?.text?.size || null}
        />

        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={"Alignment"}
          name={"text.alignment"}
          value={data?.text?.alignment}
          options={alignmentOptions}
        />

        <Property.FontStyleAndWeight 
          onChange={handleOnChange}
          name="text"
          property={data?.text}
        />

      </Property.Group>
    </div>
  )
}
