import { useEffect, useState } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useDefinitionManager } from '../../screens/Admin/DialogDesign/useDefinitionManager';
import { elementTypes } from '../../utils/constants';

export default function Properties({ initData, actions }) {
  const [data, setSetData] = useState({})

  useEffect(() => {
    setSetData(_.cloneDeep(initData))
  }, [initData])

  


  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    const clonedData = _.cloneDeep(data);
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(clonedData, name, value, type)
    setSetData(prev=> ({...clonedData}));
  }


  const definitionManager = useDefinitionManager();
  const existRadioGroup = definitionManager.elementsOfTypeFromRow(data, [elementTypes.radioButtonGroup])

  return (
    <>
      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="Row"
        />
      </Property.Group>
      <Property.Group
        title='Style'>
        <Property.Color
          onChange={handleOnChange}
          label={'Background color'}
          name={'backgroundColor'}
          initColor={data.backgroundColor}
          enableTransparent
        />

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data.borderWidth}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Rounded corners'}
          name={'roundedCorners'}
          value={data.roundedCorners}
        />

      </Property.Group>

      <Property.Group
        title='Placement'>

        <Property.MarginPadding
          onChange={handleOnChange}
          label={'Margin'}
          name={'margin'}
          value={data.margin}
          type={'margin'}
        />

        <Property.MarginPadding
          onChange={handleOnChange}
          label={'Padding'}
          name={'padding'}
          value={data.padding}
          type={'padding'}
        />

        <Property.Select
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Gap'}
          name={'gap'}
          value={data.gap}
          options={[
            { name: "None", value: 0 },
            { name: "Small", value: 2 },
            { name: "Medium", value: 3 },
            { name: "Large", value: 5 },
            { name: "Extra Large", value: 8 },
          ]}
        />
      </Property.Group>

      {existRadioGroup.length ?
        <Property.Group>
          <Property.Text
            removePaddingTop
            onChange={handleOnChange}
            label={'Radio group name'}
            name={'radioGroupName'}
            value={data?.radioGroupName}
          />
          <Property.Checkbox
            onChange={handleOnChange}
            label={'Required radio group'}
            name={'requiredRadioGroup'}
            value={data?.requiredRadioGroup}
          />
        </Property.Group>
        : null}

      <Property.Checkbox
        onChange={handleOnChange}
        rowReverse={true}
        label={'Enable Row Repeat'}
        name={'dynamicRowConfig.enableRowRepeat'}
        value={data.dynamicRowConfig?.enableRowRepeat}
      />

      <div className='mb-5'>
        {
          data.dynamicRowConfig?.enableRowRepeat &&
          <div className='ml-10'>
            <Property.Checkbox
              onChange={handleOnChange}
              rowReverse={true}
              disabled={!data.dynamicRowConfig?.enableRowRepeat}
              label={'Adding a row require all fields in row to be filled in'}
              name={'dynamicRowConfig.newRowRequiresAllFieldsToBeFilled'}
              value={data.dynamicRowConfig?.newRowRequiresAllFieldsToBeFilled}
            />
          </div>
        }
      </div>

    </>
  )
}
