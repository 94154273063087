import { Badge } from '@metaforcelabs/metaforce-core'
import React, { useEffect, useState } from 'react'
import { languageListStatus } from '../../../../utils/constants'


export default function LanguageStatusBadge({status}) {
    
    const [badgeType, setBadgeType] = useState(null);
    const [badgeText, setBadgeText] = useState(null);

    useEffect(() => {
        switch (status) {
            case languageListStatus.activeDefault:
                setBadgeType("info");
                setBadgeText("Default");
                break;
            case languageListStatus.active:
                setBadgeType("success");
                setBadgeText("Active");
                break;
            default:
                setBadgeType("warn");
                setBadgeText("Disabled");
                break;
        }
    }, [status]);

    return (
    <>
        <Badge type={badgeType} text={badgeText} />
    </>
  )
}