import React, { useContext } from "react";
import Modal from "../../../components/Modal";
import { Form, Formik } from "formik";
import { FormikSelectInput } from "../../../components/Form/Formik/FormikSelect";
import { smartFormEnvironmentNames, smartFormEnvironments } from "../../../utils/constants";
import { Button } from "../../../components/Form/Button";
import { FormikSubmitButton } from "../../../components/Form/Formik/FormikSubmit";
import { featureFlags } from "../../../utils/features";
import { countryList } from "../../../utils/countryList";
import { lang } from "moment";
import { AdminlayoutContext } from "../../../contexts";
import { useTemplateLanguageActions } from "../../../hooks/useTemplateLanguageActions";

export default function CreateDialogModal({ dialogDefinitionId, modalHelper, onSubmit, defaultLanguageCode }) {
    const environmentOptions = Object.keys(smartFormEnvironmentNames).map(k => ({ name: smartFormEnvironmentNames[k], value: k }))
    const adminLayoutContext = useContext(AdminlayoutContext);
    const templateLanguageActions = useTemplateLanguageActions(adminLayoutContext.templateLanguages);
    const cancel = (evt) => {
        evt.preventDefault();
        modalHelper.close();
    }

    const handleSubmit = (values) => {
        onSubmit(values);
    }

    return (
        <Modal
            isOpen={modalHelper.isOpen}
            onClose={modalHelper.close}
            size="large"
            title={"Create dialog"}
        >
            <div>
                <Formik
                    initialValues={{
                        environment: smartFormEnvironments.development,
                        languageCode: defaultLanguageCode,
                        dialogDefinitionId
                    }}

                    onSubmit={async (values, actions) => {
                        handleSubmit(values);
                    }}
                >
                    {
                        props => (
                            <Form>
                                <div>
                                    <div className="max-w-sm space-y-4">
                                        {
                                            featureFlags.smartformEnvironment && (
                                                <FormikSelectInput
                                                    name={'environment'}
                                                    label={'Environment'}
                                                    formikProps={props}
                                                    options={environmentOptions}
                                                />
                                            )
                                        }
                                        <FormikSelectInput
                                            name={'languageCode'}
                                            label={'Language'}
                                            formikProps={props}
                                            options={templateLanguageActions.getAsSelectOptions()}
                                        />
                                    </div>
                                    <div className="mt-10 flex justify-end gap-2">
                                        <Button
                                            onClick={cancel}
                                            text="Close"
                                            theme="white"
                                        />
                                        <FormikSubmitButton
                                            formikProps={props}
                                            text={"Create"}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </Modal>
    )
}