import { Select } from "../../../components/Form/Select";
import { TextInput } from "../../../components/Form/TextInput";
import { Checkbox } from "../../../components/Form/Checkbox";
import { securityType, securityTypeOptions } from "../../../utils/constants";
import _ from "lodash";
import React from "react";
import { VerticalStepper } from "../../../components/VerticalStepper";
import { Button } from "../../../components/Properties";
import Enumerable from "linq";

export const ProcessSettings = ({
  step,
  containers,
  onPropertyChange,
  onUpdateListProperty,
  participatingParties,
  numberOfSteps,
  alreadySelectedContainerIds,
  contactLists,
  onStepClick,
  currentStepId,
  onAddStepClick,
  onDeleteStepClick,
  customerIntegrations,
  steps
}) => {
  const orderOptions = []
  for (let i = 0; i < numberOfSteps; i++) {
    orderOptions.push(i);
  }

  const contactListOptions = () => {
    const options = [{ value: '', name: 'Standard' }]
    contactLists?.map(c => options.push({ value: c.id, name: `Contactlist: ${c.name}` }))

    return options
  }

  const onCheckListChange = (isChecked, name, value) => {
    const addToList = isChecked === true ? true : false
    onUpdateListProperty(name, addToList, value)

    if (name === 'securityOptions' && !isChecked && value === securityType.phone) {
      onPropertyChange('', 'contactListId')
    }
  }

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="px-4 py-5 sm:py-6 flex space-x-2">
        <div className='-mx-2 flex flex-col flex-2' style={{ minWidth: "20rem" }}>
          <>
            <VerticalStepper
              onClick={onStepClick}
              steps={Enumerable.from(steps).orderBy(s => s.order).toArray()}
              participatingParties={participatingParties}
              currentId={currentStepId}
            />
            <div className='flex justify-evenly mt-4'>
              <Button
                label={"Add step"}
                onClick={onAddStepClick}
              />
              <Button
                label={"Remove selected step"}
                onClick={onDeleteStepClick}
                disabled={!currentStepId}
              />
            </div>
          </>
        </div>
        <div className="pl-6 border-l border-l-gray-200 flex flex-col w-full">
          <div className="my-2 ">
            <h3 className="text-lg leading-6 font-medium text-gray-900">General</h3>
            <label className="mt-2 flex flex-col text-xs font-medium text-gray-500">
              {step.name}
            </label>
          </div>
          <div className="flex items-center w-10/12">
            <label className="text-sm font-medium text-gray-700 mr-24" htmlFor="name">Step name</label>
            <TextInput
              name="name"
              value={step.name}
              onChange={onPropertyChange}
              horizontal={true}
              maxLength={20}
            />
          </div>
          <div className="w-full border-t border-gray-200 my-5" />
          <div className="flex flex-row items-center">
            <label className="text-sm font-medium text-gray-700 mr-16" htmlFor="participatingPartyId">Party to engage</label>
            <Select
              id="participatingPartyId"
              selectedValue={step.participatingPartyId || ''}
              options={participatingParties?.map(p => ({ value: p.id, name: _.truncate(p.name, { length: 90 }) }))}
              onChange={onPropertyChange}
            />
            <label className="text-sm font-medium text-gray-700 ml-16 mr-5" htmlFor="order">Step in process</label>
            <Select
              id="order"
              horizontal
              selectedValue={step.order}
              options={orderOptions.map(o => ({ value: o, name: o + 1 }))}
              onChange={onPropertyChange}
            />
          </div>
          <div className="w-full border-t border-gray-200 my-5" />
          <div className="my-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Authentication</h3>
            <label className="mt-2 flex flex-col text-xs font-medium text-gray-500">
              Choose the possible authentication types for this step
            </label>
          </div>

          <div className="flex justify-start">
            <label className="text-sm font-medium text-gray-700 mt-3 w-36">Method</label>
            {
              securityTypeOptions.filter(s => s.value !== securityType.none).map((s, index) => {
                return (
                  <div
                    key={`securityOption-${index}`}
                    className="px-6 py-3"
                  >
                    <Checkbox
                      checked={step.securityOptions?.includes(s.value)}
                      label={s.name}
                      name={"securityOptions"}
                      id={s.value}
                      onChange={(value, name) => onCheckListChange(value, name, s.value)}
                      disabled={((s.value === securityType.bankId) && !customerIntegrations.bankIdAuthenticationProviderId) || ((s.value === securityType.phone) && (customerIntegrations.smsProviderIds.length < 1))}
                      title={((s.value === securityType.bankId) && !customerIntegrations.bankIdAuthenticationProviderId) || ((s.value === securityType.phone) && (customerIntegrations.smsProviderIds.length < 1)) ? s.name + ' is not set up in Centerpoint' : ''}
                      labelPositionRight
                    />
                  </div>
                );

              })
            }
          </div>

          <div className="w-full border-t border-gray-200 my-5" />
          <div className="my-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Section Access</h3>
            <label className="mt-2 flex flex-col text-xs font-medium text-gray-500">
              Choose the sections in the Smartform you would like this Process Step to have access to
            </label>
          </div>
          <div className="flex flex-col justify-center items-start ml-40">
            {
              containers.map((container, index) => {
                return (
                  <div
                    key={`container-${index}`}
                    className="py-3 border-t border-gray-200 w-6/12"
                  >
                    <Checkbox
                      label={container.name || `Section ${index}`}
                      // disabled={alreadySelectedContainerIds.includes(container.id)}
                      name={'containerDefinitionIds'}
                      checked={step.containerDefinitionIds?.includes(container.id)}
                      onChange={(value, name) => onCheckListChange(value, name, container.id)}
                      labelClassName={"flex-1"}
                      labelPositionRight
                    />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}