import React, { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import * as _ from 'lodash';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import './styles.scss';
import { config } from '../../utils/config';
import { setAuthHeader } from '../../api/api'
import { getCustomerDetails } from '../../api/customer';
import { MenuBar } from '@metaforcelabs/metaforce-core';

const NavigationMenuReactLinkElement = (
    name,
    route,
    currentCheckValue,
    featureEnabledCheckResult = true,
    children
  ) => {
    return {
      linkElement: <Link to={route}>{name}</Link>,
      name: name,
      route: route,
      current: currentCheckValue,
      featureEnabled: featureEnabledCheckResult,
      children
    };
  };

const Oidc = () => {
  const navigate = useNavigate();
  const { oidcUser } = useReactOidc();

  useEffect(() => {
    if (oidcUser) {
      setAuthHeader(oidcUser.access_token)
      validateLicense()
    }
  }, [oidcUser])
  
  const navigationMenu = [
    NavigationMenuReactLinkElement('Home', '/'),
    NavigationMenuReactLinkElement('Smartforms', '/admin/smartforms')
  ];

  const validateLicense = async () => {
    const customerDetails = await getCustomerDetails();
    const hasLicense = customerDetails?.activeProducts?.find(a => a.id === config.productLicense);
    if (hasLicense) {
      navigate('/'); // redirects to root to prevent site from hanging on a blank screen
    }
    else {
      window.location.href = '/missing-license';
    }
  }

  const helperMenu = [
    {
        name: 'Documentation',
        onClick: () => window.location.href = config.docsUrl
    },
    {
        name: 'Education videos',
        onClick: () => window.location.replace("/admin/videos")
    }
  ];

  return (
    <div className="relative min-h-screen flex flex-col bg-white">
        <MenuBar
            navigation={navigationMenu}
            homeButtonLink={config.centerpointUiBaseUrl}
            helpLink={helperMenu}
        />

        <main className="main-container bg-gray-50 flex-1 z-0 focus:outline-none">
            <div className="max-w-screen-2xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col pb-10">
                <div className="max-w-screen-3xl">
                <p className="text-2xl font-bold mt-6">Loading...</p>
                </div>
            </div>
            </div>
        </main>
    </div>
  )
}

export default Oidc;