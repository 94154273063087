import { v4 as uuidv4 } from "uuid";

export const MapLocations = () => {
    return {
        id: uuidv4(),
        dialogObjectId: null,
        lockToDialogObject: false,
        space: null,
        backgroundColor: "",
        margin: "20px 0",
        padding: null,
        borderWidth: null,
        borderColor: null,
        roundedCorners: false,
        static: false,
        name: "Section-1",
        newPage: false,
        backgroundImage: null,
        backgroundSize: 0,
        backgroundRepeat: false,
        avoidBreakInside: false,
        rows: [
            {
                id: uuidv4(),
                columns: [
                    {
                        id: uuidv4(),
                        borderWidth: "0",
                        elements: [
                            {
                                id: uuidv4(),
                                type: 0,
                                inputType: 0,
                                validateData: 0,
                                country: 0,
                                tableValueType: 0,
                                dynamicListValues: null,
                                video: null,
                                richTextValues: null,
                                bitmap: null,
                                layout: { order: 2, right: null },
                                text: {
                                    level: null,
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 18,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: "#000",
                                    width: 0
                                },
                                labelStyle: null,
                                dependencies: null,
                                orderTable: null,
                                customErrorMessage: null,
                                smartTable: null,
                                smartTableHeaders: [],
                                displayLabel: null,
                                valueLabel: null,
                                property: "HeadlinePerson",
                                value: "Lorem ipsum dolor sit amet",
                                placeholder: null,
                                borderWidth: null,
                                borderColor: null,
                                width: null,
                                height: null,
                                label: null,
                                labelSetManually: false,
                                helpText: null,
                                leadingText: null,
                                trailingText: null,
                                labelTop: false,
                                backgroundColor: null,
                                roundedCorners: false,
                                defaultCheckedState: null,
                                isButton: null,
                                margin: null,
                                processStep: 0,
                                signatureProcess: null,
                                leadingIcon: null,
                                minValue: null,
                                maxValue: null,
                                minLength: null,
                                maxLength: null,
                                rows: null,
                                apiValidationId: null,
                                apiRetrieveId: null,
                                workflowRetrieveId: null,
                                options: null,
                                optionWithValueAlphabeticalOrder: false,
                                optionWithValues: null,
                                flowOptions: null,
                                preventDataInsert: false,
                                maxFileSizeInMb: null,
                                readOnly: false,
                                maxRows: null,
                                connectedParentElementId: null,
                                connectedElements: null,
                                initialValue: null,
                                lockRows: false,
                                requiredField: null,
                                requiredSignatures: [],
                                hideLabel: false,
                                orderTableValue: null,
                                hasAutomaticTranslationOff: false,
                                connectedElement: null
                            }
                        ],
                        contentAlign: null,
                        verticalAlign: null,
                        backgroundColor: null,
                        space: 3,
                        span: null,
                        margin: "0",
                        padding: null,
                        borderColor: null,
                        roundedCorners: false
                    }
                ],
                space: 5,
                gap: null,
                backgroundColor: "#f5f5f5",
                margin: "5px 0",
                padding: "10px",
                borderWidth: "0",
                borderColor: null,
                roundedCorners: true
            },
            {
                id: uuidv4(),
                margin: "5px 0",
                padding: "0",
                borderWidth: "0",
                roundedCorners: true,
                space: 5,
                columns: [
                    {
                        id: uuidv4(),
                        space: 0,
                        borderWidth: "0",
                        roundedCorners: false,
                        margin: "0",
                        elements: [
                            {
                                id: uuidv4(),
                                type: 0,
                                property: "test-4",
                                value: "Sed ultrices lacus id justo facilisis, nec tristique nibh posuere. Etiam gravida ante et quam lobortis, at posuere orci laoreet. Quisque nec magna nec arcu vehicula vulputate. Nulla at purus id urna fermentum varius non a est. Donec vel neque justo. Mauris scelerisque erat ac diam malesuada, id venenatis sem varius. Integer dapibus sapien vel tincidunt consectetur. Praesent tincidunt, orci at dictum egestas, orci arcu malesuada risus, ac pharetra felis felis eu magna. \n\nTips : Make sure map is connected to an API through API Retrieve in order to define map locations. See also the documentation",
                                layout: { order: 2 },
                                text: {
                                    alignment: "left",
                                    fontFamily: "sans-serif",
                                    size: 16,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#000"
                                }
                            }
                        ]
                    }
                ]
            },
            {
                id: uuidv4(),
                margin: "5px 0",
                padding: "0",
                borderWidth: "0",
                roundedCorners: false,
                space: 5,
                columns: [
                    {
                        id: uuidv4(),
                        space: 0,
                        borderWidth: "0",
                        margin: "0",
                        roundedCorners: false,
                        elements: [
                            {
                                id: uuidv4(),
                                type: 33,
                                backgroundColor: "#000",
                                property: "mapSelectLocation",
                                value: "Map select location",
                                borderWidth: "1px",
                                layout: { order: 2 },
                                text: {
                                    level: "1",
                                    alignment: "left",
                                    fontFamily: "arial",
                                    size: 16,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: "#fff"
                                }
                            },
                            {
                                id: uuidv4(),
                                type: 8,
                                borderWidth: "1px",
                                borderColor: "#8d8d8d",
                                width: 0,
                                property: "divider-6363"
                            }
                        ]
                    }
                ]
            },
            {
                id: uuidv4(),
                margin: "5px 0",
                padding: "0",
                borderWidth: "0",
                roundedCorners: true,
                space: 5,
                columns: [
                    {
                        id: uuidv4(),
                        space: 0,
                        borderWidth: "0",
                        roundedCorners: false,
                        margin: "0",
                        elements: [
                            {
                                id: uuidv4(),
                                property: "spacer-7f2f",
                                type: 28,
                                width: 10,
                                height: 40
                            }
                        ]
                    }
                ]
            }
        ]
    };
};
