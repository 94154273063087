import { SearchIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllInMultiStepInProgress } from '../../../api/dialogValues';
import { Badge } from '../../../components/Badge';
import { Table, TableBody, TableHead, TableHeader, TableRow, TableColumn } from '../../../components/Table';
import { useTableSort } from '../../../hooks/useTableSort';
import { useToastAction } from '../../../hooks/useToastAction';
import { stringToLocaleDateTimeString } from '../../../utils/date';

export default function MultiStepForms() {
  const [allTableObjects, setAllTableObjects] = useState([])
  const loadDialogValuesAction = useToastAction()
  const [tableObjects, setTableObjects] = useState([])
  const tableSort = useTableSort(tableObjects)

  useEffect(() => {
    load();
  }, [])

  const handleSearch = (e) => {
    const searchBy = e.target.value

    if (searchBy && searchBy !== '') {
      setTableObjects([...allTableObjects.filter(m =>
        m.smartForm?.toLowerCase().includes(searchBy.toLowerCase()) ||
        m.processStepName?.toLowerCase().includes(searchBy.toLowerCase())
      )])
    }
    else {
      setTableObjects([...allTableObjects])
    }
  }

  const load = async () => {
    loadDialogValuesAction.execute(async () => {
      let records = await getAllInMultiStepInProgress();
      setAllTableObjects(records);
      setTableObjects(records);
    }, "Failed to load")
  }
  return (
    <>
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Smartforms
        </h3>
        <div className="mt-3 sm:mt-0 sm:ml-4 w-96">
          <label htmlFor="desktop-search-candidate" className="sr-only">
            Search
          </label>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                name="desktop-search-candidate"
                id="desktop-search-candidate"
                className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                placeholder="Search"
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>
      <Table className="mt-5">
        <TableHead>
          <TableHeader additionalClass="w-36" sortBy={"processStepName"} tableSort={tableSort}>
            Participating party
          </TableHeader>
          <TableHeader sortBy={"smartForm"} tableSort={tableSort}>
            Smartform
          </TableHeader>
          <TableHeader sortBy={"email"} tableSort={tableSort}>
            Email
          </TableHeader>
          <TableHeader sortBy={"createdDate"} tableSort={tableSort}>
            Created
          </TableHeader>
          <TableHeader sortBy={"updatedDate"} tableSort={tableSort}>
            Updated
          </TableHeader>
        </TableHead>
        <TableBody>
          {
            tableSort?.tableData?.map((record, i) => {
              return (
                <TableRow key={`tableItem-${i}`}>
                  <TableColumn >
                    <Badge type="default" text={record.participatingParty} />
                  </TableColumn>
                  <TableColumn>
                    <Link
                      to={`/dialog/${record.dialogDefinitionId}/form/${record.dialogValuesId}/steps/${record.currentStepId}`}
                      target="_blank"
                      className={'text-black underline hover:text-gray-700 overflow-hidden left-0 right-0 whitespace-nowrap overflow-ellipsis'}
                    >
                      {record.smartForm}
                    </Link>
                  </TableColumn>
                  <TableColumn>
                    {record.email}
                  </TableColumn>
                  <TableColumn>
                    {stringToLocaleDateTimeString(record.createdDate)}
                  </TableColumn>
                  <TableColumn>
                    {stringToLocaleDateTimeString(record.updatedDate)}
                  </TableColumn>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </>
  )
}
