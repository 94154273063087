import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getNotificationsByDialogDefinitionId, updateDialogNotifications } from '../../../api/dialogNotifications';
import { getActiveEmailNotificationTemplates, getActiveSmsNotificationTemplates } from '../../../api/notificationTemplates';
import { DialogNotificationContext } from '../../../contexts';
import { useToastAction } from '../../../hooks/useToastAction';
import { notificationEventType } from '../../../utils/constants';

export default function DialogNotification() {
  let { dialogKey } = useParams();
  const loadAction = useToastAction();
  const saveAction = useToastAction();
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [smsTemplates, setSmsTemplates] = useState([])
  const [dialogNotifications, setDialogNotifications] = useState()
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    loadAction.execute(async () => {
      const [emailTemp, smsTemp, notifications] = await Promise.all([getActiveEmailNotificationTemplates(), getActiveSmsNotificationTemplates(), getNotificationsByDialogDefinitionId(dialogKey)]);
      setEmailTemplates(emailTemp);
      setSmsTemplates(smsTemp);
      setDialogNotifications(notifications);
      setLoaded(true);
    }, "Failed to load notifications");
  }

  const saveNotifications = (updatedObj) => {
    saveAction.execute(async () => {
      const { updatedDate } = await updateDialogNotifications(dialogKey, updatedObj);
      setDialogNotifications(prev => {
        return { ...prev, updatedDate };
      })
    }, "Failed to save")
  }

  return (

    <DialogNotificationContext.Provider
      value={{
        emailTemplates,
        smsTemplates,
        emailNotifications: dialogNotifications?.emailNotifications,
        smsNotifications: dialogNotifications?.smsNotifications,
        onUpdateEmailNotification: (type, templateId) => {
          const idx = dialogNotifications.emailNotifications.findIndex(x => x.notificationEventType === type);
          const newItem = { notificationTemplateId: templateId, notificationEventType: type };
          if (idx === -1) {
            dialogNotifications.emailNotifications = [...dialogNotifications.emailNotifications, newItem];
          } else {
            dialogNotifications.emailNotifications.splice(idx, 1, newItem)
          }
          setDialogNotifications(dialogNotifications);
          saveNotifications(dialogNotifications);

        },
        onUpdateSmsNotification: (type, templateId) => {
          const idx = dialogNotifications.smsNotifications.findIndex(x => x.notificationEventType === type);
          const newItem = { notificationTemplateId: templateId, notificationEventType: type };
          if (idx === -1) {
            dialogNotifications.smsNotifications = [...dialogNotifications.smsNotifications, newItem];
          } else {
            dialogNotifications.smsNotifications.splice(idx, 1, newItem)
          }
          setDialogNotifications(dialogNotifications);
          saveNotifications(dialogNotifications);
        }
      }}
    >
      {
        !loadAction.isExecuting && loaded && (
          <div className='pb-10'>
            <NotificationTypeSection
              name={"New Smartform"}
              typeDescription={"Template used when notifying a person to access a Smartform for the first time. Templates can be changed in the templates menu"}
              sectionType={notificationEventType.notification}
            />
            <NotificationTypeSection
              name={"Process steps"}
              typeDescription={"Template used to notify process step progression. Templates can be changed in the templates menu"}
              sectionType={notificationEventType.processStep}
            />
            <NotificationTypeSection name={"On Completion with attached PDF copy"}
              typeDescription={"Template used when the process is complete to notify with attached PDF copy.Templates can be changed in the templates menu"}
              sectionType={notificationEventType.completedWithPdf}
            />
            <NotificationTypeSection name={"Security Code"}
              typeDescription={"Template used for issuing a security code to access the Smartform. Templates can be changed in the templates menu"}
              sectionType={notificationEventType.securityCode}
            />
            <NotificationTypeSection name={"Reminder"}
              typeDescription={"Template used for sending reminders to participating parties to complete their Smartforms"}
              sectionType={notificationEventType.reminder}
            />
          </div>)
      }
    </DialogNotificationContext.Provider>
  )
}

const NotificationTypeSection = ({ name, sectionType, typeDescription }) => {

  const { emailTemplates, smsTemplates, emailNotifications, smsNotifications, onUpdateEmailNotification, onUpdateSmsNotification } = useContext(DialogNotificationContext)


  const getEmailTemplatesForType = () => {
    return emailTemplates.filter(x => x.notificationEventType === sectionType);
  }

  const getSmsTemplatesForType = () => {
    return smsTemplates.filter(x => x.notificationEventType === sectionType);
  }

  const getSelectedOrDefaultEmail = () => {
    const defaultTemplate = getEmailTemplatesForType().find(x => x.isDefaultForType);
    const selectedId = (emailNotifications?.find(x => x.notificationEventType === sectionType)?.notificationTemplateId || defaultTemplate?.id);
    const verifiedId = getEmailTemplatesForType().find(x => x.id === selectedId)?.id || defaultTemplate?.id;
    return verifiedId;
  }

  const getSelectedOrDefaultSms = () => {
    const defaultTemplate = getSmsTemplatesForType().find(x => x.isDefaultForType);
    const selectedId = (smsNotifications?.find(x => x.notificationEventType === sectionType)?.notificationTemplateId || defaultTemplate?.id);
    const verifiedId = getSmsTemplatesForType().find(x => x.id === selectedId)?.id || defaultTemplate?.id;
    return verifiedId;
  }


  const handleEmailTempalteChange = (templateId) => {
    onUpdateEmailNotification(sectionType, templateId);
  }

  const handleSmsTempalteChange = (templateId) => {
    onUpdateSmsNotification(sectionType, templateId)
  }

  return (
    <section aria-labelledby="webhooks-heading">
      <div className="mt-10 bg-white pt-6 shadow sm:rounded-md">
        <div className="flex justify-between items-center px-4 sm:px-6">
          <h2
            id="webhooks-heading"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            {name}
          </h2>
        </div>
        <div className='px-4 sm:px-6 py-4'>
          <div className="grid grid-cols-7 sm:space-x-4 md:space-x-8">
            <div className='col-span-7 sm:col-span-4 md:col-span-3'>
              <div className='grid grid-cols-12 space-y-2'>
                <label className="col-span-12 sm:col-span-4 block sm:inline-flex sm:items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Email
                </label>
                <div className="col-span-12 sm:col-span-8">
                  <select className='shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400'
                    value={getSelectedOrDefaultEmail()}
                    onChange={({ target }) => { handleEmailTempalteChange(target.value) }}
                  >
                    {
                      getEmailTemplatesForType()?.map(o => <option key={o?.id} value={o?.id}>{o.name}</option>)
                    }
                  </select>
                </div>
                {
                  sectionType !== notificationEventType.completedWithPdf && (
                    <>
                      <label className="col-span-12 sm:col-span-4 block sm:inline-flex sm:items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        SMS
                      </label>
                      <div className="col-span-12 sm:col-span-8">
                        <select className='shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400'
                          value={getSelectedOrDefaultSms()}
                          onChange={({ target }) => { handleSmsTempalteChange(target.value) }}
                        >
                          {
                            getSmsTemplatesForType().map(o => <option key={o.id} value={o.id}>{o.name}</option>)
                          }
                        </select>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
            <div className='col-span-7 sm:col-span-3 md:col-span-4 text-sm text-gray-400 mt-4 sm:mt-0'>
              {typeDescription}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
