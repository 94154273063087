import { useContext, useMemo, useState } from 'react'
import { DesignActionbarContext } from '../../../../contexts';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useModalHelper } from '../../../../hooks/useModalHelper';
import { useToastAction } from '../../../../hooks/useToastAction';
import { SendDialog } from '../sendDialog';
import { isActive, isMultiStep } from '../../../../utils/dialogDefinitions';
import { SidebarButton } from '../../../../components/SidebarButton';
import { createDialogValues, generatePdfPreview } from '../../../../api/dialogValues';
import { faUpload, faCodeCommit, faPaperPlane, faFile, faFileMagnifyingGlass, faPresentationScreen, faTableLayout, faMobile, faDesktop, faFileExport } from '@fortawesome/pro-light-svg-icons'
import GeneratePdfDialog from '../../../../components/GeneratePdfDialog';
import { useNewIdGenerator } from '../useNewIdGenerator';
import { getDialogDefinitionForExport } from '../../../../api/dialogDefinition';
import CreateDialogModal from '../createDialogModal';
import { featureFlags } from '../../../../utils/features';
import { smartFormEnvironments } from '../../../../utils/constants';

export default function Actions() {
  const designActionbarContext = useContext(DesignActionbarContext);
  const {
    onPublish,
    onSetIsMobileView,
    undo,
    disablePublish,
    disableActive,
    isDialogTemplate,
    isObjectTemplate
  } = designActionbarContext

  let { dialogKey } = useParams();
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const sendDialogModalHelper = useModalHelper();
  const createDialogModalHelper = useModalHelper();
  const idGenerator = useNewIdGenerator();

  const [gridMode, setGridMode] = useState(false)
  const createDialogValuesAction = useToastAction(true)
  const [viewModeIsDesktop, setViewModeIsDesktop] = useState(true)
  const [showGeneratePdf, setShowGeneratePdf] = useState(false);

  const isDialogActive = useMemo(() => isActive(dialogDefinition), [dialogDefinition]);
  const isDialogMultiStep = isMultiStep(dialogDefinition);
  const isTemplate = isObjectTemplate || isDialogTemplate

  const handleSetViewMode = (isDesktop) => {
    onSetIsMobileView(!isDesktop)
    setViewModeIsDesktop(isDesktop)
  }

  const handleShowGrid = () => {
    setGridMode(!gridMode)
    designActionbarContext.onShowGridClick()
  }

  const createNewDialogValues = async (dialogDefinitionId) => {
    createDialogModalHelper.open();
    // if (featureFlags.smartformEnvironment) {
      
    // } else {
    //   handleCreateDialogValues({ dialogDefinitionId, environment: smartFormEnvironments.development })
    // }

  }

  const handleCreateDialogValues = (values) => {
    createDialogValuesAction.execute(async () => {
      const dialogValues = await createDialogValues(values.dialogDefinitionId, +values.environment);
      createDialogModalHelper.close();
      let url = `/dialog/${values.dialogDefinitionId}/form/${dialogValues.id}`;

      //TODO: remove || true and review logic this when we have a proper way to handle language
      if(values.languageCode != dialogDefinition.defaultLanguage || true) {
        url += `?languageCode=${values.languageCode}`;        
      }
      window.open(url, "_blank");
    }, "Failed to create preview");
  }

  const onClickPreviewButton = async () => {
    setShowGeneratePdf(true);
    const pdfName = dialogDefinition?.name.replace(/\./g, "_");
    await generatePdfPreview(dialogKey, pdfName)
    setShowGeneratePdf(false);
  }

  const onHandleExport = async (dialogDefinitionId) => {
    let dialogDefinitionToExport = await getDialogDefinitionForExport(dialogDefinitionId)
    dialogDefinitionToExport.dialogValueIds = [];

    if (dialogDefinitionToExport) {
      delete dialogDefinitionToExport.id
      delete dialogDefinitionToExport.dialogValueIds
      delete dialogDefinitionToExport.createdDate
      delete dialogDefinitionToExport.editState
      delete dialogDefinitionToExport.metaData
      delete dialogDefinitionToExport.status
      delete dialogDefinitionToExport.updatedDate

      idGenerator.newIdsOnDialog(dialogDefinitionToExport);
      const element = document.createElement("a");
      const file = new Blob([JSON.stringify(dialogDefinitionToExport)], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = `${dialogDefinitionToExport.name}.json`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  }

  return (
    <>

      <div className={`mb-8 border-b border-gray-200 pb-7`}>
        <div className='h-full px-4 sm:px-6 '>
          <h3 className="font-medium text-gray-900">General</h3>
          <div className={`pt-3 grid grid-cols-2 gap-2`}>
            <SidebarButton
              disabled={disablePublish}
              text='Publish'
              onClick={onPublish}
              icon={faUpload}
              readOnly={designActionbarContext.publishExecuting}
            />
            <SidebarButton
              disabled={disableActive}
              text={isDialogActive ? "Deactivate" : "Activate"}
              onClick={designActionbarContext[isDialogActive ? "onDeactivate" : "onActivate"]}
              readOnly={designActionbarContext[isDialogActive ? "deactivateExecuting" : "activateExecuting"]}
              icon={faCodeCommit}
            />

            {!isTemplate && <>
              <SidebarButton disabled={!isDialogActive} text='Send Form' onClick={sendDialogModalHelper.open} icon={faPaperPlane} />
              <SidebarButton disabled={isDialogMultiStep} text='Create Form' onClick={() => createNewDialogValues(dialogKey)} icon={faFile} />
              <Link
                target="_blank"
                to={`/dialog/${dialogKey}/preview`}
              >
                <SidebarButton text='Preview Draft' icon={faPresentationScreen} />
              </Link>
            </>}
          </div>
        </div>
      </div>


      {
        !disablePublish &&
        <div className={`mb-8 border-b border-gray-200 pb-7`}>
          <div className='h-full px-4 sm:px-6 '>
            <h3 className="font-medium text-gray-900">File</h3>
            <div className={`pt-3 grid grid-cols-2 gap-2`}>

              <SidebarButton icon={faFileMagnifyingGlass} text='Preview PDF' onClick={onClickPreviewButton} />
              <SidebarButton icon={faFileExport} text='Export' onClick={() => onHandleExport(dialogDefinition?.id)} />
            </div>
          </div>
        </div>
      }



      <div className={`mb-8 border-b border-gray-200 pb-7`}>
        <div className='h-full px-4 sm:px-6 '>
          <h3 className="font-medium text-gray-900">View</h3>
          <div className={`pt-3 grid grid-cols-2 gap-2`}>
            <SidebarButton text='Toggle Grid' onClick={() => handleShowGrid()} icon={faTableLayout} />
            <SidebarButton text={viewModeIsDesktop ? "Mobile view" : " Desktop view / Edit mode"} onClick={() => handleSetViewMode(!viewModeIsDesktop)} icon={viewModeIsDesktop ? faMobile : faDesktop} />
          </div>
        </div>
      </div>

      {!isDialogTemplate && <SendDialog
        dialogDefinition={designActionbarContext.dialogDefinition}
        modalHelper={sendDialogModalHelper}
      />}
      <CreateDialogModal dialogDefinitionId={dialogKey} defaultLanguageCode={dialogDefinition.defaultLanguage}
        modalHelper={createDialogModalHelper} onSubmit={handleCreateDialogValues} />
      <GeneratePdfDialog open={showGeneratePdf} setOpen={setShowGeneratePdf} />
    </>
  )
}
