import React, { useContext } from 'react'
import { useEffect, useState } from "react";
import { useTableSort } from "@metaforcelabs/metaforce-core";
import { useToastAction } from "../../../../hooks/useToastAction";
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/Table";
import { FlagIcon, TrashIcon, BanIcon, CheckIcon } from '@heroicons/react/outline';
import { useModalHelper } from "@metaforcelabs/metaforce-core";
import { replaceItem } from "../../../../utils/array";
import MenuContextList from "../../../../components/MenuContextList";
import { getAllLanguages, deleteLanguage, changeLanguageStatus, addLanguage } from "../../../../api/templateLanguages";
import { languageListStatus } from "../../../../utils/constants";
import { clone, template } from 'lodash';
import LanguageStatusBadge from './languageStatusBadge';
import { CreateLanguageModal } from './createLanguageModal';
import { templateLanguages } from '../../../../utils/constants';
import { AdminlayoutContext } from '../../../../contexts';


export default function Languages() {
  const [languageList, setLanguageList] = useState([]);
  const tableSort = useTableSort(languageList, 1, "name", "desc")
  const loadAction = useToastAction(null, "");
  const deleteAction = useToastAction(true);
  const createAction = useToastAction(true);
  const patchAction = useToastAction(true);
  const createRecordModalHelper = useModalHelper()
  const adminLayoutContext = useContext(AdminlayoutContext)
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    load();
  }, [])

  useEffect(() => {
    if (isLoaded) {
      adminLayoutContext.setTemplateLanguages(languageList);
    }
  }, [languageList])

  const load = () => {
    loadAction.execute(async () => {
      const results = await getAllLanguages()
      setLanguageList(results)
      setIsLoaded(true);
    })
  }

  const handleLanguageAdd = async (values) => {
    await createAction.executeAsync(async () => {
      const addingLanguage = {
        name: values.standardName,
        nativeName: values.nativeName,
        languageCode: values.value,
        status: 2
      }
      const newLanguage = await addLanguage(addingLanguage)
      const cloneLanguages = clone(languageList);

      setLanguageList([newLanguage, ...cloneLanguages])
      createRecordModalHelper.close()
    }, "Failed to add language", "Language added");
  }

  const handleLanguageDelete = (values) => {
    deleteAction.execute(async () => {
      await deleteLanguage(values.languageCode);
      setLanguageList([...languageList.filter(c => c.languageCode !== values.languageCode)])
    }, "Failed to delete language", "Language deleted");
  }

  const handleLanguageUpdate = (values, status) => {
    patchAction.execute(async () => {
      const updatedRecords = await changeLanguageStatus(values, status)
      const cloneLanguages = clone(languageList);
      updatedRecords.forEach(item => {
        replaceItem(cloneLanguages, x => item.languageCode === x.languageCode, item)
      })
      setLanguageList([...cloneLanguages])
    }, "Failed to change language status", "Language status changed")
  }

  return (
    <>
      <div>
        <div className="mt-10 pb-5 border-b border-gray-200 flex justify-between items-center">
          <h1 className="text-3xl font-bold">Languages</h1>
          <div className='flex justify-end mt-5 pb-5'>
            <button
              type="button"
              className="ml-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
              onClick={createRecordModalHelper.open}
            >
              New Language
            </button>
          </div>
        </div>
      </div>
      <Table>
        <TableHead>
          <TableHeader tableSort={tableSort} sortBy={"name"}>
            Name
          </TableHeader>
          <TableHeader tableSort={tableSort} sortBy={"status"}>
            Status
          </TableHeader>
          <TableHeader />
        </TableHead>
        <TableBody>
          {
            tableSort?.tableData?.map(element => {
              const isActive = element.status === languageListStatus.active
              return (
                <TableRow key={`language-list-${element.code}`}>
                  <TableColumn>
                    <span className='font-bold'>{element.name}</span> <span className='italic'>({element.nativeName})</span>
                  </TableColumn>
                  <TableColumn>
                    <LanguageStatusBadge
                      status={element.status}
                    />
                  </TableColumn>
                  <td className="p-6 pb-4 whitespace-nowrap text-right text-sm font-medium">
                    {element.status !== languageListStatus.activeDefault &&
                      <MenuContextList
                        element={element}
                        deleteType={<span>({element.nativeName})</span>}
                        actions={[
                          isActive ? {
                            name: "Deactivate",
                            disabled: patchAction.isExecuting,
                            onClick: () => handleLanguageUpdate(element, languageListStatus.disabled),
                            icon: BanIcon,
                            lineAfter: true,
                          }
                            :
                            {
                              name: "Activate",
                              disabled: patchAction.isExecuting,
                              onClick: () => handleLanguageUpdate(element, languageListStatus.active),
                              icon: CheckIcon,
                              lineAfter: true,
                            },
                          isActive ?
                            {
                              name: "Set default",
                              disabled: patchAction.isExecuting,
                              onClick: () => handleLanguageUpdate(element, languageListStatus.activeDefault),
                              icon: FlagIcon,
                            }
                            :
                            {
                              name: "Delete",
                              onClick: () => handleLanguageDelete(element),
                              disabled: deleteAction.isExecuting,
                              icon: TrashIcon,
                            }
                        ]}
                      />
                    }
                  </td>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
      <CreateLanguageModal
        modalHelper={createRecordModalHelper}
        onAddLanguage={handleLanguageAdd}
        configuredLangs={languageList}
        templateLanguages={templateLanguages}
      />
    </>
  )
}
