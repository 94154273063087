import { get, post, destroy, put } from './api'


export const getAllLanguages = async () => {
  return await get(`templateLanguage`);
}

export const addLanguage = async (record) => {
  return await post(`templateLanguage`, record);
}

export const deleteLanguage = async (languageCode) => {
  return await destroy(`templateLanguage/${languageCode}`);
}

export const changeLanguageStatus = async (record, status) => {
  return await put(`templateLanguage`, { model: record, status: status })
}
