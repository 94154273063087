
import { Form, Formik } from "formik";
import React, { useEffect, useState } from 'react';
import { Button } from "../../../../components/Form/Button";
import Modal from "../../../../components/Modal";
import * as yup from "yup";
import { FormikSelectInput } from "../../../../components/Form/Formik/FormikSelect";
import { SubmitButton } from "../../../../components/Form/SubmitButton";

export const CreateLanguageModal = ({ onAddLanguage, modalHelper, configuredLangs, templateLanguages }) => {
  const [availableLangs, setAvailableLangs] = useState([]);


  const init = () => {
    const configuredCountryCodes = configuredLangs.map(x => x.languageCode);
    const additionalLanguages = templateLanguages.filter(item => !configuredCountryCodes.includes(item.value));
    setAvailableLangs(additionalLanguages);
  }

  useEffect(() => {
    if (modalHelper.isOpen) {
      init();
    }
  }, [modalHelper]);

  const handleSubmit = async (values) => {
    let lang = templateLanguages.find(x => x.value === values.languageCode);
    await onAddLanguage(lang)
  }

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="medium"
    >
      <Formik
        initialValues={{
          languageCode: availableLangs[0]?.value
        }}
        validationSchema={() => {
          return yup.object({
            languageCode: yup.string().required("Required"),
          });
        }}
        onSubmit={async (values, { setSubmitting, ...actions }) => {
          await handleSubmit(values)
          setSubmitting(false);
        }}
      >
        {(props) => (
          <Form>
            <div>
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-2">
                Language
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <FormikSelectInput
                  formikProps={props}
                  name="languageCode"
                  options={
                    availableLangs
                  }
                />
              </div>
            </div>
            <div className="mt-5 sm:mt-6 flex justify-end space-x-4">
              <Button
                onClick={modalHelper.close}
                text="Close"
                theme="white"
              />
              <SubmitButton
                text="Add"
              />

            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}