import { useContext, useEffect, useState } from "react";
import { Button } from "../../../components/Form/Button";
import MenuContextList from "../../../components/MenuContextList";
import Modal from "../../../components/Modal";
import { useToastAction } from "../../../hooks/useToastAction";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { FormikTextInput } from "../../../components/Form/Formik/FormikTextInput";
import { FormikSubmitButton } from "../../../components/Form/Formik/FormikSubmit";
import { createParticipatingParty, deleteParticipatingParty, getParticipatingParties, updateParticipatingParty } from "../../../api/participatingParties";
import { replaceItem } from "../../../utils/array";
import { useModalHelper } from "../../../hooks/useModalHelper";
import { FormikCheckbox } from "../../../components/Form/Formik/FormikCheckbox";
import { errorMessages } from "../../../utils/constants";
import { useEllipsis } from "../../../hooks/useEllipsis";
import { FormikSelectInput } from "../../../components/Form/Formik/FormikSelect";
// import { countryList } from "../../../utils/countryList";
import { AdminlayoutContext } from "../../../contexts";
import { useTemplateLanguageActions } from "../../../hooks/useTemplateLanguageActions";

export const ParticipatingParties = ({ dialogKey, onChange, participatingParties, setParticipatingParties }) => {
  const [selectedParticipatingParty, setSelectedParticipatingParty] = useState();
  const loadAction = useToastAction();
  const createAction = useToastAction(true);
  const updateAction = useToastAction(true);
  const deleteAction = useToastAction(true);
  const createModalHelper = useModalHelper()
  const editModalHelper = useModalHelper()
  const adminLayoutContext = useContext(AdminlayoutContext);
  const ellipsis = useEllipsis()

  useEffect(() => {
    load();
  }, [])

  useEffect(() => {
    onChange(participatingParties)
  }, [participatingParties])

  const load = () => {
    loadAction.execute(async () => {
      const results = await getParticipatingParties(dialogKey);
      setParticipatingParties(results);
    }, "Failed to load participating parties")
  }

  const onCreate = async (values) => {
    createAction.execute(async () => {
      const result = await createParticipatingParty(dialogKey, { name: values.name, defaultLanguage: values.defaultLanguage, emailOnCompletion: !!values.emailOnCompletion, digitalSigning: !!values.digitalSigning })
      setParticipatingParties([...participatingParties, result])
      createModalHelper.close();
    }, "Failed to create", "Created")
  }

  const onDelete = async (id) => {
    deleteAction.execute(async () => {
      await deleteParticipatingParty(dialogKey, id);
      setParticipatingParties(participatingParties.filter(p => p.id !== id))
    }, "Failed to delete", "Deleted")
  }

  const onUpdate = async (values) => {
    updateAction.execute(async () => {
      const result = await updateParticipatingParty(dialogKey, selectedParticipatingParty.id, { name: values.name, defaultLanguage: values.defaultLanguage, emailOnCompletion: !!values.emailOnCompletion, digitalSigning: !!values.digitalSigning })
      replaceItem(participatingParties, p => p.id === result.id, result)
      setParticipatingParties([...participatingParties])
      setSelectedParticipatingParty(null)
      editModalHelper.close();
    }, "Failed to update", "Updated")
  }

  const onEditClick = (selected) => {
    setSelectedParticipatingParty(selected)
    editModalHelper.open()
  }

  const getLanguageName = (code) => {
    return adminLayoutContext.templateLanguages.find(c => c.languageCode === code)?.name
  }

  return (
    <>
      <section aria-labelledby="languages-heading">
        <div className="mb-20 bg-white pt-6 shadow sm:rounded-md">
          <div className="flex justify-between items-center px-4 sm:px-6">
            <h2
              id="languages-heading"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Participating parties
            </h2>
            <Button
              text="New"
              onClick={createModalHelper.open}
            />
          </div>
          <div className="mt-6 flex flex-col">
            <div className="-my-2 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="border-t border-gray-200">
                  <table className="min-w-full mb-1 divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>

                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Default Language
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Email on complete
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Digital Signing
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {participatingParties?.map(
                        (element) => (
                          <tr key={element.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                              {ellipsis(element.name, 50)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                              {getLanguageName(element.defaultLanguage)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                              {element.emailOnCompletion === true ? 'Yes' : 'No'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                              {element.digitalSigning === true ? 'Yes' : 'No'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {
                                <MenuContextList
                                  element={
                                    element
                                  }
                                  deleteType={"Participating party"}
                                  actions={[
                                    {
                                      name: "Edit",
                                      onClick: () => onEditClick(element)
                                    },
                                    {
                                      name: "Delete",
                                      onClick: () => onDelete(element.id),
                                      disabled: participatingParties.length <= 1
                                    },
                                  ]}
                                />
                              }
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SaveModal
        templateLanguages={adminLayoutContext.templateLanguages}
        modalHelper={createModalHelper}
        onSave={onCreate}
        isSaving={createAction.isExecuting}
      />

      <SaveModal
        templateLanguages={adminLayoutContext.templateLanguages}
        modalHelper={editModalHelper}
        onSave={onUpdate}
        record={selectedParticipatingParty}
        isSaving={updateAction.isExecuting}
      />
    </>
  )
}

const SaveModal = ({ record, modalHelper, onSave, isSaving, templateLanguages }) => {
  const templateLanguageActions = useTemplateLanguageActions(templateLanguages);
  const cancel = () => {
    modalHelper.close();
  }

  const modalTitle = record
    ? "Update Participating Party"
    : "Create Participating Party"

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="medium"
      title={modalTitle}
    >
      <Formik
        validateOnBlur={false}
        initialValues={{
          name: record?.name || '',
          emailOnCompletion: record?.emailOnCompletion || false,
          digitalSigning: record?.digitalSigning || false,
          defaultLanguage: record?.defaultLanguage || 'en'
        }}
        validationSchema={() => {
          return yup.object({
            name: yup.string().max(100, a => errorMessages.maxLimit(a.max)).required(errorMessages.required),
          })
        }}
        onSubmit={async (values, actions) => {
          await onSave(values)
          actions.setSubmitting(false)
        }}
      >
        {
          props => (
            <Form>
              <FormikTextInput
                label="Name"
                name="name"
                required={true}
                formikProps={props}
              />
              <div className="mt-4">
                <FormikSelectInput
                  label={"Default Language"}
                  name={'defaultLanguage'}
                  options={
                    templateLanguageActions.getAsSelectOptions()
                  }
                  formikProps={props}
                />
              </div>
              <div className="mt-4">
                <FormikCheckbox
                  label={"Email on completion"}
                  name={'emailOnCompletion'}
                  secondaryLabel="Send Smartform to party when complete (includes PDF if generated)."
                  formikProps={props}
                />
              </div>
              <div className="mt-4">
                <FormikCheckbox
                  label={"Digital Signing"}
                  name={'digitalSigning'}
                  secondaryLabel="Party will need to digitally sign forms."
                  formikProps={props}
                />
              </div>
              <div className="mt-10 flex justify-between">
                <FormikSubmitButton
                  formikProps={props}
                  text="Save"
                  disabled={isSaving}
                />
                <Button
                  onClick={cancel}
                  text="Close"
                  theme="white"
                />
              </div>
            </Form>
          )
        }
      </Formik>
    </Modal>
  )
}