import React from "react";
import JsonCodeExample from "../jsonCodeExample";
import TextLicense from "../textLicense";
import { Select } from "../Properties/select";
import { Menu } from "@headlessui/react";
import { DotsHorizontalIcon, PencilAltIcon, PlusIcon } from "@heroicons/react/outline";
import { WorkflowSelect } from "../Properties/workflowSelect";
import { useProduct } from "../../hooks/useProduct";
import { workflowProductCode } from "../../utils/constants";

export default function DynamicOptions({
    data,
    workflowResourceOptions,
    dynamicState,
    setDynamicState,
}) {

    const hasAccess = useProduct().hasProduct(workflowProductCode);
    const handleChange = (e) => {
        setDynamicState(e.target.value)
    }

    return (
        <>
            <TextLicense hasAccess={hasAccess} productName={"Workflow"} />
            <div className="-ml-4 sm:-ml-6">
                <WorkflowSelect
                    label={"Workflow - Element On Load"}
                    name={"workflowRetrieveLoadId"}
                    onChange={handleChange}
                    value={dynamicState}
                    options={workflowResourceOptions}

                />
            </div>

            <JsonCodeExample />
        </>
    );
}
