import { useContext, useEffect, useState } from 'react'
import * as Property from "../../../../components/Properties";
import { ObjectGroup } from "../../../../components/ObjectGroup";
import * as ObjectTemplates from "../../../../templates/objects";
import { DesignActionbarContext, GlobalDndContext } from '../../../../contexts';
import { useToastAction } from '../../../../hooks/useToastAction';
import { getAllActiveDialogObjects } from '../../../../api/dialogObjects';
import * as Icons from '@fortawesome/pro-light-svg-icons'
import { SortableContext } from '@dnd-kit/sortable';

export default function ContentObjects({ open, setOpen, finishedLoadCustomObject }) {
  const loadCustomObjectsAction = useToastAction();
  const designActionbarContext = useContext(DesignActionbarContext);
  const {
    handleAddElement,
    handleAddTemplate,
    handleAddContainer,
    handleAddRow,
    handleAddColumn,
    disableAddObjects,
    disableAddContainer,
    canAdd,
  } = designActionbarContext || {}
  const globalDndContext = useContext(GlobalDndContext)


  const allObjects = [
    //#region Elements

    { type: "general", text: "List selection", toAdd: ObjectTemplates.ListSelection, icon: Icons.faList },
    { type: "general", text: "Map locations", toAdd: ObjectTemplates.MapLocations, icon: Icons.faMapLocation },
    { type: "general", text: "Headline and content", toAdd: ObjectTemplates.HeadlineAndContent, icon: Icons.faHeading },
    { type: "general", text: "Letter head", toAdd: ObjectTemplates.LetterHead, icon: Icons.faFile },

    //#region Company Presentation
    { type: "companyPresentation", text: "Signature", toAdd: ObjectTemplates.CompanySignature, icon: Icons.faSignature },
    { type: "companyPresentation", text: "Notification", toAdd: ObjectTemplates.CompanyNotification, icon: Icons.faBell },
    { type: "companyPresentation", text: "Person", toAdd: ObjectTemplates.CompanyPerson, icon: Icons.faUser },
    { type: "companyPresentation", text: "Company details", toAdd: ObjectTemplates.CompanyDetails, icon: Icons.faBuilding },
    { type: "companyPresentation", text: "Attachment", toAdd: ObjectTemplates.CompanyAttachment, icon: Icons.faPaperclip },
    
    //#endregion
  ];

  const [objectsToAdd, setObjectsToAdd] = useState(allObjects)

  useEffect(() => {
    loadCustomObjectsAction.execute(async () => {
      const results = await getAllActiveDialogObjects()
      const objects = results.map(r => {
        return (
          {
            type: 'custom',
            text: r.name,
            icon: Icons.faLifeRing,
            toAdd: () => r,
            color: 'purple'
          }
        );
      })
      const all = [
        ...objectsToAdd,
        ...objects,
      ]

      globalDndContext.setSidebarElements([
        // { type: "element", objects: all.filter(o => o.type === "element") },
        { type: "container", objects: all.filter(o => o.type !== "element").map(a => ({ ...a, type: 'container' })) },
      ])

      setObjectsToAdd(all)
    })

    let scrollToElement = window.location.hash.replace('#', '')

    if (scrollToElement) {
      let element = document.getElementById(scrollToElement);
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    finishedLoadCustomObject()
  }, [])

  const handleSearch = (e) => {
    const searchBy = e.target.value

    if (searchBy && searchBy !== '') {
      setObjectsToAdd([...allObjects.filter(m => m.text.toLowerCase().includes(searchBy.toLowerCase()))])
    }
    else {
      setObjectsToAdd([...allObjects])
    }
  }

  const handleFilter = (e) => {
    const filterBy = e.target.value

    if (filterBy === 'all')
      setObjectsToAdd([...allObjects])
    else
      setObjectsToAdd([...allObjects.filter(m => m.type.toLowerCase() === filterBy)])
  }

  if (!canAdd()) {
    return (
      <Property.Group
        title='Locked'
      >
        <div className="ml-6 pt-2">
          This item is locked to a template
        </div>
      </Property.Group>
    )
  }

  if (loadCustomObjectsAction.isExecuting
    // || !globalDndContext.getElements("element")
    || !globalDndContext.getElements("container")) {
    return null
  }

  return (
    <div id={"contentElement"}>
      <div className='h-full px-4 sm:px-6 '>
        <div className="pb-4 ">
          <div className="mt-2 pb-8 border-b">
            <span className="flex-grow flex flex-col" id="availability-label">
              <span className="text-sm font-medium text-gray-500">Search</span>
            </span>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type={"text"}
                className={`focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md`}
                onChange={handleSearch}
              />
            </div>
          </div>
          {/* <div className="mt-2 flex items-center justify-between border-b pb-4">
            <span className="flex-grow flex flex-col" id="availability-label">
              <span className="text-sm font-medium text-gray-500">Filter</span>
            </span>
            <div className="mt-1 ml-7 relative rounded-md shadow-sm">
              <select
                className={`focus:ring-gray-400 focus:border-gray-400 block w-72 sm:text-sm border-gray-300 rounded-md`}
                onChange={handleFilter}
              >
                {<option value="all">All</option>}
                {
                  Array.from(new Set(allObjects.map(({ type }) => type))).map(
                    (type, index) => <option key={index} value={type}>{type.split('_').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')}</option>
                  )
                }
              </select>
            </div>
          </div> */}
        </div>
        <div className="popover-container-items pt-3 mb-2 element-pop-bottom">
          {/* <SortableContext
            id='element-sortable-context-sidebar'
            items={globalDndContext.getElements("element").map(e => e.dndId)}
          >
            <ObjectGroup
              label="Elements"
              defaultOpen={true}
              handleOnClick={handleAddElement}
              objectsToAdd={objectsToAdd.filter(m => m.type === 'element')}
              useFormButton={false}
              hideWhenEmpty={true}
              useGrid={true}
              enableDragAndDrop={true}
              dndType="element"
            />
          </SortableContext> */}

          <SortableContext
            id={`object-sortable-context-sidebar`}
            items={globalDndContext.getElements('container').map(e => e.dndId)}
          >
            <ObjectGroup
              label="General"
              defaultOpen={true}
              isHidden={disableAddObjects}
              handleOnClick={handleAddTemplate}
              objectsToAdd={objectsToAdd.filter(m => m.type === 'general')}
              useFormButton={false}
              hideWhenEmpty={true}
              useGrid={true}
              enableDragAndDrop={true}
              dndType="container"
            />

            <ObjectGroup
              label="Company Presentation"
              id="companyPresentation"
              defaultOpen={true}
              isHidden={disableAddObjects}
              handleOnClick={handleAddTemplate}
              objectsToAdd={objectsToAdd.filter(m => m.type === 'companyPresentation')}
              useFormButton={false}
              hideWhenEmpty={true}
              useGrid={true}
              enableDragAndDrop={true}
              dndType="container"
            />          

            <ObjectGroup
              label="Custom"
              defaultOpen={true}
              isHidden={disableAddObjects}
              handleOnClick={handleAddTemplate}
              objectsToAdd={objectsToAdd.filter(m => m.type === 'custom')}
              useFormButton={false}
              hideWhenEmpty={true}
              useGrid={true}
              enableDragAndDrop={true}
              dndType="container"
            />
          </SortableContext>
        </div>
      </div>
    </div>
  )
}
