import { useContext } from "react";
import {  OidcRoutesContext } from "../contexts";

export const useProduct = (data) => {
  const oidcRoutesContext = useContext(OidcRoutesContext);

  return {
    hasProduct: (productCode) => {
      const product = oidcRoutesContext?.userProfile?.customerUserAccess?.productFeatureAccessSelections?.find(x => x?.productCode === productCode)
  
      if(!product) return false

      return !!product?.usageFeature?.hasAccess
    }
  }
}