import { useCallback, useState } from "react";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { useDefinitionManager } from "../../../screens/Admin/DialogDesign/useDefinitionManager";
import { conditionConectorTypes, elementTypes, elementTypesEnabledForLogicControl } from "../../../utils/constants"
import { AddConditionButtons } from "./addConditionButtons";
import { Condition } from "./condition";
import { NewCondition } from "./newCondition";


export const LogicControl = ({ dialogDefinition, currentContainer, name, values = [], onChange }) => {
    const currentContainerId = currentContainer.id;
    const [editItemIndex, setEditItemIndex] = useState();
    const errorHandler = useErrorHandler();
    const definitionManager = useDefinitionManager();
    const allElementsOfContainer = definitionManager.elementsOfTypeExceptContainer(dialogDefinition, currentContainerId, elementTypesEnabledForLogicControl)
    const elementsOfContainer = allElementsOfContainer.filter(e => e.type !== elementTypes.radioButtonGroup)
    const findGroupElement = definitionManager.elementsOfTypeExceptGroupedByRow(dialogDefinition, currentContainerId, [elementTypes.radioButtonGroup])

    const getElement = useCallback((property) => definitionManager.findElementByProperty(dialogDefinition, property), [])
    const [newConditionConector, setNewConditionConector] = useState(false)
    const conditionConectorTypeKeys = Object.keys(conditionConectorTypes).map((value) => value.charAt(0).toUpperCase() + value.slice(1));
    const isFirstCondition = !values?.length;
    const containerWithoutShowHide = dialogDefinition.containers.filter(container => !container?.showLogicControl?.length)

    const isLastContainerWithoutLogicControls = containerWithoutShowHide.length === 1
    const selectedIsLastContainerWithoutLogicControls = isLastContainerWithoutLogicControls ? currentContainerId === containerWithoutShowHide?.[0].id : false
    
    const updateBeforeConector = (conditions, newConector) => {
        const updateBeforeConector = { ...conditions[values.length - 1], conector: newConector }
        return [
            ...conditions.slice(0, values.length - 1),
            updateBeforeConector,
            ...conditions.slice(values.length + 1),
        ];
    }

    const onRemoveConditionClick = (id) => {
        let conditions = [...values];

        if (conditions.length === 1) {
            conditions = []
        } else {
            const index = conditions.findIndex((condition) => condition.id === id)
            if (index === 0) {
                conditions = conditions.slice(1)
            } else if (index === conditions.length - 1) {
                conditions = conditions.slice(0, conditions.length - 1)
            } else {
                conditions = updateBeforeConector(conditions, conditionConectorTypes.none)
                conditions = conditions.slice(0, index).concat(conditions.slice(index + 1))
            }
        }

        onSaveConditions(conditions)
    }

    const onSaveNewConditionClick = (newCondition) => {
        let conditions = values
        if (isFirstCondition) {
            conditions = [newCondition]
        } else {
            conditions = updateBeforeConector(conditions, newConditionConector)
            conditions.push(newCondition)
        }
        onSaveConditions(conditions)
        setNewConditionConector(false)
    }

    const onSaveUpdateConditionClick = (updatedCondition, index) => {
        const updatedConditions = Object.assign([], values);
        updatedConditions[index] = Object.assign({}, updatedCondition);

        onSaveConditions(updatedConditions)
        setEditItemIndex(false)
    }

    const onSaveConditions = (conditions) => {
        const buildNewCondition = {
            target: {
                name: "showLogicControl",
                value: conditions
            }
        }
        onChange(buildNewCondition)
    }

    const onAddFirstConditionClick = () => {
        if (elementsOfContainer.length || findGroupElement.length) {
            setNewConditionConector(conditionConectorTypes.and)
        } else {
            errorHandler.handleApiError({ customErrorMessage: "There are no conditional elements in other sections." })
        }

    }

    const valuesWithElementWithoutDeleted = (values || []).map(value => {
        const element = getElement(value?.condition?.property);
        return element ? { ...value, element } : undefined;
    }).filter(Boolean);

    return selectedIsLastContainerWithoutLogicControls ?
        <div className="px-4 sm:px-6 pt-3 text-sm">At least one container must be left without conditions.</div>
        : (
            <div className="px-4 sm:px-6 pt-3">
                {
                    !newConditionConector && (values?.length <= 0 || !values) &&
                    <p className="text-sm">
                        Conditions when this section is visible.
                    </p>
                }
                {/* <input className="border-2" name={name} onChange={onChange} /> */}

                <div className="flex flex-col">
                    {valuesWithElementWithoutDeleted?.map((value, index) => {
                        let containerName;
                        let groupedElement = [];
                        if (value.element.type === elementTypes.radioButtonGroup) {
                            groupedElement = findGroupElement.find(x => x.elements.find(e => e.id === value.element.id));
                            containerName = `Radio group-${groupedElement.containerName}`
                        }

                        return value.element && (
                            <div key={index}>
                                <Condition
                                    setIndexEdit={(i) => setEditItemIndex(i)}
                                    indexEdit={editItemIndex === index}
                                    index={index}
                                    element={value.element}
                                    containerName={containerName}
                                    conditionType={value?.condition?.conditionType}
                                    match={value.condition.match}
                                    conectorLabel={conditionConectorTypeKeys[value.conector]}
                                    inNew={!newConditionConector}
                                    conector={value.conector}
                                    condition={value}
                                    onRemoveConditionClick={() => onRemoveConditionClick(value.id)}
                                    onConfirmEditClick={(updatedCondition) => onSaveUpdateConditionClick(updatedCondition, index)}
                                    saved
                                    groupedElementsOfContainer={findGroupElement}
                                />
                            </div>
                        )
                    })}
                </div>
                {
                    newConditionConector &&
                    <NewCondition
                        conectorLabel={conditionConectorTypeKeys[newConditionConector]}
                        elementsOfContainer={elementsOfContainer}
                        groupedElementsOfContainer={findGroupElement}
                        cancelNewConditionClick={() => setNewConditionConector(false)}
                        onSaveNewCondition={(newCondition) => onSaveNewConditionClick(newCondition)}
                        isFirstCondition={isFirstCondition}
                        defaultElementSelector={elementsOfContainer?.[0] || { ...findGroupElement?.[0].elements?.[0], rowId: findGroupElement?.[0]?.rowId }} />
                }
                {
                    (editItemIndex !== 0 && !editItemIndex && !newConditionConector) && <AddConditionButtons
                        isFirstCondition={isFirstCondition}
                        onAddFirstConditionClick={() => onAddFirstConditionClick()}
                        onNewConditionClick={(condConector) => setNewConditionConector(condConector)} />
                }
            </div>
        );
}