import { PlaceholderInput, useAlertModal, useClassNames } from '@metaforcelabs/metaforce-core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { activateEmailNotificationTemplate, getEmailNotificationTemplate, getNotificationEventTypes, resetEmailNotificationTemplate, updateEmailNotificationTemplate } from '../../../../api/notificationTemplates';
import { useToastAction } from '../../../../hooks/useToastAction';
import { notificationEventType, notificationTemplateStatus, templateLanguages } from '../../../../utils/constants';
import { Formik, Form } from 'formik';
import * as yup from "yup";
import { validateBodies, validateRequiredTextField, validateRequiredTextFieldWithPlaceholders, validateSubjects } from '../validators';
import { useReactIconFactory } from '../../../../hooks/useReactIconFactory';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import { FormikTextInput } from '../../../../components/Form/Formik/FormikTextInput';
import { useModalHelper } from '../../../../hooks/useModalHelper';
import Confirm from '../../../../components/Confirm';
import { FormikCheckbox } from '../../../../components/Form/Formik/FormikCheckbox';
import FormikRadioButton from '../../../../components/Form/Formik/FormikRadioButton';


export default function EmailTemplateForm() {

  const [template, setTemplate] = useState();
  const [currentLanguageTemplate, setCurrentLanguageTemplate] = useState();
  const { templateId } = useParams();
  const [selectedLanguage, setSelectedLanguage] = useState(templateLanguages[0].value);
  const [notificationEventTypes, setNotificationEventTypes] = useState([]);
  const navigate = useNavigate();
  const { alertWarn } = useAlertModal();
  const loadAction = useToastAction();
  const saveAction = useToastAction();
  const activateAction = useToastAction();
  const activateModalHelper = useModalHelper()
  const { classNames } = useClassNames();
  const { createReactIcon } = useReactIconFactory();

  const loadData = async () => {
    loadAction.execute(async () => {
      const eventTypesData = await getNotificationEventTypes();
      setNotificationEventTypes(eventTypesData);

      const data = await getEmailNotificationTemplate(templateId);

      setTemplate(data);

      createLanguageTemplate(data, selectedLanguage);
    }, "Failed to load template")
  }

  const createLanguageTemplate = (template, lang) => {
    const { languageContents, ...serverTemplate } = template;

    let langContent = languageContents?.find(x => x.languageCode === lang);
    if (!langContent) {
      langContent = {
        subject: "",
        body: "",
        languageCode: lang,
        messageHtml: true,
      }
    }
    const languageTemplate = {
      subject: langContent?.subject || "",
      body: langContent?.body || "",
      name: serverTemplate.name,
      messageHtml: langContent?.messageHtml,
      notificationEventType: serverTemplate.notificationEventType,
    }

    setCurrentLanguageTemplate(languageTemplate);
  }

  useEffect(() => {
    loadData();
  }, [])

  const onActivate = () => {
    activateAction.execute(async () => {
      await activateEmailNotificationTemplate(templateId);
    }, "Error activating", "Template activated")
  }

  const handleSaveTemplate = (values) => {

    const { name, notificationEventType, ...languageContents } = values;
    languageContents.languageCode = selectedLanguage;
    saveAction.execute(async () => {

      const updated = await updateEmailNotificationTemplate(templateId, { name, notificationEventType, languageContents })
      setTemplate(updated);
      createLanguageTemplate(updated, selectedLanguage);

    }, "Failed to save", "Template saved")
  }

  const handleLanguageChange = ({ value }) => {
    setSelectedLanguage(value);
    createLanguageTemplate(template, value);
  }

  const getPlaceholdersForType = (notificationEventType) => {
    const placeholders = notificationEventTypes.find(x => x.value === notificationEventType)?.placeholders;
    return placeholders?.map(({ iconKey, ...placeholder }) => {
      const icon = createReactIcon(iconKey);
      return { ...placeholder, icon: icon };
    }) || [];
  }

  const handleResetTemplate = (values) => {
    saveAction.execute(async () => {

      const { languageContents, ...toUpdate } = template;

      const updated = await resetEmailNotificationTemplate(templateId, toUpdate);

      setTemplate(updated);
      createLanguageTemplate(updated, selectedLanguage);

    }, "Failed to reset", "Template has been reset")
  }

  return (
    <>
      <div className="mt-10 pb-5 border-b border-gray-200 flex justify-between items-center">
        <h1 className="text-3xl font-bold">Edit Template</h1>
        {
          template?.isDefaultForType &&
          <button
            type="button"
            className="mt-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
            onClick={() => handleResetTemplate(template)}
          >
            Reset template values
          </button>
        }
      </div>
      <div className='my-2 sm:my-4 md:my-6'>
        <div className="bg-white shadow rounded-lg mt-4 p-8">
          <Formik
            initialValues={currentLanguageTemplate}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting }) => {
              await handleSaveTemplate(values);
              setSubmitting(false)

            }}

            validationSchema={
              yup.object().shape({
                name: yup.string().required("Required"),
                notificationEventType: yup.number().required().oneOf([notificationEventType.notification, notificationEventType.securityCode, notificationEventType.processStep, notificationEventType.completedWithPdf, notificationEventType.reminder], "Invalid value"),
                subject: yup.string().required("Required"),
                body: yup.string().test('is-42',
                  "Missing required placeholder",
                  (value, ctx) => {
                    const error = validateRequiredTextFieldWithPlaceholders(value, getPlaceholdersForType(ctx.parent?.notificationEventType))
                    return !error;
                  },).required("Required"),
                messageHtml: yup.boolean(),
              })
            }
          >
            {props => (
              <Form>
                <div className='space-y-6 sm:space-y-5'>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Template Name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <FormikTextInput
                        name={"name"}
                        formikProps={props}
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Event
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select className='shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400'
                        disabled={template?.isDefaultForType}
                        value={props.values?.notificationEventType}
                        name="notificationEventType"
                        onBlur={props.handleBlur}
                        onChange={({ target }) => {
                          props.setFieldValue(target.name, +target.value);
                        }}
                      >
                        {
                          notificationEventTypes.map(o => <option key={o.value} value={o.value}>{o.name}</option>)
                        }
                      </select>
                      <div className="text-red-500">{props.errors?.notificationEventType}</div>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Language
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select className='shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400'
                        value={selectedLanguage}
                        onChange={e => handleLanguageChange(e.target)}
                      >
                        {
                          templateLanguages.map(o => <option key={o.value} value={o.value}>{o.name}</option>)
                        }
                      </select>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Subject
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <PlaceholderInput
                        inputValue={props.values?.subject || ""}
                        className={classNames('shadow-sm block w-full sm:text-sm rounded-md focus-within:ring-1',
                          props.errors?.subject ? 'focus-within:ring-red-500 focus-within:border-red-500 border border-red-500' : 'focus-within:ring-gray-400 focus-within:border-gray-400 border border-gray-300'
                        )}
                        placeholderText="Subject"
                        name="subject"
                        placeholders={getPlaceholdersForType(props.values?.notificationEventType)}
                        onChange={({ name, value }) => {
                          props.setFieldValue(name, value)
                        }}
                      />
                      <div className="text-red-500">{props.errors?.subject}</div>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Body Format
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="flex space-x-4">
                        <FormikRadioButton
                          formikProps={props}
                          name={"messageHtml"}
                          label={"HTML"}
                          value={true}
                          styles={"cursor-pointer"}
                          onChange={e => {
                            props.setFieldValue("messageHtml", true)
                          }}
                        />
                        <FormikRadioButton
                          formikProps={props}
                          name={"messageHtml"}
                          label={"Text"}
                          value={false}
                          onChange={e => {
                            props.setFieldValue("messageHtml", false)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Message
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <PlaceholderInput
                        inputValue={props.values?.body || ""}
                        className={classNames('shadow-sm block w-full sm:text-sm rounded-md focus-within:ring-1',
                          props.errors?.body ? 'focus-within:ring-red-500 focus-within:border-red-500 border border-red-500' : 'focus-within:ring-gray-400 focus-within:border-gray-400 border border-gray-300'
                        )}
                        placeholderText="Message"
                        placeholders={getPlaceholdersForType(props.values?.notificationEventType)}
                        name="body"
                        onChange={({ name, value }) => {
                          props.setFieldValue(name, value)
                        }}
                      />
                      <div className="text-red-500">{props.errors?.body}</div>
                    </div>
                  </div>
                  <div className="flex space-x-4 sm:justify-end sm:border-t sm:border-gray-200">
                    <button
                      className="w-1/2 sm:w-auto mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:col-start-1 sm:text-sm"
                      type='button'
                      onClick={e => {

                        if (_.isEqual(props.values, template)) {
                          navigate('/admin/templates/email')
                        } else {
                          alertWarn("Unsaved changes, leave anyways?", () => { navigate('/admin/templates/email') }, true, "Cancel", () => { }, "Leave")
                        }

                      }}

                    >
                      Back
                    </button>
                    <FormikSubmitButton
                      className='w-1/2 sm:min-w-40 sm:w-auto mt-3 inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:text-sm'
                      disabled={_.isEqual(props.values, template)}
                      formikProps={props}
                      text={"Save"}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Confirm
        modalHelper={activateModalHelper}
        title="Would you like to activate the template?"
        onConfirm={onActivate}
      />
    </>
  )
}
