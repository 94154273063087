import { useEffect, useState } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getAllSmartObjects } from '../../api/smartObject';
import ColumnPopup from './columnPopup';


export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  const [openPopup, setOpenPopup] = useState(false)
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const [smartObjectOptions, setSmartObjectOptions] = useState([]);

  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])

  useEffect(() => {
    loadSmartObjectOptions();
  }, [])

  const loadSmartObjectOptions = async () => {

    var smartObjectOptions = await getAllSmartObjects()
    var options = [{ value: "", name: "None" }]

    smartObjectOptions.forEach(smartObject => {
      options.push({
        value: smartObject.id,
        name: smartObject.name
      })
    })

    setSmartObjectOptions(options)
  }

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="popover-container-items">
      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="Smart Table"
        />

        <Property.Text
          onChange={(e) => handleOnChange(e)}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={'Connected Smart Object'}
          name={'smartTable.smartObjectId'}
          value={data?.smartTable?.smartObjectId}
          options={smartObjectOptions}
          labelTop={true}
        />

      </Property.Group>

      <Property.Group
        title='Smart Table'>

        <Property.Text
          onChange={(e) => handleOnChange(e)}
          label={'Heading'}
          name={'smartTable.heading'}
          value={data?.smartTable?.heading}
        />

        <Property.TextArea
          onChange={(e) => handleOnChange(e)}
          label={'Description'}
          name={'smartTable.description'}
          value={data?.smartTable?.description}
        />

        <Property.Text
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Max rows'}
          name={'smartTable.maxRows'}
          value={data?.smartTable?.maxRows}
        />

        <Property.Checkbox  
          onChange={handleOnChange}
          label={'Prevent edit items'}
          name={'smartTable.preventEditItems'}
          value={data?.smartTable?.preventEditItems}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Show row numbers'}
          name={'smartTable.showRowNumber'}
          value={data?.smartTable?.showRowNumber}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Make selectable'}
          name={'smartTable.makeSelectable'}
          value={data?.smartTable?.makeSelectable}
        />

      </Property.Group>

      <Property.Group
        title='Design'>
        
        <Property.Subgroup
         title="General"
         first>

          <Property.Color
            onChange={handleOnChange}
            label={'Border color'}
            name={'borderColor'}
            initColor={data?.borderColor}
          />

          <Property.BorderStyleAndWidth
            onChange={handleOnChange}
            label={'Border'}
            name={'borderWidth'}
            value={data?.borderWidth}
          /> 

        </Property.Subgroup>

        <Property.Subgroup
         title="Header">

          <Property.ButtonWithLabel
            label="Columns"
            onClick={() => setOpenPopup(true)}
            text='Edit Columns'
          />
          <Property.Color
            onChange={handleOnChange}
            label={'Text Color'}
            name={'smartTable.headerTextColor'}
            initColor={data?.smartTable?.headerTextColor}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Text Size'}
            name={'smartTable.headerTextSize'}
            type={'number'}
            value={data?.smartTable?.headerTextSize || 16}
          /> 

        </Property.Subgroup>

        <Property.Subgroup
         title="Body">

          <Property.Color
            onChange={handleOnChange}
            label={'Text Color'}
            name={'smartTable.cellTextColor'}
            initColor={data?.smartTable?.cellTextColor}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Text Size'}
            name={'smartTable.cellTextSize'}
            type={'number'}
            value={data?.smartTable?.cellTextSize || 16}
          />

        </Property.Subgroup>

        <Property.Subgroup
         title="Stripes">

          <Property.Checkbox
            onChange={handleOnChange}
            label={'Striped Rows'}
            name={'smartTable.stripedRows'}
            value={data?.smartTable?.stripedRows}
          />

          <Property.Color
            onChange={handleOnChange}
            label={'Stripes Odd'}
            name={'smartTable.stripesOddColor'}
            initColor={data?.smartTable?.stripesOddColor}
          />

          <Property.Color
            onChange={handleOnChange}
            label={'Stripes Even'}
            name={'smartTable.stripesEvenColor'}
            initColor={data?.smartTable?.stripesEvenColor}
        />

         </Property.Subgroup>

         <Property.Subgroup
         title="Button">

          <Property.Text
            onChange={handleOnChange}
            label={'Label'}
            name={'smartTable.addButtonText'}
            value={data?.smartTable?.addButtonText}
          />

          <Property.Color
            onChange={handleOnChange}
            label={'Text color'}
            name={'smartTable.addButtonTextColor'}
            initColor={data?.smartTable?.addButtonTextColor}
          />

          <Property.Color
            onChange={handleOnChange}
            label={'Background'}
            name={'smartTable.addButtonBackground'}
            initColor={data?.smartTable?.addButtonBackground}
          />

        </Property.Subgroup>  
      </Property.Group>

      <ColumnPopup smartTableHeaders={data?.smartTableHeaders} smartObjectId={data?.smartTable?.smartObjectId} data={data} onChange={handleOnChange} open={openPopup} setOpen={setOpenPopup} />

    </div>
  )
}
