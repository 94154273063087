export const useSelectInputHelpers = ()=> {

    return {
        createOptions: (array, valueSelector = "value", nameSelecto = "name" ) => {
            
            return array.map(item => {
                return {
                    value: item[valueSelector],
                    name: item[nameSelecto],
                    disabled: item.disabled || false
                }
            })
        }
    }
}