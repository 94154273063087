export const Checkbox = ({
  id,
  name,
  label,
  labelPositionRight = false,
  checked,
  onChange,
  inputClassName = null,
  labelClassName = null,
  disabled = false,
  title = null
}) => {
  return (
    <div className={`flex items-center ${labelPositionRight ? 'flex-row-reverse' : 'flex-row'}`}>
      <label
        className={`text-sm font-medium text-gray-700 ${labelClassName} ${!labelPositionRight ? 'mr-1' : ''}`}
        htmlFor={name}
      >
        {label}
      </label>
      <input
        type="checkbox"
        name={name}
        id={id}
        disabled={disabled}
        checked={checked}
        onChange={(evt) => onChange(evt.target.checked, name)}
        className={`mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded ${inputClassName}`}
        title={title}
      />
    </div>
  )
}