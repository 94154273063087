import { useContext } from 'react'
import MarginPaddingPicker from "../../../../components/MarginPaddingPicker"
import ColorPicker from '../../../../components/ColorPicker';
import { useClassNames } from "../../../../hooks/useClassNames";
import { DesignActionbarContext } from '../../../../contexts';
import { dialogType, dialogTypeOptions, positionOptions } from '../../../../utils/constants';
import { SidebarButton } from '../../../../components/SidebarButton';
import { faArrowRotateRight } from '@fortawesome/pro-light-svg-icons';
import { PropertyOfType } from './PropertiesOfType';
import { OutsideStyles } from './PropertiesOfType/outsideStyles';

export default function Theme() {
  const classNames = useClassNames();

  const designActionbarContext = useContext(DesignActionbarContext);
  const {
    dialogDefinition,
    handleGeneralChange,
    handleResetToDefault,
    handleUpdatePageConfig,
    allStyles,
    handleThemeTypeChange,
    apiDefinitionOptions,
    setMultipleDialogDefinitionValues
  } = designActionbarContext || {}


  const handleThemeStyleChange = (e) => {
    let style = allStyles.find(style => style.id === e.target.value)
    handleGeneralChange(e)
    handleUpdatePageConfig(style)
  }

  return (
    <div
      className={classNames.classNames(
        'h-full px-4 sm:px-6 '
      )}
    >
      <div>
        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="style-label">
            <span className="text-sm font-medium text-gray-900">Style</span>
          </span>
          <select
            name="themeStyleId"
            className="mt-1 block w-48 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-pink focus:border-brand-pink sm:text-sm rounded-md"
            value={dialogDefinition.themeStyleId}
            onChange={(e) => handleThemeStyleChange(e)}
          >
            {
              allStyles.map(style => <option key={style.id} value={style.id}>{style?.name}</option>)
            }
          </select>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Background color</span>
          </span>
          <ColorPicker
            name='backgroundColor'
            onChange={handleGeneralChange}
            alignRight={true}
            initColor={dialogDefinition.backgroundColor} />
        </div>

        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Toolbar color</span>
          </span>
          <ColorPicker
            name='toolbarColor'
            onChange={handleGeneralChange}
            alignRight={true}
            initColor={dialogDefinition.toolbarColor} />
        </div>

        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Margin</span>
          </span>
          <MarginPaddingPicker name={"margin"} value={dialogDefinition.margin} type={'margin'} onChange={handleGeneralChange} />
        </div>

        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Padding</span>
          </span>
          <MarginPaddingPicker name={"padding"} value={dialogDefinition.padding} type={'padding'} onChange={handleGeneralChange} />
        </div>

        <div className="flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Type</span>
          </span>
          <select
            name="type"
            className="mt-1 block w-48 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-pink focus:border-brand-pink sm:text-sm rounded-md"
            value={dialogDefinition.type}
            onChange={(e) => handleGeneralChange(e, 'number')}
          >
            {
              dialogTypeOptions.map((type, index) => {
                return <option key={index} value={type.value}>{type.name}</option>
              })
            }
          </select>
        </div>

        {dialogDefinition.type === dialogType.stepByStep &&
          <div className="mt-2 flex items-center justify-between">
            <span className="flex-grow flex flex-col">
              <span className="text-sm font-medium text-gray-900">Position</span>
            </span>
            <select
              name="position"
              className="mt-1 block w-48 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-pink focus:border-brand-pink sm:text-sm rounded-md"
              value={dialogDefinition.position}
              onChange={(e) => handleGeneralChange(e, 'number')}
            >
              {
                positionOptions.map((type, index) => {
                  return <option value={type.value} key={index}> {type.name}</option>
                })
              }
            </select>
          </div>
        }

        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Size</span>
          </span>
          <select
            name="size"
            className="mt-1 block w-48 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-pink focus:border-brand-pink sm:text-sm rounded-md"
            value={dialogDefinition.size}
            onChange={(e) => handleGeneralChange(e, 'number')}
          >
            <option value={0}>Small</option>
            <option value={1}>Medium</option>
            <option value={2}>Large</option>
          </select>
        </div>

        <div className="mt-2 flex items-center justify-between">
          <span className="flex-grow flex flex-col" id="availability-label">
            <span className="text-sm font-medium text-gray-900">Disabled PDF Generation</span>
          </span>
          <input
            name='disabledPdfGeneration'
            type='checkbox'
            checked={dialogDefinition.disabledPdfGeneration}
            onChange={(e) => handleGeneralChange(e, 'boolean')}
            className="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 border-gray-300 rounded mt-px"
          >
          </input>
        </div>
      </div>

      <div className={`mt-8 border-t border-gray-200 pt-7`}>
          <PropertyOfType dialogDefinition={dialogDefinition} handleChange={handleThemeTypeChange} />
      </div>

      <div className={`mt-8 border-t border-gray-200 pt-7`}>
        <div className={`pt-3 grid grid-cols-2 gap-2`}>
          <SidebarButton text='Reset to default' onClick={handleResetToDefault} icon={faArrowRotateRight} />
        </div>
      </div>
    </div>

  )
}
