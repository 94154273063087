import { SearchIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react'
import { Badge } from '../../../components/Badge';
import { useTableSort } from '../../../hooks/useTableSort';
import { useToastAction } from '../../../hooks/useToastAction';
import { editStates } from '../../../utils/constants';
import { Link } from 'react-router-dom';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { getDialogDefinitions } from '../../../api/dataLoad';
import { useTableSearch } from '../../../hooks/useTableSearch';
import { useFileSaver } from '../../../hooks/useFileSaver';

export default function DataLoad() {
    const [allDialogs, setAllDialogs] = useState([]);
    const [dialogsToShow, setDialogsToShow] = useState([]);
    const tableSort = useTableSort(dialogsToShow);
    const tableSearch = useTableSearch(allDialogs);
    const fileSaver = useFileSaver();
    const loadAction = useToastAction();
    const createAction = useToastAction();

    const loadData = () => {
        loadAction.execute(async () => {
            const activeDefinitions = await getDialogDefinitions();
            setAllDialogs(activeDefinitions);
            setDialogsToShow(activeDefinitions);
        }, "Failed to load dialogs")
    }

    useEffect(() => {
        loadData();
    }, []);

    const handleSearch = (e) => {
        const searchBy = e.target.value

        tableSearch.search(searchBy, setDialogsToShow, "name");
    }

    const onHandleLog = async (dialogDefinitionId) => {

    }

    const DataloaderInfoHeader = () => {
        return (
            <>
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">The Smartform dataloader will enable you to setup data process for retrieving and returning data to external data providers (FTP sites, FIles, databases, CRM systems, etc.)</h3>
                </div>
                <div className="hidden pl-5 text-gray-500 sm:flex justify-center items-center">
                    <div className="flex flex-col justify-center h-full">
                        <svg xmlns="http://www.w3.org/2000/svg" className="my-1 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="my-1 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="my-1 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
                        </svg>
                    </div>
                    <div className="px-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                        </svg>
                    </div>
                    <div className="relative w-32">
                        <svg xmlns="http://www.w3.org/2000/svg" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-32 w-32" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={.7} d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                        </svg>
                        <span className="absolute top-14 left-1/2 transform -translate-x-1/2 -translate-y-14 text-sm font-bold">
                            CenterPoint
                        </span>
                    </div>
                    <div className="px-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                        </svg>
                    </div>
                    <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="max-w-screen-3xl">
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <h3 className="text-xl leading-6 font-medium text-gray-900">Smartform Dataloader</h3>
                <div className="mt-3 sm:mt-0 sm:ml-4 w-96">
                    <label htmlFor="desktop-search-candidate" className="sr-only">
                        Search
                    </label>
                    <div className="flex rounded-md shadow-sm">
                        <div className="relative flex-grow focus-within:z-10">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                                type="text"
                                name="desktop-search-candidate"
                                id="desktop-search-candidate"
                                className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                                placeholder="Search"
                                onChange={handleSearch}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className="py-5 border-b border-gray-200 sm:grid sm:grid-cols-2">
                <DataloaderInfoHeader />
            </div>
            <div className="my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <h3 className="mb-2 text-lg leading-6 font-medium text-gray-900">Available Smartforms for dataload:</h3>
                    <p className="pb-2">
                        Get started by selecting a Smartform
                    </p>
                    <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() => tableSort.orderBy("name")}
                                    >
                                        Smartform {tableSort.getSortIcon('name')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() => tableSort.orderBy("editState")}
                                    >
                                        State {tableSort.getSortIcon('editState')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() => tableSort.orderBy("lastUploadDate")}
                                    >
                                        Last Upload {tableSort.getSortIcon('lastUploadDate')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    !!dialogsToShow.length &&
                                    (tableSort?.tableData?.map((element) => (
                                        <tr key={element.dialogDefinitionId}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                <Link
                                                    className="underline"
                                                    to={`/admin/dataload/${element.dialogDefinitionId}`}
                                                >
                                                    {element.name}
                                                </Link>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {
                                                    element.editState === editStates.draft
                                                        ? <Badge type="warn" text="Draft" />
                                                        : <Badge type="success" text="Published" />
                                                }
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stringToLocaleDateTimeString(element.lastUploadDate)}</td>
                                        </tr>
                                    )))
                                }
                                {
                                    !dialogsToShow.length && !loadAction.isExecuting && (
                                        <tr>
                                            <td colSpan={6} className="py-4">
                                                <div className="flex justify-center items-center">
                                                    {
                                                        !allDialogs.length ?
                                                            (<>
                                                                No active dialogs.
                                                                {/* <button
                                                                    className="ml-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                    onClick={() => { setShowNewDialog(true) }}>
                                                                    + Create new dialog
                                                                </button> */}
                                                            </>)
                                                            : (<>Search yielded no result</>)
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
