import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from 'react-router';
import {
  generateSecurityToken,
  validateSecurityToken,
  generateSecurityTokenForStep,
  validateSecurityTokenForStep,
  generateBankIdUrl,
  exchangeBankIdToken,
  deleteCurrentSecurityToken,
  deleteCurrentSecurityTokenForStep,
  loadFormAuthentication,
} from "../../api/public/dialogValues";
import { Button } from "../../components/Form/Button";
import { TextInput } from "../../components/Form/TextInput";
import { useToastAction } from "../../hooks/useToastAction"
import { securityTypeOptions, securityType, processTypes } from "../../utils/constants";
import { useTokenAuthentication } from "./useTokenAuthentication";
import { onKeyPressEnter } from "../../utils/onKeyPress";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input"
import { PhoneInputConfig } from "../../utils/config"
import { getContactOnContactList } from "../../api/public/contactList";
import { useEnterKeyPress } from "../../hooks/useEnterKeyPress";
import { isValidDate } from "../../utils/date";

export const AuthenticateForm = () => {
  const { dialogKey, valuesKey, currentStepKey } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState();
  const [birthday, setBirthday] = useState('');
  const [authenticationType, setAuthenticationType] = useState()
  const [securityOptions, setSecurityOptions] = useState([])
  const [authenticationTemplate, setAuthenticationTemplate] = useState([])
  const [securityTokenObject, setSecurityTokenObject] = useState(null);
  const currentStep = useRef()
  const [contactListAuth, setContactListAuth] = useState(false)
  const [matchingContactListContactId, setMatchingContactListContactId] = useState(false)
  const generateTokenAction = useToastAction();
  const validateTokenAction = useToastAction(true);
  const tokenAuthentication = useTokenAuthentication();
  const [phoneNumber, setPhoneNumber] = useState('')
  const [validPhoneNumberFormat, setValidPhoneNumberFormat] = useState(false)
  const [dialogDefinition, setDialogDefinition] = useState()
  const lookupPhoneNumberAction = useToastAction()
  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get("code")
  const transaction_id = searchParams.get("transaction_id")
  const bankIdCancelled = searchParams.get("transaction_id") && (searchParams.get("success") == "false");


  useEffect(() => {
    load();
  }, [])

  const load = async () => {
    const { dialogDefinition,
      authenticationTemplate,
      securityToken,
      step,
      securityOptions,
      process,
      authenticationRequired } = await loadFormAuthentication({
        dialogDefinitionId: dialogKey,
        dialogValuesId: valuesKey,
        stepId: currentStepKey,
        dialogValuesPhoneNumberLookupJwt: tokenAuthentication.getJwtForDialogValuesPhoneNumberLookup(valuesKey)
      })

    if (authenticationRequired) {
      // authenticationRequired response will only be set if it's a contact list auth that hasn't done
      // the phone number lookup yet
      tokenAuthentication.handlePhoneListProcessUnauthorized(dialogKey, valuesKey)
      return
    }

    setDialogDefinition(dialogDefinition);
    setAuthenticationTemplate(authenticationTemplate)

    if (process === processTypes.normal) {
      if (securityToken) {
        setSecurityTokenObject(securityToken);
      }
      else {
        onSetSecurityType(securityType.phone, dialogDefinition)
      }
    }
    else {
      currentStep.current = step

      if (securityToken) {
        setSecurityTokenObject(securityToken);
      }
      else {
        if (securityOptions.length === 1) {
          onSetSecurityType(securityOptions[0])
        }
        else {
          setSecurityOptions(securityOptions);
        }
      }
    }
  }

  useEffect(() => {
    if (securityTokenObject?.securityType === securityType.bankId) {
      if (bankIdCancelled) {
        navigate(location.pathname, { replace: true });
        onCancel();
        load();
      }
      else {
        if (code) {
          onValidate(code);
        }
        else if (transaction_id) {
          onValidate(transaction_id);
        }
        else {
          performBankId(securityTokenObject.nationality);
        }
      }
    }
    if (securityTokenObject?.securityType === securityType.phone && securityTokenObject.contactListContactId) {
      setMatchingContactListContactId(securityTokenObject.contactListContactId)
    }
  }, [securityTokenObject])

  const performBankId = async (nationality) => {
    const bankIdUrl = await generateBankIdUrl(dialogKey, valuesKey, currentStepKey, nationality)
    if (bankIdUrl.substr(0,4) == "http") window.location.href = bankIdUrl;
  }

  const onSetSecurityType = (value, dialogDefinitionPassed) => {
    let dialogDefinitionToUse = dialogDefinitionPassed || dialogDefinition
    setAuthenticationType(value);

    if (value === securityType.phone && dialogDefinitionToUse?.contactListId) {
      setContactListAuth(true)
      return;
    }

    generateToken(value);
  }

  const onCancel = async () => {  
    currentStepKey
      ? await deleteCurrentSecurityTokenForStep(valuesKey, currentStepKey)
      : await deleteCurrentSecurityToken(valuesKey)
    setAuthenticationType(undefined);
    setSecurityTokenObject(null)
    setContactListAuth(false)
    setMatchingContactListContactId(null)
  }

  const generateToken = async (selectedSecurityType) => {

    generateTokenAction.execute(async () => {
      const securityToken = currentStepKey
        ? await generateSecurityTokenForStep(valuesKey, currentStepKey, selectedSecurityType, birthday)
        : await generateSecurityToken(valuesKey);

      setSecurityTokenObject(securityToken);
    }, "Failed to generate security token. Please try again later");
  }

  const onValidate = (code) => {
    validateTokenAction.execute(async () => {
      let jwt = "";

      if (code) {
        jwt = await exchangeBankIdToken(valuesKey, currentStepKey, code, securityTokenObject.id);
      }
      else {
        jwt = currentStepKey
          ? await validateSecurityTokenForStep(valuesKey, currentStepKey, securityTokenObject.id, token)
          : await validateSecurityToken(valuesKey, securityTokenObject.id, token);
      }

      if (!jwt) {
        // TODO: Add better error handling here
        throw new Error("Missing JWT token");
      }

      if (currentStepKey) {
        tokenAuthentication.completeAuthenticationForStep(valuesKey, currentStepKey, jwt);
        navigate(`/dialog/${dialogKey}/form/${valuesKey}/steps/${currentStepKey}`, { replace: true });
      }
      else {
        tokenAuthentication.completeAuthentication(valuesKey, jwt);
        navigate(`/dialog/${dialogKey}/form/${valuesKey}`, { replace: true });
      }
    }, "Validation failed. Please try again later")
  }

  const onResend = () => {
    generateTokenAction.execute(async () => {
      const securityToken = currentStepKey
        ? await generateSecurityTokenForStep(valuesKey, currentStepKey, securityTokenObject.securityType, null, matchingContactListContactId)
        : await generateSecurityToken(valuesKey);
      setSecurityTokenObject(securityToken);
    }, "Failed to generate security token. Please try again later", "Token was re-sent")
  }

  const onKeyPress = (event) => {
    onKeyPressEnter(event, "validateButton")
  }
  const lookupPhoneNumber = async () => {
    if (!validPhoneNumberFormat) {
      return
    }

    lookupPhoneNumberAction.execute(async () => {
      const contact = await getContactOnContactList(currentStep.current.contactListId, phoneNumber)
      if (contact) {
        setMatchingContactListContactId(contact.id)
      }
      else {
        lookupPhoneNumberAction.toastError("You tried to retrieve a SMS code but your phone number is not in the contact list")
      }
    })
  }

  useEffect(() => {
    if (matchingContactListContactId && contactListAuth) {
      generateTokenAction.execute(async () => {
        const securityToken = await generateSecurityTokenForStep(valuesKey, currentStepKey, securityType.phone, birthday, matchingContactListContactId)
        setSecurityTokenObject(securityToken);
      }, "Failed to generate security token. Please try again later")
    }
  }, [matchingContactListContactId])

  const enterKeyPress = useEnterKeyPress(lookupPhoneNumber)

  const authenticationSelectionRequired = securityOptions?.length > 0 && !authenticationType
  const hasMoreThanOneAuthentication = securityOptions?.length > 1

  let headline
  if (authenticationTemplate?.headline) {
    headline = authenticationTemplate?.headline
  }
  else if (authenticationSelectionRequired) {
    headline = 'How would you like to authenticate?'
  }

  useEffect(() => {
    setValidPhoneNumberFormat(phoneNumber && isPossiblePhoneNumber(phoneNumber))
  }, [phoneNumber])

  const onChangeBirthDay = (value) => {
    const birthdayWithoutStrings = value.replace(/[^0-9]/g, '');

    if (birthdayWithoutStrings.length <= 8) {
      setBirthday(birthdayWithoutStrings);
    }
  }

  return (
    <div className="">
      <div
        className="flex flex-col relative h-screen m-6"
        style={{ backgroundColor: authenticationTemplate?.backgroundColor, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url("data:image/png;base64,${authenticationTemplate?.backgroundImage?.content}") ` }}
      >
        {
          authenticationTemplate?.logo?.content &&
          <div className="absolute m-4 w-96">
            <img src={`data:image/png;base64,${authenticationTemplate.logo.content}`} alt="logo" className="w-full md:w-1/2 mx-auto md:mx-0" />
          </div>
        }

        <div className="my-auto md:m-auto">
          {
            <div>
              {
                headline &&
                <h4 className="text-2xl leading-6 font-medium mb-5" style={{ color: authenticationTemplate?.fontColor }}>
                  {headline}
                </h4>
              }
              {
                authenticationTemplate?.about &&
                <p className="max-w-md mb-4" style={{ color: authenticationTemplate?.fontColor }}>
                  {authenticationTemplate.about}
                </p>
              }
              {
                authenticationSelectionRequired &&
                <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-2 justify-left">
                  {
                    securityOptions.map((s) => {
                      return (
                        <div
                          key={`securityOption-${s}`}
                          className={''}
                        >
                          <button
                            type="button"
                            onClick={() => onSetSecurityType(s)}
                            className="inline-flex lg:w-fit w-full items-center px-6 py-3 border border-gray-400 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            {securityTypeOptions.find(o => o.value === s).name}
                          </button>
                        </div>
                      );
                    })
                  }
                </div>
              }
              {
                contactListAuth && !securityTokenObject &&
                <div className="w-96">
                  <div className="w-full">
                    <h3>
                      Enter your phone number below to receive a security code
                    </h3>
                    <div className="mt-4 p1 pl-2 mt-1 border w-full shadow-sm  sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400">
                      <PhoneInput
                        defaultCountry={PhoneInputConfig.defaulPhoneInputCountry}
                        international
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        countryCallingCodeEditable={false}
                        onKeyPress={enterKeyPress.onKeyPressed}
                      />
                    </div>
                    <div className="mt-4">
                      <Button
                        onClick={lookupPhoneNumber}
                        text={"Send code"}
                        theme="white"
                        fontSize="base"
                        disabled={!validPhoneNumberFormat || lookupPhoneNumberAction.isExecuting}
                        className="px-6 py-3 focus:ring-offset-2 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                </div>
              }
            </div>
          }
          {
            securityTokenObject && securityTokenObject.securityType !== securityType.bankId &&
            <div>
              <h3 className="w-full sm:w-96" style={{ color: authenticationTemplate?.fontColor }}>
                Enter the security token that has been sent to you below.
                If you did not receive anything, click the Re-send button.
              </h3>
              <div className="mt-4">
                <TextInput
                  name={"token"}
                  required={true}
                  value={token}
                  onChange={setToken}
                  onKeyPress={onKeyPress}
                />
              </div>
              <div className="mt-4 flex gap-2">
                <button
                  id="validateButton"
                  type="button"
                  onClick={() => onValidate()}
                  className="inline-flex items-center px-6 py-3 border border-gray-400 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Validate
                </button>
                <button
                  type="button"
                  onClick={onResend}
                  className="inline-flex items-center px-6 py-3 border border-gray-400 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Re-send
                </button>
                {hasMoreThanOneAuthentication &&
                  <button
                    id="cancel"
                    type="button"
                    onClick={() => onCancel()}
                    className="inline-flex items-center px-6 py-3 border border-gray-400 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Cancel
                  </button>
                }
              </div>
            </div>
          }
        </div>
        <footer className="">
          <div className="max-w-screen-3xl mx-auto py-12 px-4 sm:px-6 md:flex md:justify-start lg:px-8">
            <div className="mt-8 md:mt-0">
              <p className="text-base" style={{ color: authenticationTemplate?.fontColor }}>
                {authenticationTemplate?.footer || `© ${new Date().getFullYear()} Metaforce AB. All rights reserved.`}
              </p>
            </div>
          </div>
        </footer>
      </div>

    </div>
  );
}