import * as Constants from '../utils/constants';
import { useSelectInputHelpers } from './useSelectInputHelpers';

export const useTemplateLanguageActions = (templateLanguages) => {
    const selectInputHelpers = useSelectInputHelpers();

    const disabledMapped = (templateLanguages || []).map(x => ({ ...x, name: x.status === Constants.languageListStatus.disabled ? x.name + ' (Disabled)' : x.name, disabled: x.status === Constants.languageListStatus.disabled })) ;

    return {
        getAsSelectOptions: ()=> selectInputHelpers.createOptions(disabledMapped, "languageCode", "name")
    }
}