import { DatabaseIcon, DesktopComputerIcon, DocumentDownloadIcon, DocumentIcon, SearchIcon, ServerIcon } from '@heroicons/react/outline';
import React, { useEffect, useLayoutEffect } from 'react'
import { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getDialogDefinitionDataLoads, getDialogDefinitionTemplate } from '../../../api/dataLoad';
import { getDialogDefinition } from '../../../api/dialogDefinition';
import { Button } from '../../../components/Form/Button';
import { useClassNames } from '../../../hooks/useClassNames';
import { useFileSaver } from '../../../hooks/useFileSaver';
import { useTableSearch } from '../../../hooks/useTableSearch';
import { useTableSort } from '../../../hooks/useTableSort';
import { useToastAction } from '../../../hooks/useToastAction';
import { dataLoadStates, dataLoadTypes } from '../../../utils/constants';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import DataLoadStatusBadge from './dataLoadStatusBadge';
import './dialogDataLoads.css';

export default function DialogDataLoads() {
    let { dialogKey, dataLoadId } = useParams();
    const [allDataLoads, setAllDataLoads] = useState([]);
    const [dataLoadsToShow, setDataLoadsToShow] = useState([]);
    const [dialogDefinition, setDialogDefinition] = useState({})
    const [selectedLoadOption, setSelectedLoadOption] = useState(null);
    const tableSort = useTableSort(dataLoadsToShow);
    const tableSearch = useTableSearch(allDataLoads);
    const loadAction = useToastAction();
    const dialogDefintionAction = useToastAction();
    const templateAction = useToastAction();
    const location = useLocation();
    const { classNames } = useClassNames();
    const { saveAs } = useFileSaver();

    const dataLoadOptions = [
        {
            id: dataLoadTypes.localFile,
            name: "Local files",
            description: "Import local files in order to generate populated Smartforms",
            icon: DocumentDownloadIcon,
            available: true
        },
        {
            id: dataLoadTypes.ftp,
            name: "FTP",
            description: "Schedule import of data from FTP and return receipts to the same location",
            icon: ServerIcon
        },
        {
            id: dataLoadTypes.api,
            name: "API/Database",
            description: "Setting up scheduled imports from a database or REST API",
            icon: DatabaseIcon
        },
        {
            id: dataLoadTypes.crm,
            name: "CRM",
            description: "Setting up import of data from the most common CRM systems",
            icon: DesktopComputerIcon
        },
    ];

    const loadData = async () => {
        dialogDefintionAction.execute(async () => {
            const dialog = await getDialogDefinition(dialogKey);
            setDialogDefinition(dialog);
        }, "Failed to laod dialog definition");

        loadAction.execute(async () => {
            const dataLoads = await getDialogDefinitionDataLoads(dialogKey);
            setAllDataLoads(dataLoads);
            setDataLoadsToShow(dataLoads);

        }, "Failed to load data loads");
    }

    useEffect(() => {
        setSelectedLoadOption(dataLoadOptions[0]);
        loadData();
    }, [location]);

    const handleSearch = (e) => {
        const searchBy = e.target.value
        tableSearch.search(searchBy, setDataLoadsToShow, 'name');
    }

    const handleGetTemplate = async () => {
        templateAction.execute(async () => {
            const template = await getDialogDefinitionTemplate(dialogKey);
            const dialogDefinition = await getDialogDefinition(dialogKey);
            const fileName = `${dialogDefinition.name}-dataload-template.csv`;
            saveAs(fileName, template, "text/plain");
        })
    }

    const handleSelectLoadOption = (loadOption) => {
        setSelectedLoadOption(prev => {
            return loadOption;
        })
    }

    return (
        <div className="max-w-screen-3xl">
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <h3 className="text-xl leading-6 font-medium text-gray-900">Smartform Dataloader: {dialogDefinition?.name}</h3>
            </div>
            <div className="py-5 border-b border-gray-200">
                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 items-stretch gap-2">
                    {
                        dataLoadOptions.map(loadOption => {
                            return (
                                <button className={classNames("border-2 rounded p-4 bg-white flex items-stretch",
                                    loadOption.available ? "text-gray-500" : "text-gray-300",
                                    loadOption.id === selectedLoadOption?.id ? "border-brand-pink" : "border-gray-200")}
                                    onClick={() => handleSelectLoadOption(loadOption)}
                                    disabled={!loadOption.available}
                                >
                                    <div className="flex items-center mr-2 -ml-2">
                                        <loadOption.icon className="h-12 w-12 path-stroke-width-1" />
                                    </div>
                                    <div className="text-left">
                                        <div className="flex justify-between">
                                            <h3 className={classNames("font-bold", loadOption.available ? "text-gray-700" : "text-gray-300")}>{loadOption.name}</h3>
                                            {
                                                !loadOption.available && (
                                                    <span className={classNames(loadOption.available ? "text-red-400" : "text-red-200")}>Unavaliable</span>
                                                )
                                            }
                                        </div>
                                        <div>{loadOption.description}</div>
                                    </div>
                                </button>)
                        })
                    }
                </div>
                <div className="mt-4 w-full">
                    <div className="flex space-x-4">
                        <button
                            onClick={() => {
                                handleGetTemplate();
                            }}
                            className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 text-sm">
                            Generate Template
                        </button>
                        {
                            !!selectedLoadOption && (

                                <Link
                                    to={`/admin/dataload/${dialogKey}/import?dataloadType=${selectedLoadOption?.id}`}
                                    className="justify-center flex items-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-base font-medium text-white text-sm">
                                    Start Import
                                </Link>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <h3 className="mb-2 text-lg leading-6 font-medium text-gray-900">Current dataloads for {dialogDefinition?.name}:</h3>
                    <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() => tableSort.orderBy("name")}
                                    >
                                        Name {tableSort.getSortIcon('name')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() => tableSort.orderBy("numberOfDialogs")}
                                    >
                                        Success {tableSort.getSortIcon('numberOfDialogs')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() => tableSort.orderBy("failedDialogs")}
                                    >
                                        Errors {tableSort.getSortIcon('failedDialogs')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() => tableSort.orderBy("status")}
                                    >
                                        Status {tableSort.getSortIcon('status')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() => tableSort.orderBy("createdDate")}
                                    >
                                        Created {tableSort.getSortIcon('createdDate')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() => tableSort.orderBy("notificationDate")}
                                    >
                                        Emailed {tableSort.getSortIcon('notificationDate')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    !!dataLoadsToShow.length &&
                                    (tableSort?.tableData?.map((element) => (
                                        <tr key={element.id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                <Link
                                                    to={`/admin/dataload/${dialogKey}/import/${element.id}`}
                                                    className="underline">
                                                    {element.dataLoadName}
                                                </Link>

                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{element.dataLoadDetails.numberOfDialogs - element.dataLoadDetails.failedDialogs}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{element.dataLoadDetails.failedDialogs}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {
                                                    <DataLoadStatusBadge status={element.status} />
                                                }
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stringToLocaleDateTimeString(element.createdDate)}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{
                                                element.status === dataLoadStates.notfied && (stringToLocaleDateTimeString(element.updatedDate))
                                            }</td>
                                            {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <Button
                                                    theme="text"
                                                    text="Export"
                                                    className="mr-5"
                                                    onClick={() => onHandleExport(element.id)}
                                                />
                                                <Button
                                                    theme="text"
                                                    text="Log"
                                                    className="mr-5"
                                                    onClick={() => onHandleLog(element.id)}
                                                />
                                            </td> */}
                                        </tr>
                                    )))
                                }
                                {
                                    !dataLoadsToShow.length && !loadAction.isExecuting && (
                                        <tr>
                                            <td colSpan={6} className="py-4">
                                                <div className="flex justify-center items-center">
                                                    {
                                                        !allDataLoads.length ?
                                                            (<>
                                                                No data loads for dialog.
                                                            </>)
                                                            : (<>Search yielded no result</>)
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >
    )
}
