import { useToastAction } from "@metaforcelabs/metaforce-core";
import { useState, useEffect } from "react";
import { getWorkflowTagsResources } from "../api/workflowDefinition";

export const useWorkflowResources = () => {

  const [workLoadOptions, setWorkLoadOptions] = useState([]);
  const [workTriggerOptions, setWorkTriggerOptions] = useState([]);
  const loadAction = useToastAction();
  const tagLoad = 'trig:smartforms:element-on-load'
  const tagChange = 'trig:smartforms:value-change'


  const convertToSelectOptions = (workflowResources, includeEmpty = true) => {
    const resources = workflowResources.map(({ id, name }) => ({ value: id, name }));
    return includeEmpty ? [{ value: "", name: "None" }, ...resources] : resources;
  }

  useEffect(() => {
    loadAction.execute(async () => {
      const workflowResources = await getWorkflowTagsResources(
        tagLoad,tagChange
      );
      
      const grouped = workflowResources.reduce((acc, item) => {
        const relevantTags = item.workflowTags;

        relevantTags.forEach((tag) => {
          if (!acc[tag]) {
            acc[tag] = [];
          }
          acc[tag].push(item);
        });
        return acc;
      }, {});
    
      setWorkLoadOptions(convertToSelectOptions(grouped[tagLoad] || []));
      setWorkTriggerOptions(convertToSelectOptions(grouped[tagChange] || []));
    }, "Failed to load workflow resources");
  }, []);

  return {
    elementOnLoadResources: workLoadOptions,
    elementValueChangeResources: workTriggerOptions,
    convertToSelectOptions: convertToSelectOptions
  };
}