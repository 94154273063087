import { Checkbox } from "./checkbox";

/**
 * @param {*} FontStyleAndWeight 
 */
export const FontStyleAndWeight = ({ onChange, name, property}) => {
    return (
      <>
        <div className='flex-col justify-end'>
            <Checkbox
              onChange={onChange}
              label={"Bold"}
              name={`${name}.bold`}
              value={property?.bold}
              rowReverse
            />

            <Checkbox
              onChange={onChange}
              label={"Italic"}
              name={`${name}.italic`}
              value={property?.italic}
              rowReverse
            />

            <Checkbox
              onChange={onChange}
              label={"Underline"}
              name={`${name}.underline`}
              value={property?.underline}
              rowReverse
            />
          </div>
      </>
    );
  }