import { Fragment, useState, useEffect, createRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, SearchIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import * as Dialogs from "../../templates/dialogs";
import toast from "react-hot-toast";
import {
    createDefaultDialogDefinition,
    createDialogDefinitionFromTemplate,
    getDefaultDialogDefinition,
} from "../../api/dialogDefinition";
import { useToastAction } from "../../hooks/useToastAction";
import {
    First, Six, Thirteen, Fifteen, Seventeen,
    TwentyOne, NR1, NR2, NR4, NR7, NR8, NR9,
    NR11, UtenNavn
} from "../../images/index";
import Preview from "./Templates/preview";
import Modal from '../../components/Modal';
import { TextInput } from "../../components/Form/TextInput";
import { onKeyPressEnter } from "../../utils/onKeyPress";
import { getAllActiveDialogTemplates } from "../../api/dialogTemplates";

export default function TemplateGallery({ open, setOpen, dialogs }) {
    const [requiredFieldErrors, setRequiredFieldErrors] = useState([]);
    const navigate = useNavigate();
    const createDefaultDefinitionAction = useToastAction();
    const createDefinitionFromTemplateAction = useToastAction();
    const loadDialogCustomTemplatesAction = useToastAction();
    const [activeTemplate, setActiveTemplate] = useState(1);
    const [name, setName] = useState("");
    const [showPreviewDialog, setShowPreviewDialog] = useState(false);
    const [showNameModal, setShowNameModal] = useState(false);

    const allTemplates = [
        {
            templateId: 20,
            name: "QuickForm",
            description: "Fast and easy template based design.",
            image: First,
            category: "General",
        },
        {
            templateId: 1,
            name: "Blank",
            description: "This is a blank template.",
            image: First,
            category: "General",
        },
        {
            templateId: 2,
            name: "Basic template",
            description: "This is a basic template",
            image: Fifteen,
            category: "General",
        },
        {
            templateId: 3,
            name: "Non Disclosure Agreement(NDA)",
            description: "This is a non disclosure agreement(NDA)",
            image: Fifteen,
            category: "Employment",
        },
        {
            templateId: 4,
            name: "Resignation letter",
            description: "This is a resignation letter",
            image: Fifteen,
            category: "Employment",
        },
        {
            templateId: 5,
            name: "Rehire policy",
            description: "This is a rehire policy",
            image: Fifteen,
            category: "Employment",
        },
        {
            templateId: 6,
            name: "Employment contract",
            description: "This is a employment contract",
            image: Fifteen,
            category: "Employment",
        },
        {
            templateId: 7,
            name: "Cancellation of Lease Agreement",
            description: "This is a cancellation of lease agreement",
            image: Fifteen,
            category: "Property",
        },
        {
            templateId: 8,
            name: "Rent contract",
            description: "This is a rent contract",
            image: Fifteen,
            category: "Property",
        },
        {
            templateId: 9,
            name: "Boat Lease Agreement",
            description: "This is a boat lease agreement",
            image: Fifteen,
            category: "Property",
        },
        {
            templateId: 10,
            name: "Storage Rental Agreement",
            description: "This is a storage rental agreement",
            image: Fifteen,
            category: "Property",
        },
        {
            templateId: 11,
            name: "Absence Request Form",
            description: "This is a absence request form template",
            image: Fifteen,
            category: "Property",
        },
        {
            templateId: 12,
            name: "Absence Request Form From Manager",
            description: "This is a absence request from manager form template",
            image: Fifteen,
            category: "Property",
        }
    ];

    const [templates, setTemplates] = useState(allTemplates);
    const [customTemplates, setCustomTemplates] = useState();
    const [categories, setCategories] = useState(Object.values(allTemplates.reduce((acc, cur) => Object.assign(acc, { [cur.category]: cur }), {})));
    const [categoriesRef, setCategoriesRef] = useState([]);


    useEffect(() => open && loadCustomTemplates(), [open])

    const loadCustomTemplates = async () => {
        loadDialogCustomTemplatesAction.execute(async () => {
            const records = await getAllActiveDialogTemplates();

            const buildTemplatesFromRecords = records.map((record) => buildRecordToTemplate(record));

            const newTemplates = [...allTemplates, ...buildTemplatesFromRecords];
            const arrLength = newTemplates.length;
            records.length && categories.splice(1, 0, buildRecordToTemplate(records[0]));

            setTemplates(newTemplates);
            setCustomTemplates(records);
            setCategories(categories);
            setCategoriesRef(categoriesRef => (
                Array(arrLength).fill().map((_, i) => categoriesRef[i] || createRef())
            ));
        }, "Failed to load objects");
    };

    const buildRecordToTemplate = (record) => ({
        templateId: record.id,
        name: record.name,
        description: `This is a ${record?.name?.toLowerCase()} template`,
        image: Fifteen,
        category: "Templates"
    });

    const verifyDialogExistence = (templateName) => {
        let regex = new RegExp(`${templateName} \\([0-9]+\\)`, "i");
        const verifyExist = dialogs?.filter(({ name }) => name.match(regex) || name === templateName)

        if (verifyExist?.length) {
            templateName = `${templateName} (${verifyExist.length})`
        }

        return templateName;
    }


    const handleSubmit = async (templateId) => {
        handleCreate(templateId, name);
        setRequiredFieldErrors([]);
    };

    const handleClose = () => {
        setOpen(false);
        setName("");
        setActiveTemplate(null);
    };

    const handleSearch = (e) => {
        const searchBy = e.target.value;

        if (searchBy && searchBy !== "") {
            let newList = [
                ...allTemplates.filter(
                    (m) =>
                        m.name.toLowerCase().includes(searchBy.toLowerCase()) ||
                        m.description.toLowerCase().includes(searchBy.toLowerCase())
                ),
            ];

            setTemplates(newList);
            if (newList?.length > 0) {
                setTemplates(newList);
            } else {
                setTemplates(null);
            }
        } else {
            setTemplates(allTemplates);
        }
    };

    const handleCreate = async (createType, name) => {
        switch (createType) {
            case 1:
                await createDefault(name);
                break;
            case 2:
                await createFromTemplate(Dialogs.Basic, name);
                break;
            case 3:
                await createFromTemplate(Dialogs.NonDisclosureAgreement, name);
                break;
            case 4:
                await createFromTemplate(Dialogs.ResignationLetter, name);
                break;
            case 5:
                await createFromTemplate(Dialogs.RehirePolicy, name);
                break;
            case 6:
                await createFromTemplate(Dialogs.EmploymentContract, name);    
                break;
            case 7:
                await createFromTemplate(Dialogs.CancellationOfLeaseAgreement, name);    
                break;
            case 8:
                await createFromTemplate(Dialogs.RentContract, name);    
                break;
            case 9:
                await createFromTemplate(Dialogs.BoatLeaseAgreement, name);    
                break;
            case 10:
                await createFromTemplate(Dialogs.StorageRentalAgreement, name);    
                break;
            case 11:
                await createFromTemplate(Dialogs.AbsenceRequestForm, name);    
                break;
            case 12:
                await createFromTemplate(Dialogs.AbsenceRequestFormFromManager, name);    
                break;
            case 20:
                await createDefaultQuickDesign(name);
                break;
            default:
                const customTemplate = customTemplates.find((template) => template.id === createType);
                return customTemplate ? createFromTemplate(customTemplate, name) : toast.error("Template not found");
        }

        setOpen(false);
    };

    const createFromTemplate = async (template, name) => {
        const nameWithExistenceVerification = verifyDialogExistence(template.name || name);

        createDefinitionFromTemplateAction.execute(
            async () => {
                const payload = {
                    ...template,
                    name: name ? name : nameWithExistenceVerification,
                };
                const result = await createDialogDefinitionFromTemplate(
                    payload
                );
                navigate(`/admin/dialog/${result.id}/design`);
            },
            "Failed to create",
            "Created"
        );
    };

    const createDefault = async (name) => {
        const nameWithExistenceVerification = verifyDialogExistence(name);

        createDefaultDefinitionAction.execute(
            async () => {
                const result = await createDefaultDialogDefinition(nameWithExistenceVerification);
                navigate(`/admin/dialog/${result.id}/design`);
            },
            "Failed to create",
            "Created"
        );
    };

    const createDefaultQuickDesign = async (name) => {

        createDefaultDefinitionAction.execute(
            async () => {
                const defaultTemplate = await getDefaultDialogDefinition();
                await createFromTemplate({ ...defaultTemplate, isQuickForm: true }, name);
            },
            "Failed to create"
        );
    };

    const scrollToRef = ref => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "center"  });
        }
    }

    const scrollToPane = num => {
        scrollToRef(categoriesRef[num])
    };

    const onKeyPress = (event) => {
        onKeyPressEnter(event, "submitButton")
    }

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto overflow-x-hidden"
                    open={open}
                    onClose={handleClose}
                >
                    <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left shadow-xlsm:align-middle w-full min-h-screen h-full">
                                <div className="fixed h-full w-60 left-0 top-0 pt-10 bg-gray-300 bg-opacity-50 z-10 overflow-hidden">
                                    <h3 className="pl-5 text-lg leading-6 font-medium text-gray-900 mb-5">
                                        New Smartform
                                    </h3>
                                    <p className="pl-5 text-sm font-medium text-gray-500 w-full">
                                        Select a template that best matches your
                                        Smartform specification. This will save
                                        you time in designing your customer
                                        dialog.
                                    </p>

                                    <ul className="mt-5 divide-y">
                                        <li></li>
                                        {
                                            categories?.map((element, id) => {
                                                return <li key={id} className={`text-gray-600 hover:bg-gray-200 group flex items-center px-6 py-4 text-sm font-medium cursor-pointer`}
                                                    onClick={() => scrollToPane(id)}
                                                >
                                                    {element.category}
                                                </li>
                                            })
                                        }
                                        <li></li>
                                    </ul>
                                </div>
                                <div className="fixed bg-white top-0 right-0 left-0 ml-60 h-24">
                                    <div className="absolute top-0 right-0">
                                        <button type="button"
                                            onClick={() => handleClose()}
                                            className="inline-flex p-3 text-gray-400"
                                        >
                                            <span className="sr-only">Dismiss</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>

                                    <div className="flex justify-start mt-10 relative rounded-md mr-6 w-full">
                                        <SearchIcon className="absolute top-0 left-0 bottom-0 h-5 m-auto mx-2 text-gray-400 ml-6" />
                                        <input
                                            type={"text"}
                                            // value={searchBy}
                                            className={`w-4/6 focus:ring-transparent focus:border-gray-300 block sm:text-sm border-gray-300 rounded-md pl-10 ml-4`}
                                            onChange={handleSearch}
                                            placeholder="Search"
                                        />
                                    </div>
                                </div>
                                {
                                    categories && templates &&
                                    categories?.map((element, elementId) => {

                                        let isCategory = templates.find(temp => temp.category === element.category);

                                        if (!isCategory) return null;
                                        return (
                                            <section ref={categoriesRef[elementId]} key={elementId} className="flex w-full flex-col pl-64 mt-10">
                                                <div className="flex mt-10 font-bold text-black text-xs font-mono tracking-widest uppercase py-2 border-b">
                                                    <h3 key={elementId} >{element.category}</h3>
                                                </div>
                                                <div className="block sm:flex sm:flex-wrap sm:gap-6">
                                                    {
                                                        templates &&
                                                        templates.map((template, templateId) => {
                                                            if (template.category !== element.category) return null

                                                            return <article key={templateId} style={{ height: '13.6rem' }} className="w-48 mt-4 bg-white shadow-lg text-left rounded-md">
                                                                <header>
                                                                    <img alt="" src={template.image} className="text-white w-full h-24 rounded-t-md" aria-hidden="true" />
                                                                </header>
                                                                <footer>
                                                                    <p className="h-10 inline-block align-middle mt-5 ml-4 text-sm font-normal text-black">{template.name}</p>
                                                                    <div className="ml-2 flex gap-2 mt-4">
                                                                        <button type="button"
                                                                            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                            onClick={() => {
                                                                                setShowPreviewDialog(true)
                                                                                setActiveTemplate(template.templateId)
                                                                            }}
                                                                        >
                                                                            Preview
                                                                        </button>
                                                                        <button type="button"
                                                                            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                            onClick={() => {
                                                                                setActiveTemplate(template.templateId)
                                                                                setShowNameModal(true);
                                                                            }}
                                                                        >
                                                                            Create
                                                                        </button>
                                                                    </div>
                                                                </footer>
                                                            </article>
                                                        })
                                                    }
                                                </div>
                                            </section>
                                        )
                                    })
                                }
                                <Modal
                                    isOpen={showNameModal}
                                    onClose={() => {
                                        setShowNameModal(false)
                                    }}
                                    size="medium"
                                >
                                    <div className="mt-4">
                                        <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
                                        <TextInput
                                            name={`name${activeTemplate}`}
                                            value={name}
                                            onChange={setName}
                                            onKeyPress={onKeyPress}
                                            required
                                        />
                                    </div>
                                    <div className="mt-5 sm:mt-6">
                                        <button
                                            id="submitButton"
                                            disabled={name.length === 0}
                                            type="button"
                                            className="inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-base font-medium text-white sm:text-sm"
                                            onClick={(event) => {
                                                handleSubmit(activeTemplate)
                                                setShowNameModal(false);
                                            }}
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setName("");
                                                setShowNameModal(false)
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Modal>
                            </div>
                        </Transition.Child>
                    </div>
                    <Preview open={showPreviewDialog} setOpen={setShowPreviewDialog} dialogId={activeTemplate} customTemplates={customTemplates} />
                </Dialog>
            </Transition.Root>
        </>
    );
}
