import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useClassNames } from "../../hooks/useClassNames";
import _ from 'lodash';
import { XCircleIcon } from '@heroicons/react/solid';
import { getSmartObject } from '../../api/smartObject';

export default function ColumnPopup({ smartTableHeaders, smartObjectId, onChange, open, setOpen }) {
  const [smartObject, setSmartObject] = useState({});
  const [validationMessage, setValidationMessage] = useState();
  const classNames = useClassNames();
  const [headers, setHeaders] = useState(smartTableHeaders || []);

  useEffect(() => {
    loadSmartObject();
  }, [smartObjectId])

  const loadSmartObject = async () => {

    if (!smartObjectId) return;

    const smartObjectSrv = await getSmartObject(smartObjectId);
    setSmartObject(smartObjectSrv);

    const newHeaders = [];
    smartObjectSrv?.smartObjectProperties.forEach(property => {
      const header = smartTableHeaders?.find(h => h.name === property.name);
      if (header) {
        newHeaders.push(header);
      } else {
        newHeaders.push({
          name: property.name,
          display: property.label,
          span: property.span
        })
      }
    })

    setHeaders(newHeaders);
  }

  const handleOnChange = (e) => {
    const { name, value, valueAsNumber } = e.target;
    const property = e.target.dataset.optionType

    const newHeaders = _.cloneDeep([...headers]);
    const header = newHeaders.find(h => h.name === name);
    header[property] = property === "span" ? valueAsNumber : value
    setHeaders(newHeaders);
  }

  const handleOnShowChange = (e) => {
    const { name, checked } = e.target;

    const newHeaders = _.cloneDeep([...headers]);
    const header = newHeaders.find(h => h.name === name);
    header.hide = !checked;
    setHeaders(newHeaders);
  }

  const handleSave = () => {
    onChange({
      target: {
        name: 'smartTableHeaders',
        value: headers
      }
    })

    setOpen(false)
  }

  const handleCancel = () => {
    setValidationMessage('')
    setOpen(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames.classNames(
                'sm:max-w-2xl inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6'
              )}
            >
              <div className='flex justify-between items-center'>

                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 mb-5">
                  Edit Columns
                </Dialog.Title>


              </div>
              <div className="">
                {
                  validationMessage &&
                  <div className="rounded-md bg-red-50 p-4 mb-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">{validationMessage}</h3>
                      </div>
                    </div>
                  </div>
                }
                {
                  headers?.length > 0 &&
                  <div className="grid grid-cols-11 gap-3 mb-4">
                    <div className="col-span-2">
                      Show
                    </div>
                    <div className="col-span-4">
                      Property
                    </div>
                    <div className="col-span-4">
                      Display
                    </div>
                    <div className="col-span-1">
                      Span
                    </div>
                  </div>
                }
                {

                  headers?.map((column, index) => (
                    <div key={index} >
                      <div
                        className={classNames.classNames(
                          'mb-3 grid grid-cols-11 gap-3')}
                      >
                        <div className="col-span-2">
                          <input
                            name={column.name}
                            checked={!column.hide}
                            onChange={handleOnShowChange}
                            type="checkbox"
                            className="focus:ring-gray-400 focus:border-gray-400  h-4 w-4 text-indigo-600 border-gray-300 rounded mt-px"
                          />
                        </div>
                        <div className="col-span-4">
                          {column.name}
                        </div>

                        <div className='col-span-4'>
                          <input
                            name={column.name}
                            type="text"
                            data-option-type="display"
                            value={column.display}
                            placeholder="Value..."
                            className={`focus:ring-gray-400 focus:border-gray-400 block w-full mr-5 sm:text-sm border-gray-300 rounded-md`}
                            onChange={(e) => handleOnChange(e)}
                          />
                        </div>

                        <input
                          name={column.name}
                          data-option-type="span"
                          type="number"
                          className="col-span-1 focus:ring-gray-400 focus:border-gray-400 block w-full mr-5 sm:text-sm border-gray-300 rounded-md"
                          onChange={handleOnChange}
                          value={column?.span ?? 1}
                        />
                      </div>

                    </div>

                  ))
                }

                {
                  headers?.length < 1 &&
                  <div className="">
                    Smart object does not have any properties.
                  </div>
                }

              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-base font-medium text-white sm:text-sm"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}